import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deleteRequest, get } from "../../api";
import { Circle, MeasureInput, ToggleBox } from "../../components";
import { MinusCircle } from "../../icons";

const EditCampaign = observer(() => {
  const navigate = useNavigate();
  const state = useLocalObservable(() => ({
    isLoading: false,
    setIsLoading(isLoading) {
      state.isLoading = isLoading;
    },
    campaign: null,
    setCampaign(campaign) {
      state.campaign = campaign;
    },
    async removeCampaign() {
      if (state.isLoading) {
        return;
      }

      if (!confirm("Är du säker på att du vill ta bort denna kampanjen?")) {
        return;
      }

      try {
        state.setIsLoading(true);
        await deleteRequest(`/campaigns/${state.campaign.id}`);
        navigate("/settings");
      } catch (error) {
        console.error(error.message);
      } finally {
        state.setIsLoading(false);
      }
    },
  }));

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const { data } = await get("/campaigns");
        state.setCampaign(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchCampaign();
  }, []);
  if (state.campaign === null) return null;
  return (
    <ToggleBox className="mt-8" title="Kampanj" toggle={false}>
      <div className="p-4 md:py-7 flex flex-wrap">
        <div className="px-3 flex-1">
          <span className="text-grey-dark font-medium">Beskrivning</span>
          <textarea
            disabled={true}
            className="mt-2 min-w-96"
            value={state.campaign.description}
          />
        </div>

        <div className="px-3 w-96">
          <MeasureInput
            disabled={true}
            label="Rabatt"
            prependLabel="%"
            value={state.campaign.discount_percentage}
          />
        </div>
      </div>
      <div className="flex items-center px-7 pb-7">
        <Circle type="button" onClick={state.removeCampaign}>
          <MinusCircle />
        </Circle>
      </div>
    </ToggleBox>
  );
});

export default EditCampaign;
