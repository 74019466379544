import Downshift from "downshift";
import { Observer, observer, useLocalObservable } from "mobx-react";
import React, { useEffect } from "react";
import { Circle, Input, MeasureInput, Select } from "../../../components";
import { ColorNames } from "../../../constants";
import { DownArrow, MinusCircle } from "../../../icons";
import { getOrderStatus, getShippingText } from "../../../utils";

export const Row = observer(({ openDefault = false, title, children }) => {
  const state = useLocalObservable(() => ({
    isOpen: openDefault,
  }));
  return (
    <div className="flex flex-col">
      <div
        onClick={() => (state.isOpen = !state.isOpen)}
        className={`cursor-pointer flex items-center px-6 py-4 leading-none border-b border-grey ${
          !state.isOpen || !children ? "closed" : ""
        }`}
      >
        <p>{title}</p>
        <DownArrow
          className={`ml-auto w-4 transform ${
            state.isOpen ? "rotate-180" : ""
          }`}
        />
      </div>
      <div className={state.isOpen ? "border-b border-grey" : ""}>
        {state.isOpen && children}
      </div>
    </div>
  );
});

export const Color = ({ color, name }) => (
  <div className="flex items-center">
    <span
      className="w-5 h-5 border border-grey"
      style={{ backgroundColor: color }}
    ></span>
    <span className="pl-2 text-xs text-grey-dark">{name}</span>
  </div>
);

export const ProductSearchRow = observer(
  ({ products, product, onDelete, onAdd, onUpdate, showLabels, type }) => {
    const state = useLocalObservable(() => ({
      quantity: product.quantity,
      setQuantity(quantity) {
        state.quantity = quantity;
      },
      get rowPrice() {
        return (product.selectedConf.price * state.quantity) / 100;
      },
      handleBlur() {
        if (!state.quantity) {
          state.quantity = "1";
        }
        onUpdate(product, state.quantity, product.selectedConf);
      },
    }));
    return (
      <div className="flex">
        <Circle className="mt-1 mr-4" onClick={onDelete} type="button">
          <MinusCircle />
        </Circle>
        <div className="flex flex-wrap w-full -mx-1">
          <span className="w-full sm:w-1/2 mb-4 xl:w-1/4 xl:flex-1 px-1">
            <SearchProduct
              products={products}
              product={product}
              onChange={(val) => {
                Object.keys(product).forEach(function (key) {
                  delete product[key];
                });

                Object.keys(val).forEach(function (key) {
                  product[key] = val[key];
                });
                product.selectedConf = val.configurations[0];
                product.quantity = 1;
                onAdd(product);
              }}
              label={showLabels ? "Produkt" : null}
            />
          </span>
          {product && product.id && (
            <>
              {product.configurations && (
                <span className="w-full sm:w-1/2 mb-4 xxl:w-1/4 xxl:flex-1 px-1">
                  <Select
                    items={product.configurations}
                    initialItem={product.selectedConf}
                    onChange={(config) =>
                      onUpdate(product, product.quantity, config)
                    }
                    type={"configurations"}
                    label={showLabels ? "Mått" : null}
                  />
                </span>
              )}
              {(product.selectedConf.color ||
                product.selectedConf.meta.color ||
                product.selectedConf.meta.v_and_w) && (
                <span className="w-full sm:w-1/2 xxl:w-1/5 px-1">
                  <Input
                    disabled
                    value={
                      product.selectedConf
                        ? type === "lighting"
                          ? product.selectedConf.meta.v_and_w
                          : ColorNames[product.selectedConf.color] ||
                            product.selectedConf.meta.color
                        : ""
                    }
                    label={showLabels ? "Färg" : null}
                    full
                  />
                </span>
              )}
              <span className="w-full sm:w-1/2 mb-4 xxl:w-1/5 px-1">
                <MeasureInput
                  onChange={(e) => state.setQuantity(e.target.value)}
                  onBlur={state.handleBlur}
                  value={state.quantity || ""}
                  prependLabel="st"
                  label={showLabels ? "Antal" : null}
                  small
                />
              </span>
              <span className="w-full sm:w-1/2 mb-4 xxl:w-1/5 px-1">
                <MeasureInput
                  disabled
                  value={state.rowPrice}
                  prependLabel="SEK"
                />
              </span>
            </>
          )}
        </div>
      </div>
    );
  }
);
const SearchProduct = ({ products, product, onChange }) => {
  const store = useLocalObservable(() => ({
    isOpen: false,
    products: [],
    selectedItem: null,
    searchString: "",
  }));

  useEffect(() => {
    if (product && product.id) {
      store.selectedItem = product;
      store.searchString = product.name;
    }
  }, [product]);

  return (
    <div className="searchProduct flex min-w-48">
      <Downshift
        onOuterClick={() => {
          store.isOpen = false;
        }}
        onChange={(selection) => {
          store.isOpen = false;
          store.searchString = selection.name;
          store.selectedItem = selection;
          onChange(selection);
        }}
        itemToString={(item) => (item ? item.name : "")}
      >
        {({
          getInputProps,
          getItemProps,
          //highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
          <div
            className="flex-1 flex items-center"
            {...getRootProps({}, { suppressRefError: true })}
          >
            <Observer>
              {() => (
                <div className="w-full relative">
                  <input
                    className="relative z-30 bg-white"
                    {...getInputProps()}
                    placeholder="Sök efter produkt..."
                    value={store.searchString}
                    onChange={(e) => {
                      store.isOpen = true;
                      store.searchString = e.target.value;
                    }}
                  />
                  {store.isOpen && (
                    <ul className="z-40 pt-8 -mt-6 absolute border-b border-l border-r border-grey w-full rounded-b-2xl overflow-hidden bg-white h-64 overflow-y-auto">
                      {products.filter(
                        (item) =>
                          !store.searchString ||
                          item.name
                            .toLowerCase()
                            .includes(store.searchString.toLowerCase())
                      ).length > 0 ? (
                        products
                          .filter(
                            (item) =>
                              !store.searchString ||
                              item.name
                                .toLowerCase()
                                .includes(store.searchString.toLowerCase())
                          )
                          .map((item, index) => {
                            return (
                              <li
                                key={item.id}
                                className="px-5 py-2 hover:bg-blue-light cursor-pointer"
                                {...getItemProps({
                                  key: item.id,
                                  index,
                                  item,
                                  style: {
                                    fontWeight:
                                      selectedItem === item ? "bold" : "normal",
                                  },
                                })}
                              >
                                {item.name}
                              </li>
                            );
                          })
                      ) : (
                        <p className="p-6 bg-white">
                          Inga resultat matchade din sökning
                        </p>
                      )}
                    </ul>
                  )}
                </div>
              )}
            </Observer>
          </div>
        )}
      </Downshift>
    </div>
  );
};
export const ShippingAndRequest = observer(({ order, customer }) => {
  return (
    <div className="box mt-6 flex flex-wrap">
      <div className="flex flex-col w-full xl:w-1/2 lg:border-r border-grey-light">
        <p className="p-4 md:px-7 w-full border-b border-grey-light">
          Kommentar/önskemål
        </p>
        <p className="p-4 md:p-7">
          {order.customer_note ? order.customer_note : "Inga önskemål"}
        </p>
      </div>
      <div className="flex flex-col w-full xl:w-1/2">
        <p className="h-0 xl:h-auto px-4 md:px-7 xl:py-4 w-full border-b border-grey-light">
          &nbsp;
        </p>
        {customer && customer.note && customer.note !== "" && (
          <p className="p-4 md:p-7 border-b border-grey-light">
            {customer.note}
          </p>
        )}
        <div className="flex flex-col p-4 md:px-7">
          <p>{getShippingText(order.delivery_option)}.</p>
          {order.customer_wants_sms && <p>Kund vill ha sms.</p>}
        </div>
      </div>
    </div>
  );
});

export const AssemplyDescriptionAndMiscInfo = observer(({ order }) => {
  const orderStatus = order.order_status
    ? getOrderStatus(order.order_status)
    : "offer";

  return (
    <div className="flex flex-wrap" style={{ minHeight: "250px" }}>
      <div className="flex flex-col w-full md:w-1/2 border-r border-grey-light">
        <p className="px-4 py-2 md:px-7 bg-grey-lighter font-medium">
          Monteringsinstruktioner
        </p>
        {orderStatus === "offer" ? (
          <div className="p-4 md:p-7 h-full">
            <textarea
              placeholder="Skriv monteringsinstruktioner här"
              onChange={(e) => (order.assembly_note = e.target.value)}
              value={order.assembly_note}
              className="flex h-full outline-none flex-grow"
            />
          </div>
        ) : (
          <p className="m-6">{order.assembly_note}</p>
        )}
      </div>
      <div className="flex flex-col w-full md:w-1/2">
        <p className="px-4 py-2 md:px-7 bg-grey-lighter font-medium">
          Övrig info
        </p>
        {orderStatus === "offer" ? (
          <div className="p-4 md:p-7 h-full">
            <textarea
              placeholder="Skriv övrig info här"
              onChange={(e) => (order.note = e.target.value)}
              value={order.note}
              className="flex h-full outline-none flex-grow"
              disabled={orderStatus !== "offer"}
            />
          </div>
        ) : (
          <p className="m-6">{order.note}</p>
        )}
      </div>
    </div>
  );
});
