import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

const ToggleSwitch = observer(({ label, name, value, onChange }) => {
  return (
    <div className="mr-4 last:mr-0">
      <p className="text-grey-dark font-medium mb-3">{label}</p>
      <label className="toggle-switch relative cursor-pointer">
        <input
          type="checkbox"
          onChange={() => {
            value = !value;
            onChange(value);
          }}
          name={name}
          className="hidden"
          value={value}
          checked={value}
        />
        <div className="flex items-center w-30 h-12 bg-grey-light border border-grey rounded-3xl"></div>
      </label>
    </div>
  );
});

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default ToggleSwitch;
