import React from "react";
import { observer } from "mobx-react";
import { InteriorWardrobeMaxHeight } from "../../../constants";
import Measurement from "./Measurement";

export const ClothesRailJacket = (props) => (
  <svg
    viewBox="0 0 35 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin meet"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="m28.882 15.322-2.743 4.115s-2.744-4.116-4.573-3.546c-1.829.57-7.2 10.404-7.2 10.404l4.344-10.974s1.829-3.545 5.487-2.63c3.658.914 4.685 2.63 4.685 2.63ZM8.992 92.48l-.802-1.607a2.975 2.975 0 0 1-.305-1.448c.714-18.143 6.49-63.301 6.49-63.301M12.421 99.795l-3.518-1.137a.555.555 0 0 1-.286-.847c1.024-1.433.375-5.326.375-5.326l8.569 1.024-.756 6.371a.378.378 0 0 1-.418.333l-3.963-.418s9.49 11.547 14.977 6.06c5.487-5.487-1.26-86.42-1.26-86.42"
        stroke="currentColor"
        strokeWidth={1.35}
        strokeMiterlimit={10}
      />
      <path
        d="M17.56 93.508s4.698-17.497 1.55-43.554"
        stroke="currentColor"
        strokeWidth={1.35}
        strokeMiterlimit={10}
      />
      <path
        d="M23.657 12.76a.715.715 0 0 0 .222-.537v-4.02a4.217 4.217 0 0 0 1.798-.763c.53-.39.959-.9 1.25-1.486a4.085 4.085 0 0 0-.056-3.755A4.15 4.15 0 0 0 25.576.751a4.242 4.242 0 0 0-3.77-.526 4.195 4.195 0 0 0-1.65 1.037 4.175 4.175 0 0 0-1.143 2.868 1.417 1.417 0 0 0 0 .376.748.748 0 0 0 .26.539.77.77 0 0 0 .87.097.764.764 0 0 0 .375-.469.747.747 0 0 0 .019-.304v-.244c0-.523.158-1.035.453-1.47a2.677 2.677 0 0 1 1.205-.974 2.718 2.718 0 0 1 2.924.573 2.645 2.645 0 0 1 .774 1.882 2.617 2.617 0 0 1-.804 1.87 2.68 2.68 0 0 1-1.905.755H23.1a.767.767 0 0 0-.54.22.747.747 0 0 0-.222.532v4.735c0 .2.08.39.223.532a.767.767 0 0 0 1.096-.02Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="currentColor"
          transform="matrix(-1 0 0 1 35 0)"
          d="M0 0h35v108H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

const WardrobeClothesRail = observer(
  ({
    wardrobeWidth,
    width,
    offset = 0,
    depth,
    double = false,
    color,
    showMeasurement,
  }) => {
    return (
      <div
        className="absolute h-full preserve-3d"
        style={{
          left: `${(offset / wardrobeWidth) * 100}%`,
          width: `${(width / wardrobeWidth) * 100}%`,
        }}
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-end items-center"></div>
        <div
          className="absolute w-full left-0 right-0 border-t-4"
          style={{
            top: `${(80 / InteriorWardrobeMaxHeight) * 100}%`,
            height: "40%",
            transform: `translateZ(-${25 + depth}px)`,
            borderTopColor: color,
            color,
          }}
        >
          <ClothesRailJacket
            className="h-full max-w-full relative"
            style={{ top: "-4px" }}
          />
        </div>
        {double && (
          <div
            className="absolute w-full left-0 right-0 h-1 border-t-4"
            style={{
              top: `${(1080 / InteriorWardrobeMaxHeight) * 100}%`,
              height: "40%",
              transform: `translateZ(-${25 + depth}px)`,
              borderTopColor: color,
              color,
            }}
          >
            <ClothesRailJacket
              className="h-full max-w-full relative"
              style={{ top: "-4px" }}
            />
          </div>
        )}
        {showMeasurement && <Measurement size={width} type="bottom" />}
      </div>
    );
  }
);

export default WardrobeClothesRail;
