import React, { useRef } from "react";
import { observer, useLocalObservable } from "mobx-react";
import { Input, Circle, Select, ToggleBox } from "../../../components";
import { Checkmark, Upload, CheckMarkNoBorder } from "../../../icons";
import { customerTypes } from "../../../constants";
import { post, postMedia } from "../../../api";
import { validateEmail, validatePhone } from "../../../utils";
import { useNavigate } from "react-router-dom";

const CUSTOMER_TYPE_COMPANY = "company";
const CUSTOMER_TYPE_PRIVATE = "person";

const AddCustomer = observer(() => {
  const navigate = useNavigate();
  const store = useLocalObservable(() => ({
    file: null,
    error: {
      company: null,
      orgnumber: null,
      email: null,
      phone: null,
      billing_address: null,
      billing_zipcode: null,
      billing_city: null,
      delivery_address: null,
      delivery_zipcode: null,
      delivery_city: null,
      general: null,
    },
    privateData: {
      name: "",
      lastname: "",
      note: "",
      email: "",
      phone: "",
      billing_address: "",
      billing_zipcode: "",
      billing_city: "",
      delivery_address: "",
      delivery_zipcode: "",
      delivery_city: "",
    },
    companyData: {
      file: null,
      company: "",
      orgnumber: "",
      billing_address: "",
      billing_zipcode: "",
      billing_city: "",
      name: "",
      lastname: "",
      email: "",
      phone: "",
      agreement: "",
      agreement_url: "",
    },
    handleUpload(e) {
      const file = e.target.files[0];
      if (file) {
        store.companyData.agreement_url = file;
      }
    },
    customerType: "",
    copyDeliveryAdress: false,
    async addCustomer(e) {
      e.preventDefault();

      let customer = null;

      if (store.customerType === "company") {
        customer = {
          type: this.customerType,
          company_name: store.companyData.company,
          organization_number: store.companyData.orgnumber,
          contact_first_name: store.companyData.name,
          contact_last_name: store.companyData.lastname,
          contact_email: store.companyData.email,
          contact_phone: store.companyData.phone,
          agreement: store.companyData.agreement,
          agreement_url: store.companyData.agreement_url,
          billing_address: store.companyData.billing_address,
          billing_zipcode: store.companyData.billing_zipcode,
          billing_city: store.companyData.billing_city,
        };
      } else {
        customer = {
          type: this.customerType,
          first_name: store.privateData.name,
          last_name: store.privateData.lastname,
          email: store.privateData.email,
          contact_phone: store.privateData.phone,
          note: store.privateData.note,
          billing_address: store.copyDeliveryAdress
            ? store.privateData.delivery_address
            : store.privateData.billing_address,
          billing_zipcode: store.copyDeliveryAdress
            ? store.privateData.delivery_zipcode
            : store.privateData.billing_zipcode,
          billing_city: store.copyDeliveryAdress
            ? store.privateData.delivery_city
            : store.privateData.billing_city,
          delivery_address: store.privateData.delivery_address,
          delivery_zipcode: store.privateData.delivery_zipcode,
          delivery_city: store.privateData.delivery_city,
        };
      }

      this.validateData(customer);

      if (Object.keys(this.error).length === 0) {
        try {
          if (customer.agreement_url instanceof File) {
            const res = await postMedia(customer.agreement_url);
            customer.agreement_url = res.data.Path;
          }

          await post("/customers", customer);
          navigate(-1);
        } catch (error) {
          store.error.general = "Något gick fel, försök igen";
        }
      }
    },
    validateData(data) {
      this.error = {};
      if (data.type === "company") {
        if (!data.company_name || data.company_name.length < 2) {
          this.error.company = "Företagsnamnet är för kort";
        }
        if (!data.organization_number || data.organization_number.length < 5) {
          this.error.orgnumber = "Organisationsnumret är för kort";
        }
        if (!data.contact_first_name || data.contact_first_name.length < 2) {
          this.error.name = "Ditt förnamn är för kort";
        }
        if (!data.contact_last_name || data.contact_last_name.length < 2) {
          this.error.lastname = "Ditt efternamn är för kort";
        }
        if (!validateEmail(data.contact_email)) {
          this.error.email = "Ogiltig email";
        }
        if (!validatePhone(data.contact_phone)) {
          this.error.phone = "Ogiltigt telefonnummer";
        }
        if (!data.billing_address || data.billing_address.length < 2) {
          this.error.billing_address = "För kort adress";
        }
        if (!data.billing_zipcode || data.billing_zipcode.length < 2) {
          this.error.billing_zipcode = "För kort zip";
        }
        if (!data.billing_city || data.billing_city.length < 2) {
          this.error.billing_city = "För kort stad";
        }
      } else {
        if (!data.first_name || data.first_name.length < 2) {
          this.error.name = "Ditt förnamn är för kort";
        }
        if (!data.last_name || data.last_name.length < 2) {
          this.error.lastname = "Ditt efternamn är för kort";
        }
        if (!validateEmail(data.email)) {
          this.error.email = "Ogiltig email";
        }
        if (!validatePhone(data.contact_phone)) {
          this.error.phone = "Ogiltigt telefonnummer";
        }
        if (!store.copyDeliveryAdress) {
          if (!data.billing_address || data.billing_address.length < 2) {
            this.error.billing_address = "För kort adress";
          }
          if (!data.billing_zipcode || data.billing_zipcode.length < 2) {
            this.error.billing_zipcode = "För kort zip";
          }
          if (!data.billing_city || data.billing_city.length < 2) {
            this.error.billing_city = "För kort stad";
          }
        }
        if (!data.delivery_address || data.delivery_address.length < 2) {
          this.error.delivery_address = "För kort adress";
        }
        if (!data.delivery_zipcode || data.delivery_zipcode.length < 2) {
          this.error.delivery_zipcode = "För kort zip";
        }
        if (!data.delivery_city || data.delivery_city.length < 2) {
          this.error.delivery_city = "För kort stad";
        }
      }
    },
  }));

  const ref = useRef();

  return (
    <div className="flex flex-col">
      <Select
        items={customerTypes}
        onChange={(item) => (store.customerType = item.value)}
      />

      {store.customerType === CUSTOMER_TYPE_COMPANY && (
        <form
          onSubmit={store.addCustomer}
          className="flex mt-4 md:mt-7 flex-col"
        >
          <ToggleBox title="Företagsinformation">
            <div className="flex p-4 pb-0 md:pb-3 md:pt-7">
              <div className="w-full flex flex-wrap">
                <div className="flex flex-wrap w-full xl:w-2/3">
                  <Input
                    error={store.error.company}
                    value={store.companyData.company}
                    onChange={(event) =>
                      (store.companyData.company = event.target.value)
                    }
                    name="company"
                    fieldname="Företagsnamn"
                  />
                  <Input
                    error={store.error.orgnumber}
                    value={store.companyData.orgnumber}
                    onChange={(event) =>
                      (store.companyData.orgnumber = event.target.value)
                    }
                    name="orgnumber"
                    fieldname="Organisationsnummer"
                  />
                  <div className="flex flex-wrap w-full flex-col">
                    <Input
                      value={store.companyData.billing_address}
                      error={store.error.billing_address}
                      onChange={(event) =>
                        (store.companyData.billing_address = event.target.value)
                      }
                      placeholder="Gata"
                      fieldname="Faktureringsadress"
                      className="md:w-full"
                    />
                    <div className="flex flex-col md:flex-row w-full">
                      <Input
                        error={store.error.billing_zipcode}
                        value={store.companyData.billing_zipcode}
                        onChange={(event) =>
                          (store.companyData.billing_zipcode =
                            event.target.value)
                        }
                        name="zip"
                        placeholder="123 45"
                        className="md:w-1/3 md:pr-0"
                      />
                      <Input
                        error={store.error.billing_city}
                        value={store.companyData.billing_city}
                        onChange={(event) =>
                          (store.companyData.billing_city = event.target.value)
                        }
                        name="city"
                        placeholder="Ort"
                        className="md:w-2/3 md:pl-4"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mb-4 md:px-3 xl:w-1/3">
                  <p className="font-medium">Avtal</p>
                  <div className="rounded-2xl mt-2 p-0 w-full">
                    <textarea
                      className="w-full"
                      onChange={(event) =>
                        (store.companyData.agreement = event.target.value)
                      }
                    />
                  </div>
                  <div className="flex items-center mt-4">
                    <input
                      onChange={store.handleUpload}
                      type="file"
                      accept="application/pdf"
                      name="file"
                      ref={ref}
                      className="hidden"
                    />
                    <Circle
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        ref.current.click();
                      }}
                    >
                      <Upload />
                    </Circle>
                    <p className="ml-4">
                      {store.companyData.agreement_url
                        ? store.companyData.agreement_url.name
                        : "Ladda upp en kopia av avtalet"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ToggleBox>

          <div className="flex mt-4 md:mt-7 flex-col">
            <ToggleBox title="Kontaktperson">
              <div className="flex p-4 pb-0 md:pt-7 md:pb-3 flex-wrap xl:w-2/3">
                <Input
                  error={store.error.name}
                  value={store.companyData.name}
                  onChange={(event) =>
                    (store.companyData.name = event.target.value)
                  }
                  name="name"
                  fieldname="Förnamn"
                  placeholder="Namn"
                />
                <Input
                  error={store.error.lastname}
                  value={store.companyData.lastname}
                  onChange={(event) =>
                    (store.companyData.lastname = event.target.value)
                  }
                  name="lastname"
                  fieldname="Efternamn"
                  placeholder="Efternamn"
                />
                <Input
                  error={store.error.email}
                  value={store.companyData.email}
                  onChange={(event) =>
                    (store.companyData.email = event.target.value)
                  }
                  name="email"
                  type="email"
                  fieldname="E-post"
                  placeholder="namn@foretag.se"
                />
                <Input
                  error={store.error.phone}
                  value={store.companyData.phone}
                  onChange={(event) =>
                    (store.companyData.phone = event.target.value)
                  }
                  name="phone"
                  type="tel"
                  fieldname="Telefon"
                  placeholder="0700000000"
                />
              </div>
            </ToggleBox>
          </div>
          <div className="flex mt-6 items-center">
            <Circle type="button" value="submit">
              <Checkmark />
            </Circle>
            <p className="ml-4">Skapa kund</p>
          </div>
        </form>
      )}

      {store.customerType === CUSTOMER_TYPE_PRIVATE && (
        <form
          onSubmit={store.addCustomer}
          className="flex mt-4 md:mt-7 flex-col w-full"
        >
          <ToggleBox title="Kundinformation">
            <div className="flex flex-col xl:flex-row p-4 md:py-7 w-full">
              <div className="flex flex-wrap w-full xl:w-2/3">
                <Input
                  value={store.privateData.name}
                  error={store.error.name}
                  onChange={(event) => {
                    store.privateData.name = event.target.value;
                  }}
                  name="name"
                  fieldname="Förnamn"
                  placeholder="Förnamn"
                />
                <Input
                  error={store.error.lastname}
                  value={store.privateData.lastname}
                  onChange={(event) =>
                    (store.privateData.lastname = event.target.value)
                  }
                  name="lastname"
                  fieldname="Efternamn"
                  placeholder="Efternamn"
                />
                <Input
                  error={store.error.email}
                  value={store.privateData.email}
                  onChange={(event) => {
                    store.privateData.email = event.target.value;
                  }}
                  name="email"
                  fieldname="E-post"
                  type="email"
                  placeholder="namn@example.se"
                />
                <Input
                  error={store.error.phone}
                  value={store.privateData.phone}
                  onChange={(event) => {
                    store.privateData.phone = event.target.value;
                  }}
                  name="phone"
                  fieldname="Telefon"
                  type="tel"
                  placeholder="0700000000"
                />
                <div className="md:px-3 w-full mt-4">
                  <p>Kommentar</p>
                  <div className="rounded-2xl mt-2 p-0 w-full">
                    <textarea
                      value={store.privateData.note}
                      onChange={(event) =>
                        (store.privateData.note = event.target.value)
                      }
                      placeholder="Kommentar..."
                      className="w-full mr-4"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full xl:w-1/3">
                <div className="flex w-full flex-col mt-4 xl:mt-0">
                  <Input
                    error={store.error.delivery_address}
                    value={store.privateData.delivery_address}
                    onChange={(event) => {
                      store.privateData.delivery_address = event.target.value;
                    }}
                    name="street"
                    className="w-full md:w-full"
                    placeholder="Gata"
                    fieldname="Leveransaddress"
                  />
                  <div className="flex flex-col sm:flex-row w-full">
                    <Input
                      error={store.error.delivery_zipcode}
                      value={store.privateData.delivery_zipcode}
                      onChange={(event) => {
                        store.privateData.delivery_zipcode = event.target.value;
                      }}
                      className="sm:w-1/3 md:w-1/3 sm:min-w-0 md:pr-0"
                      name="zip"
                      placeholder="123 45"
                    />
                    <Input
                      error={store.error.delivery_city}
                      value={store.privateData.delivery_city}
                      onChange={(event) => {
                        store.privateData.delivery_city = event.target.value;
                      }}
                      className="sm:w-2/3 md:w-2/3 sm:pl-4 sm:min-w-0"
                      name="city"
                      placeholder="Ort"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col mt-4">
                  <Input
                    error={store.error.billing_address}
                    onChange={(event) => {
                      store.privateData.billing_address = event.target.value;
                    }}
                    value={
                      store.copyDeliveryAdress
                        ? store.privateData.delivery_address
                        : store.privateData.billing_address
                    }
                    disabled={store.copyDeliveryAdress}
                    name="billingstreet"
                    className="w-full md:w-full"
                    placeholder="Gata"
                    fieldname="Faktureringsadress"
                  />
                  <div className="flex flex-col sm:flex-row">
                    <Input
                      error={store.error.billing_zipcode}
                      onChange={(event) => {
                        store.privateData.billing_zipcode = event.target.value;
                      }}
                      value={
                        store.copyDeliveryAdress
                          ? store.privateData.delivery_zipcode
                          : store.privateData.billing_zipcode
                      }
                      disabled={store.copyDeliveryAdress}
                      className="sm:w-1/3 md:w-1/3 sm:min-w-0 md:pr-0"
                      name="billingzip"
                      placeholder="123 45"
                    />
                    <Input
                      error={store.error.billing_city}
                      onChange={(event) => {
                        store.privateData.billing_city = event.target.value;
                      }}
                      value={
                        store.copyDeliveryAdress
                          ? store.privateData.delivery_city
                          : store.privateData.billing_city
                      }
                      disabled={store.copyDeliveryAdress}
                      className="sm:w-2/3 md:w-2/3 sm:pl-4 sm:min-w-0"
                      name="billingcity"
                      placeholder="Ort"
                    />
                  </div>
                  <div className="flex items-center pr-3 md:px-3">
                    <button
                      type="button"
                      className={`checkbox ${
                        store.copyDeliveryAdress ? "selected" : ""
                      }`}
                      onClick={() =>
                        (store.copyDeliveryAdress = !store.copyDeliveryAdress)
                      }
                    >
                      <CheckMarkNoBorder />
                    </button>
                    <p className="ml-4">Samma som leveransadress</p>
                  </div>
                </div>
              </div>
            </div>
          </ToggleBox>
          <div className="flex mt-6 items-center">
            <Circle type="button" value="submit">
              <Checkmark />
            </Circle>
            <p className="ml-4">Skapa kund</p>
          </div>
        </form>
      )}
    </div>
  );
});

export default AddCustomer;
