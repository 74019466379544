import React from "react";
import Downshift from "downshift";
import { observer, useLocalObservable } from "mobx-react";
import { getBadge } from "../../utils";

export const LabelSelect = observer(({ onChange, value, disabled }) => {
  const store = useLocalObservable(() => ({
    isActive: false,
  }));
  const labels = [
    "offer.unhandled",
    "offer.sent",
    "offer.changed",
    "order.confirmed",
    "order.ready",
    "order.paused",
    "pruduction.producing",
    "pruduction.produced",
    "pruduction.paused",
    "done",
  ].filter(
    (label) => label.split(".") && label.split(".")[0] === value.split(".")[0]
  );
  return (
    <Downshift
      onOuterClick={() => {
        store.isActive = false;
      }}
      isOpen={!disabled && store.isActive}
      onChange={(value) => {
        if (!value) {
          return;
        }
        store.isActive = false;
        onChange ? onChange(value) : null;
      }}
      initialSelectedItem={value}
    >
      {({
        getLabelProps,
        isOpen,
        selectedItem,
        getMenuProps,
        getItemProps,
      }) => (
        <div className="flex">
          <label
            className={`md:min-w-30 text-sm md:text-base px-3 flex items-center justify-center py-1 capitalize rounded-full cursor-pointer bg-${
              getBadge(selectedItem).color
            }`}
            onClick={() => {
              if (disabled) {
                return;
              }
              store.isActive = true;
            }}
            {...getLabelProps()}
          >
            {getBadge(selectedItem).label}
          </label>
          {isOpen && (
            <ul
              className="absolute select-list label-select bg-white border rounded-2xl -mt-2 -ml-2 z-20 shadow-sm"
              {...getMenuProps()}
            >
              {labels &&
                labels.map((label, index) => (
                  <li
                    key={index}
                    {...getItemProps({
                      index,
                      item: label,
                    })}
                    className="label p-2 bg-transparent transition-colors hover:bg-grey-lighter focus:bg-grey-lighter"
                  >
                    <div
                      className={`md:min-w-30 text-sm md:text-base px-3 flex items-center justify-center py-1 capitalize rounded-full cursor-pointer bg-${
                        getBadge(label).color
                      }`}
                    >
                      <p>{getBadge(label).label}</p>
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </div>
      )}
    </Downshift>
  );
});
export default LabelSelect;
