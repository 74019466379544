import React from "react";
import PropTypes from "prop-types";
import { Mail, Phone, Search } from "../../icons";

const Input = ({
  color,
  className,
  error,
  fieldname,
  name = "",
  type,
  full,
  ...props
}) => (
  <div
    className={`${name} input  mb-4 flex flex-col ${
      className ? className : " "
    } ${full ? "w-full xl:w-auto" : "w-full min-w-48 md:w-1/2 md:px-3"}`}
  >
    <label className="w-full" htmlFor={name}>
      {fieldname && <p className="mb-2 font-medium label">{fieldname}</p>}
      <div className={`rounded-2xl p-0 w-full`}>
        <div className="flex relative w-full z-10">
          {name === "email" && (
            <Mail className={`input-icon ${error ? "error" : ""}`} />
          )}
          {name === "phone" && (
            <Phone className={`input-icon ${error ? "error" : ""}`} />
          )}
          {type === "search" && (
            <Search className={`input-icon search ${error ? "error" : ""}`} />
          )}
          <input
            className={`w-full bg-white overflow-x-auto ${
              error ? "error" : ""
            }`}
            name={name}
            type={type}
            {...props}
          />
          {color && (
            <div
              style={{ backgroundColor: color }}
              className="color border rounded-r-2xl border-grey ml-auto w-16 h-full absolute top-0 right-0 bottom-0"
            />
          )}
        </div>
        {error && <div className="form-error relative">{error}</div>}
      </div>
    </label>
  </div>
);

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  fieldname: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};
export default Input;
