import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect } from "react";
import { get } from "../../../api";
import { API_URL, assemblyItems } from "../../../constants";
import { Row } from "./index";
import { store as globalStore } from "../../../store";
import Wardrobe from "./Wardrobe";

export const Closet = observer(
  ({ order, showModel, orderStatus, handleColor }) => {
    const store = useLocalObservable(() => ({
      figures: [],
      lightingProducts: [],
      standAndWallProducts: [],
      interiorProducts: [],
      specialProducts: [],
      productGroups: [],
      loading: true,
      profileType: null,
      assemblyOption: null,
      getProductType(group_id) {
        return this.productGroups.find((pg) => pg.id === group_id);
      },
    }));
    useEffect(() => {
      async function fetchData() {
        try {
          if (
            !globalStore.productCategories ||
            globalStore.productCategories.length === 0
          ) {
            const res = await get("/product_groups?limit=100");
            store.productGroups = res.data.results;
          } else {
            store.productGroups = globalStore.productCategories;
          }
          if (order && order.wardrobe && order.wardrobe.height) {
            const res = await get("/layouts/" + order.wardrobe.height);
            store.figures = res.data;
          }
          if (order) {
            store.lightingProducts = order.rows.filter((prod) =>
              store.getProductType(prod.product.group_id)
                ? store.getProductType(prod.product.group_id).category ===
                  "lighting"
                : false
            );
            store.interiorProducts = order.rows.filter((prod) =>
              store.getProductType(prod.product.group_id)
                ? store.getProductType(prod.product.group_id).category ===
                  "interior"
                : false
            );
            store.specialProducts = order.rows.filter((prod) =>
              store.getProductType(prod.product.group_id)
                ? store.getProductType(prod.product.group_id).category ===
                  "special"
                : false
            );
            store.standAndWallProducts = order.rows.filter((prod) =>
              store.getProductType(prod.product.group_id)
                ? store.getProductType(prod.product.group_id).category ===
                  "stand_and_wallmounted"
                : false
            );
            if (order.wardrobe) {
              const profileType = store.getProductType(
                order.wardrobe.profile.group_id
              );
              store.profileType = profileType.id;
            }
            store.assemblyOption = assemblyItems.find((obj) => {
              return obj.value === order.assembly_option;
            });
          }
        } catch (err) {
          console.log(err);
        } finally {
          store.loading = false;
        }
      }
      fetchData();
    }, []);

    if (store.loading) {
      return <p>Hämtar garderobdata</p>;
    }

    return (
      <div className="flex flex-col">
        {showModel ? (
          <>
            {order.wardrobe.wardrobe_type !== "only_interior" && (
              <Wardrobe
                wardrobe={order.wardrobe}
                profileType={store.profileType}
              />
            )}
            {order.wardrobe.wardrobe_type !== "only_doors" && (
              <Wardrobe
                wardrobe={order.wardrobe}
                profileType={store.profileType}
                handleColor={handleColor}
                interior
              />
            )}
          </>
        ) : (
          <div className="flex flex-col overflow-hidden">
            {order && (
              <>
                {order.wardrobe && (
                  <>
                    <Row openDefault title="Bas">
                      <Bas wardrobe={order.wardrobe} />
                    </Row>
                    <Row openDefault title="Dörrar">
                      <Doors
                        getProductType={store.getProductType}
                        wardrobe={order.wardrobe}
                      />
                    </Row>
                    <Row openDefault title="Fält">
                      <Fields
                        figures={store.figures}
                        doors={order.wardrobe.doors}
                      />
                    </Row>
                    <Row openDefault title="Belysning">
                      <Lighting
                        products={
                          orderStatus !== "offer"
                            ? store.lightingProducts
                            : null
                        }
                        wardrobe={order.wardrobe}
                      />
                    </Row>
                  </>
                )}
                {orderStatus !== "offer" && (
                  <>
                    {store.interiorProducts.length > 0 && (
                      <Row openDefault title="Möbelinredning">
                        <Products products={store.interiorProducts} />
                      </Row>
                    )}
                    {store.specialProducts.length > 0 && (
                      <Row openDefault title="Specialprodukter">
                        <Products products={store.specialProducts} />
                      </Row>
                    )}
                    {store.standAndWallProducts.length > 0 && (
                      <Row openDefault title="Stativ och vägghängd inredning">
                        <Products products={store.standAndWallProducts} />
                      </Row>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}
        {orderStatus !== "offer" && (
          <div className="flex flex-col py-4">
            <PriceRow label="Summa" price={order ? order.price / 100 : 0} />
            <PriceRow
              label="Förskottsbetalning"
              price={order ? order.advance_payment / 100 : 0}
            />
            <PriceRow
              label="Frakt"
              price={order ? order.shipping_price / 100 : 0}
            />
            <PriceRow label="Rabatt" price={order ? order.discount / 100 : 0} />
            <div className="flex w-full px-6 justify-between items-center">
              <p>Montering</p>
              {store.assemblyOption && (
                <p>
                  {store.assemblyOption.title}{" "}
                  {store.assemblyOption.value === "fixed_price"
                    ? `${(order.assembly_price / 100).toLocaleString("sv-SE", {
                        minimumFractionDigits: 2,
                      })} SEK`
                    : null}
                </p>
              )}
            </div>
            <PriceRow
              label="Totalsumma"
              price={order ? order.total_price / 100 : 0}
              large
            />
          </div>
        )}
      </div>
    );
  }
);

const Products = ({ products, lighting }) => (
  <>
    <div className="hidden xl:flex flex-col">
      <div className="flex">
        <div
          className="px-6 py-2 text-xs space-x-4 grid w-3/4 h-full bg-grey-lighter"
          style={{ gridTemplateColumns: "1fr 3fr 2fr 1fr 1fr" }}
        >
          <p>Art.nr</p>
          <p>Produkt</p>
          <p>Mått</p>
          <p>{lighting ? "V och W" : "Färg"}</p>
          <p>Antal</p>
        </div>

        <div className="w-1/4 border-l border-grey h-full bg-grey-lighter px-6 py-2 text-xs space-x-4 grid grid-cols-2">
          <p className="text-right">Styckpris</p>
          <p className="text-right">Pris</p>
        </div>
      </div>
      <div className="flex flex-wrap">
        {products.map(
          (row) =>
            row.configuration && (
              <React.Fragment key={row.id}>
                <div
                  className="grid py-4 px-6 space-x-4 w-3/4"
                  style={{ gridTemplateColumns: "1fr 3fr 2fr 1fr 1fr" }}
                >
                  <p>{row.configuration.meta.article_number}</p>
                  <p>{row.product.name}</p>
                  <p>{row.configuration.meta.dimensions}</p>
                  <p>
                    {lighting
                      ? row.configuration.meta.v_and_w
                      : row.configuration.meta.color}
                  </p>
                  <p>{row.quantity}</p>
                </div>
                <div className="grid grid-cols-2 px-6 py-4 w-1/4 border-l border-grey">
                  <p className="text-right font-medium">
                    {row.configuration.price
                      ? row.configuration.price / 100
                      : row.product.price / 100}{" "}
                    SEK
                  </p>
                  <p className="text-right font-medium">
                    {row.price / 100} SEK
                  </p>
                </div>
              </React.Fragment>
            )
        )}
      </div>
    </div>
    <div className="flex flex-col xl:hidden">
      {products.map(
        (row) =>
          row.configuration && (
            <React.Fragment key={row.id}>
              <div className="w-full flex border-t border-b">
                <p className="w-24 px-4 py-2 text-opacity-50 uppercase text-right">
                  Art.nr
                </p>
                <p className="px-4 py-2">
                  {row.configuration.meta.article_number}
                </p>
              </div>
              <div className="flex">
                <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                  Produkt
                </p>
                <p className="px-4 py-2">{row.product.name}</p>
              </div>
              {row.configuration.meta.dimensions && (
                <div className="flex">
                  <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                    Mått
                  </p>
                  <p className="px-4 py-2">
                    {row.configuration.meta.dimensions}
                  </p>
                </div>
              )}
              {(row.configuration.meta.v_and_w ||
                row.configuration.meta.color) && (
                <div className="flex">
                  <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                    {lighting ? "V och W" : "Färg"}
                  </p>
                  <p className="px-4 py-2">
                    {row.configuration.meta.v_and_w}
                    {lighting
                      ? row.configuration.meta.v_and_w
                      : row.configuration.meta.color}
                  </p>
                </div>
              )}
              <div className="flex">
                <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                  Antal
                </p>
                <p className="px-4 py-2">{row.quantity}</p>
              </div>
              <div className="flex">
                <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                  Styckpris
                </p>
                <p className="px-4 py-2">
                  {row.configuration.price
                    ? row.configuration.price / 100
                    : row.product.price / 100}{" "}
                  SEK
                </p>
              </div>
              <div className="flex">
                <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                  Pris
                </p>
                <p className="px-4 py-2">{row.price / 100} SEK</p>
              </div>
            </React.Fragment>
          )
      )}
    </div>
  </>
);

const PriceRow = ({ label, price, large }) => (
  <div
    className={`flex w-full px-6 justify-between items-center ${
      large ? "py-4" : "py-1"
    }`}
  >
    <p className={large ? "font-medium text-lg" : ""}>{label}</p>
    <p className={large ? "font-medium text-lg" : ""}>
      {label === "Rabatt" && "- "}
      {label === "Frakt" && "+ "}
      {price.toLocaleString("sv-SE", { minimumFractionDigits: 2 })} SEK
    </p>
  </div>
);

export const Doors = ({ wardrobe, getProductType }) => {
  const doorType = {
    sliding: "Skjutdörrar",
    folding: "Vikdörrar",
    hinged: "Gånggärnsdörrar",
  };
  return (
    <div className="flex flex-col">
      <TopRow />
      <ContentRow>
        <Item label="Typ av dörrar" value={doorType[wardrobe.door_type]} />
        <Item label="Antal" value={wardrobe.door_count} />
        <Item label="Dörrbredd" value={wardrobe.door_width.toFixed(2) + "mm"} />
      </ContentRow>
      <ContentRow price={wardrobe.rail_price / 100}>
        <Item label="Spår" value={wardrobe.rail_count} />
        <Item label="Bredd" value={wardrobe.rail_width.toFixed(2) + "mm"} />
      </ContentRow>
      <ContentRow price={wardrobe.door_extras_price / 100}>
        <Item label="Mjukstängning" value={wardrobe.soft_close_count} />
        <Item label="Fitlister" value={wardrobe.damping_strips} />
      </ContentRow>
      <ContentRow price={wardrobe.profile_price / 100}>
        <Item
          label="Profil"
          value={
            wardrobe.profile
              ? getProductType(wardrobe.profile.group_id)
                ? getProductType(wardrobe.profile.group_id).name
                : ""
              : ""
          }
        />
        <Item
          label="Färg"
          value={wardrobe.profile ? wardrobe.profile.name : ""}
        />
      </ContentRow>
    </div>
  );
};

export const Fields = observer(({ doors, figures }) => (
  <>
    {doors &&
      doors.map((door, i) => (
        <FieldsRow key={door.id} figures={figures} door={door} pos={i} />
      ))}
  </>
));

const FieldsRow = observer(({ door, pos, figures }) => {
  const figure = figures.find((fig) => fig.id === door.layout_id);
  const barTypes = {
    sectioned: "Avdelande",
    glued: "Limmade",
  };
  return (
    <div className="flex overflow-x-auto">
      <div className="flex flex-wrap items-start flex-1 lg:w-3/4 p-6 border-b border-grey-light">
        <p className="mr-6">Dörr {pos + 1}</p>
        {figure && <img className="mr-6 mb-6" src={API_URL + figure.image} />}
        <div className="flex flex-col">
          <div className="flex">
            {figure && <Item label="Fältindelning" value={figure.name} />}
            <div className="flex flex-col">
              {door &&
                door.sections.map((section, i) => (
                  <div
                    key={section.id}
                    className="flex pb-6 last:pb-0 space-x-4 justify-between"
                  >
                    <Item
                      label={"Fält " + (i + 1)}
                      value={`${section.area_color.product_group.name}: ${section.area_color.name}`}
                    />
                    <Item
                      label="Fälthöjd"
                      value={section.height.toFixed(2) + "mm"}
                    />
                  </div>
                ))}
            </div>
          </div>
          {!!door.bar_count && door.bar_count > 0 && (
            <div className="flex pt-6">
              <Item label="Spröjs" value={barTypes[door.bar_type]} />
              <Item label="Antal" value={door.bar_count} />
            </div>
          )}
        </div>
      </div>
      <div className="flex w-40 lg:w-1/4 border-b border-l flex-col border-grey p-6 whitespace-nowrap ml-auto">
        {!!door.price && (
          <p className="font-medium mt-6 ml-auto">{door.price / 100} SEK</p>
        )}
        {!!door.bar_price && (
          <p className="font-medium mt-auto ml-auto">
            {door.bar_price / 100} SEK
          </p>
        )}
      </div>
    </div>
  );
});

const Lighting = ({ wardrobe, products }) => (
  <div className="flex flex-col">
    <TopRow />
    <ContentRow separator={true} price={wardrobe.door_lighting_cost / 100}>
      <Item
        label="Dörrbelysning"
        value={wardrobe.door_lighting ? "Ja" : "Nej"}
      />
    </ContentRow>
    <ContentRow price={wardrobe.interior_lighting_price / 100}>
      <Item
        label="Inredningsbelysning"
        value={wardrobe.has_interior_lighting ? "Ja" : "Nej"}
      />
    </ContentRow>
    {products && products.length > 0 && (
      <Products products={products} lighting />
    )}
  </div>
);

const Bas = ({ wardrobe }) => {
  const placements = {
    center: "Centrerad",
    wall_to_wall: "Vägg till vägg",
    left: "Till vänster",
    right: "Till höger",
  };
  return (
    <div className="flex flex-col">
      <TopRow />
      <ContentRow>
        <Item label="Placering" value={placements[wardrobe.placement]} />
        <Item label="Totalbredd" value={wardrobe.width + "mm"} />
        <Item label="Höjd" value={wardrobe.height + "mm"} />
        {wardrobe.depth > 0 && (
          <Item label="Djup" value={wardrobe.depth + "mm"} />
        )}
        <Item label="Öppningsmått" value={wardrobe.opening_width + "mm"} />
      </ContentRow>
      {wardrobe.bead_count > 0 && (
        <ContentRow price={wardrobe.bead_price / 100}>
          <Item label="Anslagslist" value="Ja" />
          <Item label="Antal" value={wardrobe.bead_count} />
          <Item
            label="Färg"
            value={wardrobe.bead_product_configuration.meta.color}
          />
        </ContentRow>
      )}
      {wardrobe.side_wall_count > 0 && (
        <ContentRow price={wardrobe.side_wall_price / 100}>
          <Item
            label="Sidovägg"
            value={
              wardrobe.side_wall_product_configuration
                ? wardrobe.side_wall_product_configuration.meta.dimensions
                : "-"
            }
          />
          <Item
            label="Färg"
            value={
              wardrobe.side_wall_product_configuration
                ? wardrobe.side_wall_product_configuration.meta.color
                : "-"
            }
          />
          <Item label="Antal" value={wardrobe.side_wall_count} />
        </ContentRow>
      )}
      <ContentRow price={wardrobe.sloping_cieling_price / 100}>
        <Item
          label="Snedtak bakåt"
          value={wardrobe.sloping_cieling ? "Ja" : "Nej"}
        />
        {wardrobe.sloping_cieling && (
          <Item
            label="Antal snedtaksvinklar"
            value={wardrobe.sloping_cieling_count}
          />
        )}
      </ContentRow>
    </div>
  );
};

const TopRow = () => (
  <div className="flex h-8">
    <span className="flex w-3/5 lg:w-3/4 h-full bg-grey-lighter" />
    <div className="flex items-center w-2/5 lg:w-1/4 border-l border-grey h-full bg-grey-lighter">
      <p className="text-sm ml-auto pr-6 text-medium">Pris</p>
    </div>
  </div>
);
const ContentRow = ({ children, price, separator }) => (
  <div className={`flex ${separator ? "border-b border-grey-light" : ""}`}>
    <div className="flex flex-wrap w-3/5 lg:w-3/4 px-6 py-3">{children}</div>
    <div className="flex w-2/5 lg:w-1/4 border-l border-grey items-end py-3">
      {!!price && <p className="font-medium ml-auto pr-6">{price} SEK</p>}
    </div>
  </div>
);
const Item = ({ label, value }) => (
  <div className="flex flex-col mr-6">
    <p className="text-xs">{label}</p>
    <p className="font-medium pt-3 min-w-100px">{value}</p>
  </div>
);
