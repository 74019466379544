import React from "react";
import PropTypes from "prop-types";

const MeasureInput = ({
  label,
  prependLabel,
  info,
  className = "",
  small,
  disabled,
  error,
  ...props
}) => {
  return (
    <label className={`flex flex-col w-full`}>
      {label && (
        <p className="text-grey-dark font-medium mb-2 label whitespace-nowrap">
          {label}
        </p>
      )}
      <div
        className={`input-wrapper w-full flex flex-col relative rounded-2xl group${
          info ? " bg-grey-lighter" : ""
        }`}
      >
        <div className="flex">
          <input
            type="number"
            className={`text-grey-dark flex-1 border border-grey border-r-0 rounded-2xl rounded-r-none px-6 ${className} ${
              small ? "w-20" : ""
            }${info ? " bg-white" : ""}${
              disabled
                ? " bg-grey-lighter"
                : " group-hover:border-blue group-hover:text-blue"
            }${error ? " border-orange-light" : ""}`}
            {...props}
            disabled={disabled}
          />
          {prependLabel && (
            <div
              className={`flex flex-nowrap input-prepend bg-grey-lighter text-grey-dark rounded-2xl rounded-l-none border border-l-0 border-grey ${
                error ? " border-orange-light" : ""
              } ${
                disabled
                  ? ""
                  : "group-hover:bg-blue-light group-hover:text-blue group-hover:border-blue"
              }`}
            >
              <span className="flex items-center px-5 whitespace-nowrap ">
                {prependLabel}
              </span>
            </div>
          )}
        </div>
        {info && (
          <div className="w-full text-xs text-grey-dark rounded-b-2xl px-6 py-3">
            {info}
          </div>
        )}
        {error && <div className="text-xs text-orange px-6 pb-3">{error}</div>}
      </div>
    </label>
  );
};

MeasureInput.propTypes = {
  label: PropTypes.string,
  prependLabel: PropTypes.string.isRequired,
  info: PropTypes.string,
  className: PropTypes.string,
  small: PropTypes.any,
};

export default MeasureInput;
