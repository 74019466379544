import React from "react";
import { observer } from "mobx-react";
import WardrobeProduct from "./WardrobeProduct";
import Measurement from "./Measurement";

const WardrobeSection = observer(
  ({ section, wardrobeWidth, color, showMeasurement, handleColor }) => {
    return (
      <div
        className="absolute h-full"
        style={{
          left: `${(section.position_x / wardrobeWidth) * 100}%`,
          width: `${(section.width / wardrobeWidth) * 100}%`,
        }}
      >
        {section.products.map((product) => (
          <WardrobeProduct
            key={product.id}
            product={product}
            color={color}
            sectionType={section.type}
            handleColor={handleColor}
          />
        ))}
        {showMeasurement && <Measurement size={section.width} type="bottom" />}
      </div>
    );
  }
);

export default WardrobeSection;
