import { useLocalObservable, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { get } from "../../api";
import { ToggleBox, ToggleRow } from "../../components";
import { AddCircle, DownArrow, Loading } from "../../icons";
import { store } from "../../store";

const ToggleSection = observer(
  ({ type, link, customers, page, maxPage, loadMoreCustomers }) => {
    return (
      <ToggleBox
        className="mb-4 md:mb-7"
        title={type === "company" ? "Företag" : "Privat"}
        // linkTitle="Visa alla"
        // link={link}
      >
        <div className="rounded-b-2xl overflow-auto lg:overflow-hidden">
          {customers.length < 1 ? (
            <div className="p-4 md:p-7 text-grey-dark">
              Inga kunder finns inlagda
            </div>
          ) : (
            <div className={type}>
              <div className="md:hidden">
                {customers.map((customer) => (
                  <ToggleRow
                    key={customer.id}
                    title={
                      type === "company"
                        ? customer.company_name
                        : customer.first_name + " " + customer.last_name
                    }
                  >
                    <table className="text-grey-dark table-fixed w-full">
                      <tbody>
                        <tr>
                          <th className="bg-grey-lighter w-2/5 py-2 pt-4 px-4 text-sm text-right font-medium">
                            Kundnummer
                          </th>
                          <td className="py-2 pt-4 px-4 break-words whitespace-pre-line">
                            {customer.customer_number}
                          </td>
                        </tr>
                        <tr>
                          <th className="bg-grey-lighter w-2/5 py-2 px-4 text-sm text-right font-medium">
                            E-post
                          </th>
                          <td className="py-2 px-4 break-words">
                            {type === "company"
                              ? customer.contact_email
                              : customer.email}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className={`bg-grey-lighter w-2/5 py-2 px-4 text-sm text-right font-medium ${
                              type !== "company" && "pb-4"
                            }`}
                          >
                            Telefon
                          </th>
                          <td
                            className={`py-2 px-4 break-words ${
                              type !== "company" && "pb-4"
                            }`}
                          >
                            <a
                              className="hover:text-blue hover:underline"
                              href={`tel: ${customer.contact_phone}`}
                              title={customer.contact_phone}
                            >
                              {customer.contact_phone}
                            </a>
                          </td>
                        </tr>
                        {type === "company" && (
                          <tr className="w-full">
                            <th className="bg-grey-lighter w-2/5 py-2 pb-4 px-4 text-sm text-right font-medium align-top">
                              Avtal
                            </th>
                            <td className="py-2 pb-4 px-4 align-top break-words flex">
                              {customer.agreement}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="border-t border-grey-light flex justify-center w-full">
                      <Link className="flex p-4" to={`${link}/${customer.id}`}>
                        <p className="pr-2">Visa mer</p>
                        <DownArrow className="w-5 transform transition-all -rotate-90" />
                      </Link>
                    </div>
                  </ToggleRow>
                ))}
              </div>
              <table className="w-full hidden md:table">
                {type === "company" ? (
                  <tbody>
                    <tr className="bg-grey-lighter border-b border-grey">
                      <th className="text-left py-2 pl-6 text-sm font-medium">
                        <p>Kundnummer</p>
                      </th>
                      <th className="text-left py-2 pl-4 text-sm font-medium">
                        <p>Företagsnamn</p>
                      </th>
                      <th className="text-left py-2 pl-4 text-sm xl:table-cell hidden font-medium">
                        <p>Avtal</p>
                      </th>
                      <th className="text-left py-2 pl-4 text-sm font-medium">
                        <p>E-post</p>
                      </th>
                      <th className="text-left py-2 pl-4 text-sm font-medium lg:table-cell hidden">
                        <p>Telefon</p>
                      </th>
                      <th></th>
                    </tr>
                    {customers.map((customer) => (
                      <tr key={customer.id}>
                        <td className="pl-6 py-6">
                          {customer.customer_number}
                        </td>
                        <td className="pl-4 py-6">{customer.company_name}</td>
                        <td className="pl-4 py-6 xl:table-cell hidden">
                          {customer.agreement}
                        </td>
                        <td className="pl-4 py-6">
                          <a
                            className="hover:text-blue hover:underline"
                            href={`mailto: ${customer.contact_email}`}
                            title={customer.contact_email}
                          >
                            {customer.contact_email}
                          </a>
                        </td>
                        <td className="pl-4 py-6 lg:table-cell hidden">
                          <a
                            className="hover:text-blue hover:underline"
                            href={`tel: ${customer.contact_phone}`}
                            title={customer.contact_phone}
                          >
                            {customer.contact_phone}
                          </a>
                        </td>
                        <td className="flex justify-end">
                          <Link
                            className="flex p-6"
                            to={`${link}/${customer.id}`}
                          >
                            <DownArrow className="w-4 transform transition-all -rotate-90" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className="bg-grey-lighter border-b border-grey">
                      <th className="text-left py-2 pl-6 text-sm font-medium">
                        <p>Kundnummer</p>
                      </th>
                      <th className="text-left py-2 pl-4 text-sm font-medium">
                        <p>Förnamn</p>
                      </th>
                      <th className="text-left py-2 pl-4 text-sm font-medium">
                        <p>Efternamn</p>
                      </th>
                      <th className="text-left py-2 pl-4 text-sm font-medium">
                        <p>E-post</p>
                      </th>
                      <th className="text-left py-2 pl-4 text-sm font-medium lg:table-cell hidden">
                        <p>Telefon</p>
                      </th>
                      <th></th>
                    </tr>
                    {customers.map((customer) => (
                      <tr key={customer.id}>
                        <td className="pl-6 py-6">
                          {customer.customer_number}
                        </td>
                        <td className="pl-4 py-6">{customer.first_name}</td>
                        <td className="pl-4 py-6">{customer.last_name}</td>
                        <td className="pl-4 py-6">
                          <a
                            className="hover:text-blue hover:underline"
                            href={`mailto: ${customer.email}`}
                            title={customer.email}
                          >
                            {customer.email}
                          </a>
                        </td>
                        <td className="pl-4 py-6 lg:table-cell hidden">
                          <a
                            className="hover:text-blue hover:underline"
                            href={`tel: ${customer.contact_phone}`}
                            title={customer.contact_phone}
                          >
                            {customer.contact_phone}
                          </a>
                        </td>
                        <td className="flex justify-end">
                          <Link
                            className="flex p-6"
                            to={`${link}/${customer.id}`}
                          >
                            <DownArrow className="w-4 transform -rotate-90" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
          {page < maxPage && (
            <div
              onClick={() => {
                if (store.loadingMore) {
                  return;
                }
                loadMoreCustomers(type, page);
              }}
              className="cursor-pointer p-6 bg-grey-light flex items-center space-x-4"
            >
              {store.loadingMore ? (
                <Loading />
              ) : (
                <>
                  <AddCircle />
                  <p>Tryck här för att ladda in mer</p>
                </>
              )}
            </div>
          )}
        </div>
      </ToggleBox>
    );
  }
);

const Customers = observer(({ searchTerm }) => {
  const limit = 10;
  const store = useLocalObservable(() => ({
    loadingMore: false,
    pages: {
      person: 0,
      company: 0,
      maxPersons: 0,
      maxCompanies: 0,
    },
    personCustomers: [],
    companyCustomers: [],
    getMoreCustomers(type, page) {
      store.loadingMore = true;
      get(`/customers?limit=${limit}&type=${type}&page=${page}`).then(
        (response) => {
          store.loadingMore = false;
          if (type === "company") {
            store.pages.company += 1;
            this.companyCustomers.push(...response.data.results);
          } else {
            store.pages.person += 1;
            this.personCustomers.push(...response.data.results);
          }
        }
      );
    },
  }));

  useEffect(() => {
    get(
      `/customers?limit=${limit}&type=company${
        searchTerm ? `&s=${searchTerm}` : ""
      }`
    ).then((response) => {
      store.companyCustomers = response.data.results;
      if (response.data.count > limit) {
        store.pages.person = 2;
        store.pages.maxCompanies = Math.ceil(response.data.count / limit);
      }
    });
    get(
      `/customers?limit=${limit}&type=person${
        searchTerm ? `&s=${searchTerm}` : ""
      }`
    ).then((response) => {
      store.personCustomers = response.data.results;
      if (response.data.count > limit) {
        store.pages.company = 2;
        store.pages.maxPersons = Math.ceil(response.data.count / limit);
      }
    });
  }, [searchTerm]);

  return (
    <div className="flex flex-col">
      <ToggleSection
        type="company"
        page={store.pages.company}
        maxPage={store.pages.maxCompanies}
        link="/customers/company"
        loadMoreCustomers={store.getMoreCustomers}
        customers={store.companyCustomers}
      />
      <ToggleSection
        title="Privat"
        type="person"
        page={store.pages.person}
        maxPage={store.pages.maxPersons}
        link="/customers/person"
        loadMoreCustomers={store.getMoreCustomers}
        customers={store.personCustomers}
      />
    </div>
  );
});

export default Customers;
