import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Cross } from "../../icons";
import Circle from "../circle";

const Page = ({ title, children, backLink, header }) => {
  useEffect(() => {
    document.title = title || "";
  }, [title]);
  return (
    <>
      <div className={`flex flex-wrap pb-6 items-center`}>
        {title && <h1>{title}</h1>}
        {header}
        {backLink && (
          <Circle link={backLink} className="ml-auto">
            <Cross />
          </Circle>
        )}
      </div>
      {children}
    </>
  );
};

Page.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
};
export default Page;
