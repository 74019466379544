import React, { useRef, useEffect } from "react";
import { observer, useLocalObservable } from "mobx-react";
import { Circle, Input, MeasureInput, Select } from "../../../components";
import { AddCircle, Checkmark, MinusCircle, Upload } from "../../../icons";
import PropTypes from "prop-types";
import {
  API_URL,
  BodyColors,
  ColorGroupItems,
  ColorGroups,
  ColorNames,
  ConfiguratorCategories,
  DetailColors,
  HandleColors,
  SectionTypes,
} from "../../../constants";
import { get } from "../../../api";

export const AddArea = observer(({ store, image, edit, activeProduct }) => {
  const inputRef = useRef();
  return (
    <form onSubmit={store.handleAreaSubmit}>
      <div className="box p-2 mt-6 xl:mt-7 md:p-3">
        <div className="flex-wrap flex">
          <Select
            className="w-full p-2 sm:w-1/2 md:p-4 xl:w-1/3 md:max-w-xs z-20"
            label="Typ"
            error={store.errors.group_id}
            type="product_category"
            items={store.productCategories.filter(
              (cat) => cat.category === activeProduct
            )}
            initialItem={store.productType}
            value={store.productType}
            onChange={(value) => {
              store.productType = value;
            }}
          />
          <Input
            error={store.errors.name}
            value={store.product ? store.product.name : ""}
            onChange={(e) => (store.product.name = e.target.value)}
            className="p-2 w-full sm:w-1/2 min-w-48 md:p-4 md:max-w-xs xl:w-1/3"
            name="name"
            fieldname="Namn"
          />
          <Select
            className="w-full p-2 pt-0 md:p-4 md:pt-2 sm:w-1/2 md:max-w-xs xl:w-1/3 xl:pt-4 z-0"
            label="Prisgrupp"
            type="price_groups"
            items={store.priceGroups}
            initialItem={store.selectedPriceGroup}
            onChange={(value) => (store.selectedPriceGroup = value)}
            error={store.errors.price_groups}
          />
        </div>
        <div className="p-2 pt-4 md:p-4 xl:pt-0 flex flex-col sm:flex-row items-start">
          <div className="flex items-center sm:w-1/2 sm:pr-2 md:pr-4 xl:w-1/3 md:max-w-xs">
            <input
              onChange={store.handleUpload}
              type="file"
              accept="image/png, image/jpeg"
              name="file"
              ref={inputRef}
              className="hidden"
            />
            <Circle
              type="button"
              actionText="Ladda upp texturbild"
              onClick={(e) => {
                inputRef.current.click();
                e.preventDefault();
              }}
            >
              <Upload />
            </Circle>
          </div>
          {image && !store.file && (
            <div
              className="mt-6 sm:mt-0 sm:ml-2 md:ml-4 max-w-44 h-32 border-grey border rounded-lg overflow-hidden relative group"
              onClick={() => {
                store.file = null;
                store.filePath = null;
                store.product.image = null;
                inputRef.current.value = null;
              }}
            >
              <div className="justify-center items-center absolute inset-0 bg-black bg-opacity-50 cursor-pointer hidden group-hover:flex z-20">
                <span className="round-button button">
                  <MinusCircle />
                </span>
              </div>
              <img
                className="flex flex-1 w-full h-full bg-cover"
                src={API_URL + image}
              />
              <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center pl-3">
                <p>{store.product ? store.product.name : ""}</p>
              </div>
            </div>
          )}
          {store.file && (
            <div
              className="mt-6 sm:mt-0 sm:ml-2 md:ml-4 max-w-44 h-32 border-grey border rounded-lg overflow-hidden relative group"
              onClick={() => {
                store.file = null;
                store.filePath = null;
                inputRef.current.value = null;
              }}
            >
              <div className="justify-center items-center absolute inset-0 bg-black bg-opacity-50 cursor-pointer hidden group-hover:flex z-20">
                <span className="round-button button">
                  <MinusCircle />
                </span>
              </div>
              <img
                className="flex flex-1 w-full h-full bg-cover"
                src={URL.createObjectURL(store.file)}
              />
              <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center pl-3">
                <p>{store.file.name}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {!edit && (
        <div className="flex items-center mt-6">
          <Circle
            actionText="Lägg till ny produkt"
            value="submit"
            type="button"
          >
            <Checkmark />
          </Circle>
        </div>
      )}
    </form>
  );
});

AddArea.propTypes = {
  image: PropTypes.string,
  store: PropTypes.object.isRequired,
};

export const AddProfile = observer(
  ({ store, inputRef, image, edit, activeProduct }) => {
    return (
      <form onSubmit={store.handleProfileSubmit}>
        <div className="box p-2 mt-6 xl:mt-7 md:p-3">
          <div className="flex flex-wrap py-2 md:pt-4">
            <Select
              className="p-2 md:px-4 w-full sm:w-1/2 md:max-w-xs lg:max-w-xs xl:max-w-xs xl:w-1/3 z-20"
              label="Typ"
              error={store.errors.group_id}
              type="product_category"
              items={store.productCategories.filter(
                (cat) => cat.category === activeProduct
              )}
              initialItem={store.productType}
              value={store.productType}
              onChange={(value) => (store.productType = value)}
            />
            <Input
              error={store.errors.name}
              className="p-2 md:px-4 w-full sm:w-1/2 md:max-w-xs xl:w-1/3 min-w-48"
              name="name"
              fieldname="Namn"
              onChange={(e) => (store.product.name = e.target.value)}
              value={store.product ? store.product.name : ""}
            />
            <div className="p-2 pt-0 md:pt-2 md:px-4 w-full sm:w-1/2 md:max-w-xs xl:w-1/3 min-w-48">
              <MeasureInput
                small
                className="bg-white"
                name="price"
                prependLabel="SEK / dörr"
                value={parseInt(store.product.price, 10) / 100}
                label="Pris"
                onChange={(e) => (store.product.price = e.target.value * 100)}
              />
            </div>
          </div>
          <div className="my-2 md:my-4 xl:mt-0 flex flex-col sm:flex-row items-start">
            <div className="w-full sm:w-1/2 xl:w-1/3 md:max-w-xs min-w-48">
              <Input
                error={store.errors.color}
                className="w-full px-2 md:px-4 md:w-full"
                value={store.color}
                color={store.color}
                fieldname="Färgkod"
                name="color"
                onChange={(value) => store.handleColorChange(value)}
              />
              <div className="flex items-center p-2 md:p-4">
                <input
                  onChange={store.handleUpload}
                  type="file"
                  accept="image/png, image/jpeg"
                  name="file"
                  ref={inputRef}
                  className="hidden"
                />
                <Circle
                  type="button"
                  onClick={(e) => {
                    inputRef.current.click();
                    e.preventDefault();
                  }}
                  actionText="Ladda upp texturbild"
                >
                  <Upload />
                </Circle>
              </div>
            </div>
            {/* this is for the color */}
            {store.color && !image && !store.file && (
              <div className="mx-2 md:mx-4 mt-2 sm:mt-8 border-grey border rounded-lg w-full max-w-44 h-32 overflow-hidden relative">
                <div
                  className="flex flex-1 w-full h-full"
                  style={{ backgroundColor: store.color }}
                ></div>
                <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center px-3">
                  <p>{store.product ? store.product.name : ""}</p>
                </div>
              </div>
            )}
            {image && !store.file && (
              <div
                className="mx-2 md:mx-4 mt-2 sm:mt-8 max-w-44 h-32 border-grey-darker border rounded-lg overflow-hidden relative group"
                onClick={() => {
                  store.file = null;
                  store.filePath = null;
                  store.product.image = null;
                  inputRef.current.value = null;
                }}
              >
                <div className="justify-center items-center absolute inset-0 bg-black bg-opacity-50 cursor-pointer hidden group-hover:flex z-20">
                  <span className="round-button button">
                    <MinusCircle />
                  </span>
                </div>
                <img
                  className="flex flex-1 w-full h-full bg-cover"
                  src={API_URL + image}
                />
                <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center px-3">
                  <p>{store.product ? store.product.name : ""}</p>
                </div>
              </div>
            )}
            {store.file && (
              <div
                className="mx-2 md:mx-4 mt-2 sm:mt-8 max-w-44 h-32 border-grey-darker border rounded-lg overflow-hidden relative group"
                onClick={() => {
                  store.file = null;
                  store.filePath = null;
                  inputRef.current.value = null;
                }}
              >
                <div className="justify-center items-center absolute inset-0 bg-black bg-opacity-50 cursor-pointer hidden group-hover:flex z-20">
                  <span className="round-button button">
                    <MinusCircle />
                  </span>
                </div>
                <img
                  className="flex flex-1 w-full h-full bg-cover"
                  src={URL.createObjectURL(store.file)}
                />
                <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center px-3">
                  <p>{store.file.name}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        {!edit && (
          <div className="flex items-center mt-6">
            <Circle
              actionText="Lägg till ny produkt"
              value="submit"
              type="button"
            >
              <Checkmark />
            </Circle>
          </div>
        )}
      </form>
    );
  }
);

AddProfile.propTypes = {
  edit: PropTypes.bool,
  activeProduct: PropTypes.string,
  store: PropTypes.object.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export const AddCatProduct = observer(({ store, activeCategory, edit }) => {
  const removeFromVariants = (i) => {
    store.productConfigurations.splice(i, 1);
  };

  const addProductVariant = () => {
    store.productConfigurations.push({
      price: 0,
      meta: {
        dimensions: "",
        article_number: "",
        color: "",
        v_and_w: "",
      },
    });
  };

  const getExtraFieldFromCat = (cat) => {
    switch (cat) {
      case "special":
        return { name: "Färg", value: "color" };
      case "lighting":
        return { name: "V och W", value: "v_and_w" };
      case "stand_and_wallmounted":
        return null;
      case "interior":
        return { name: "Färg", value: "color" };
      default:
        return null;
    }
  };

  const isBaseCategory = store.product.category === ConfiguratorCategories.Base;

  return (
    <form onSubmit={store.handleCatProduct} className="new-edit-product">
      <div className="box py-4 px-2 mt-4 md:py-7 md:px-4 md:mt-7">
        <Input
          className="px-2"
          error={store.errors.name}
          value={store.product.name}
          onChange={(e) => (store.product.name = e.target.value)}
          name="name"
          fieldname="Namn"
        />
        <div className="flex flex-col mt-7">
          <div className="hidden xl:flex mb-3 font-medium">
            <span className="w-20 mr-1"></span>
            <p
              className={
                getExtraFieldFromCat(activeCategory) ? "w-1/4" : "w-1/3"
              }
            >
              Artikelnummer
            </p>
            <p
              className={
                getExtraFieldFromCat(activeCategory) ? "w-1/4" : "w-1/3"
              }
            >
              Mått
            </p>
            {getExtraFieldFromCat(activeCategory) && (
              <p className="w-1/4">
                {getExtraFieldFromCat(activeCategory).name}
              </p>
            )}
            <p
              className={
                getExtraFieldFromCat(activeCategory) ? "w-1/4" : "w-1/3"
              }
            >
              Pris
            </p>
          </div>

          <div className="flex flex-col">
            {store.productConfigurations.map((prod, i) => {
              return (
                <div key={i} className="flex p-row mb-7 xl:mb-0">
                  <div className="pt-8 px-2 xl:pt-1">
                    <Circle
                      className={`mr-auto my-auto ${
                        i === 0 || isBaseCategory ? "opacity-50" : ""
                      }`}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isBaseCategory && i !== 0) {
                          removeFromVariants(i);
                        }
                      }}
                    >
                      <MinusCircle
                        className={`${
                          i === 0 || isBaseCategory ? "opacity-50" : ""
                        } w-5`}
                      />
                    </Circle>
                  </div>
                  <div className="flex flex-col sm:flex-wrap sm:flex-row xl:flex-nowrap w-full">
                    <Input
                      fieldname="Artikelnummer"
                      className={`mb-4 px-2 sm:w-1/2 ${
                        getExtraFieldFromCat(activeCategory)
                          ? "xl:w-1/4"
                          : "xl:w-1/3"
                      }`}
                      placeholder="Ange artikelnummer"
                      name="article-num"
                      type="text"
                      value={prod.meta.article_number}
                      onChange={(e) => {
                        prod.meta.article_number = e.target.value;
                      }}
                      disabled={isBaseCategory}
                    />
                    <div
                      className={`mb-4 px-2 sm:w-1/2 ${
                        getExtraFieldFromCat(activeCategory)
                          ? "xl:w-1/4"
                          : "xl:w-1/3"
                      }`}
                    >
                      <MeasureInput
                        label="Mått"
                        className="bg-white"
                        name="dimensions"
                        type="text"
                        prependLabel="mm"
                        placeholder="Ange mått"
                        value={prod.meta.dimensions}
                        onChange={(e) =>
                          (prod.meta.dimensions = e.target.value)
                        }
                        disabled={isBaseCategory}
                      />
                    </div>
                    {getExtraFieldFromCat(activeCategory) && (
                      <Input
                        fieldname={getExtraFieldFromCat(activeCategory).name}
                        className={`mb-4 px-2 sm:w-1/2 ${
                          getExtraFieldFromCat(activeCategory)
                            ? "xl:w-1/4"
                            : "xl:w-1/3"
                        }`}
                        placeholder={`Ange ${getExtraFieldFromCat(
                          activeCategory
                        ).name.toLowerCase()}`}
                        name={getExtraFieldFromCat(activeCategory).value}
                        value={
                          prod.meta[
                            getExtraFieldFromCat(activeCategory).value
                          ] || ""
                        }
                        onChange={(e) =>
                          (prod.meta[
                            getExtraFieldFromCat(activeCategory).value
                          ] = e.target.value)
                        }
                      />
                    )}
                    <div
                      className={`px-2 sm:w-1/2 ${
                        getExtraFieldFromCat(activeCategory)
                          ? "xl:w-1/4"
                          : "xl:w-1/3"
                      }`}
                    >
                      <MeasureInput
                        label="Pris"
                        className="bg-white"
                        value={prod.price / 100 || ""}
                        onChange={(e) => {
                          prod.price = parseInt(e.target.value, 10) * 100;
                        }}
                        placeholder="Ange pris"
                        prependLabel="SEK"
                        name="price"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {!isBaseCategory && (
            <div className="px-2">
              <Circle
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  addProductVariant();
                }}
              >
                <AddCircle />
              </Circle>
            </div>
          )}
        </div>
      </div>
      {!edit && (
        <Circle
          className="mt-4 md:mt-7"
          actionText="Skapa produkt"
          type="button"
          value="submit"
        >
          <Checkmark />
        </Circle>
      )}
    </form>
  );
});

AddCatProduct.propTypes = {
  store: PropTypes.object.isRequired,
};

const getAccessoryName = (product, config) => {
  let name = product.name;

  const width = config.width || 0;
  const depth = config.depth || 0;
  const height = config.height || 0;

  if (width > 0 || depth > 0 || height > 0) {
    name += ` - ${width}x${depth}x${height}`;
  }

  if (config.color) {
    name += ` - ${ColorNames[config.color]}`;
  }

  return name;
};

export const AddConfiguratorProduct = observer(
  ({ store, edit, isInteriorProduct }) => {
    const inputRef = useRef(null);
    const uploadedImage = store.files.find(
      (file) => file.id === store.product.id
    );
    const addProductConfig = () => {
      store.productConfigurations.push({
        price: 0,
        meta: {
          article_number: "",
        },
        accessories: [],
      });
    };

    const state = useLocalObservable(() => ({
      interiorProducts: [],
      setInteriorProducts(interiorProducts) {
        state.interiorProducts = interiorProducts;
      },
      accessoriesSelectItems: [],
      setAccessoriesSelectItems(accessoriesSelectItems) {
        state.accessoriesSelectItems = accessoriesSelectItems;
      },
    }));

    useEffect(() => {
      async function getAccessories() {
        try {
          const { data: groups } = await get(
            "/product_groups/interior_accessories"
          );
          const interiorProducts = groups.reduce((products, group) => {
            products.push(...group.Products);

            return products;
          }, []);

          state.setInteriorProducts(interiorProducts);

          const accessoriesItems = [];
          interiorProducts.forEach((product) => {
            product.configurations.forEach((config) => {
              accessoriesItems.push({
                title: getAccessoryName(product, config),
                value: {
                  product_id: product.id,
                  product,
                  config_id: config.id,
                  config,
                },
              });
            });
          });
          state.setAccessoriesSelectItems(accessoriesItems);
        } catch (error) {
          console.error(error.message);
        }
      }
      getAccessories();
    }, []);

    return (
      <form
        className="new-edit-product"
        onSubmit={store.handleConfiguratorSubmit}
      >
        <div className="box px-4 py-4 mt-4 md:py-7 md:mt-7">
          <div className="flex flex-wrap border-b pb-4 md:pb-0">
            <Input
              error={store.errors.name}
              value={store.product.name}
              onChange={(e) => (store.product.name = e.target.value)}
              name="name"
              fieldname="Namn"
            />
            {isInteriorProduct ? (
              <>
                <Input
                  value={store.product.number_of_holes || 0}
                  error={store.errors.number_of_holes}
                  onChange={(e) =>
                    (store.product.number_of_holes = e.target.value)
                  }
                  name="number_of_holes"
                  type="number"
                  fieldname="Antal hål"
                />
                <Input
                  value={store.product.hole_offset || 0}
                  error={store.errors.hole_offset}
                  onChange={(e) => (store.product.hole_offset = e.target.value)}
                  name="hole_offset"
                  type="number"
                  fieldname="Hål offset"
                />
                <Select
                  className="flex px-0 md:px-3 flex-1"
                  error={store.errors.color_group}
                  label="Färggrupp"
                  items={ColorGroupItems}
                  initialItem={store.product.color_group}
                  value={store.product.color_group}
                  onChange={(item) => (store.product.color_group = item.value)}
                />
              </>
            ) : (
              <div className="w-full flex flex-col sm:flex-row sm:items-center md:px-3 mb-4">
                <input
                  onChange={(e) => store.setFiles(e, store.product.id)}
                  type="file"
                  accept="image/png, image/jpeg"
                  name="file"
                  ref={inputRef}
                  className="hidden"
                />
                <Circle
                  type="button"
                  actionText="Ladda upp produktbild"
                  onClick={(e) => {
                    inputRef.current.click();
                    e.preventDefault();
                  }}
                >
                  <Upload className="h-5 w-5 hidden lg:block" />
                </Circle>
                {store.product.image && !uploadedImage && (
                  <div
                    className="mt-6 sm:mt-0 sm:ml-2 md:ml-4 max-w-44 h-32 border-grey border rounded-lg overflow-hidden relative group"
                    onClick={() => {
                      store.product.image = null;
                    }}
                  >
                    <div className="justify-center items-center absolute inset-0 bg-black bg-opacity-50 cursor-pointer hidden group-hover:flex z-20">
                      <span className="round-button button">
                        <MinusCircle />
                      </span>
                    </div>
                    <img
                      className="flex flex-1 w-full h-full bg-cover"
                      src={API_URL + store.product.image}
                    />
                    <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center pl-3">
                      <p>{store.product ? store.product.name : ""}</p>
                    </div>
                  </div>
                )}
                {uploadedImage && (
                  <div
                    className="mt-6 sm:mt-0 sm:ml-2 md:ml-4 max-w-44 h-32 border-grey border rounded-lg overflow-hidden relative group"
                    onClick={() => {
                      store.removeFile(store.product.id);
                    }}
                  >
                    <div className="justify-center items-center absolute inset-0 bg-black bg-opacity-50 cursor-pointer hidden group-hover:flex z-20">
                      <span className="round-button button">
                        <MinusCircle />
                      </span>
                    </div>
                    <img
                      className="flex flex-1 w-full h-full bg-cover"
                      src={URL.createObjectURL(uploadedImage.file)}
                    />
                    <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center pl-3">
                      <p>{uploadedImage.file.name}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex flex-col mt-7">
            <div className="flex flex-col">
              {store.productConfigurations.map((config, i) => (
                <ProductConfig
                  key={i}
                  config={config}
                  index={i}
                  edit={edit}
                  store={store}
                  isInteriorProduct={isInteriorProduct}
                  accessoriesSelectItems={state.accessoriesSelectItems}
                />
              ))}
            </div>
            <div className="px-2">
              <Circle
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  addProductConfig();
                }}
              >
                <AddCircle />
              </Circle>
            </div>
          </div>
        </div>
        {!edit && (
          <Circle
            className="mt-4 md:mt-7"
            actionText="Skapa produkt"
            type="button"
            value="submit"
          >
            <Checkmark />
          </Circle>
        )}
      </form>
    );
  }
);

const ProductConfig = observer(
  ({
    store,
    config,
    index,
    edit,
    isInteriorProduct,
    accessoriesSelectItems,
  }) => {
    const inputRef = useRef(null);
    const configId = edit ? config.id : index;
    const uploadedImage = store.files.find((file) => file.id === configId);

    const removeProductConfig = (index) => {
      store.productConfigurations.splice(index, 1);
    };

    const addAccessory = (accessory) => {
      if (!config.accessories) {
        config.accessories = [];
      }

      config.accessories.push(accessory);
    };

    const removeAccessory = (index) => {
      config.accessories.splice(index, 1);
    };

    const filteredAccessories = !config.accessories
      ? accessoriesSelectItems
      : accessoriesSelectItems.filter(
          (item) =>
            !config.accessories.some(
              (accessory) =>
                item.value.config_id === accessory.config_id &&
                item.value.product_id === accessory.product_id
            )
        );

    const isHandleProduct = store.product.name === "Handtag";

    const selectItems = isHandleProduct
      ? HandleColors
      : store.product.color_group === ColorGroups.Body
      ? BodyColors
      : DetailColors;

    return (
      <div key={config.id} className="flex p-row mb-7 pb-7 border-b">
        <div className="pt-8 lg:px-2 xl:pt-1">
          <Circle
            className={`mr-auto my-auto ${index === 0 ? "opacity-50" : ""}`}
            type="button"
            onClick={() => removeProductConfig(index)}
          >
            <MinusCircle className={`${index === 0 ? "opacity-50" : ""} w-5`} />
          </Circle>
        </div>
        <div className="flex flex-col sm:flex-wrap sm:flex-row w-full">
          <div className="flex flex-col p-2">
            <p className="font-medium mb-2">Artikelnummer</p>
            <input
              className="w-full sm:w-48"
              placeholder="Ange artikelnummer"
              type="text"
              value={config.meta.article_number || ""}
              onChange={(e) => {
                config.meta.article_number = e.target.value;
              }}
            />
          </div>
          {isInteriorProduct && (
            <div className="p-2">
              <Select
                className="w-full sm:w-30"
                label="Sektion"
                items={SectionTypes}
                initialItem={config.section_type}
                value={config.section_type}
                onChange={(item) => (config.section_type = item.value)}
              />
            </div>
          )}
          <div className="p-2">
            {isInteriorProduct || isHandleProduct ? (
              <Select
                className="w-full sm:w-30"
                label="Färg"
                items={selectItems}
                initialItem={config.color}
                value={config.color}
                onChange={(item) => {
                  config.color = item.value;
                }}
              />
            ) : (
              <div className="flex flex-col">
                <p className="font-medium mb-2">Färg</p>
                <input
                  className="w-full sm:w-48"
                  placeholder="Ange färg"
                  type="text"
                  value={config.color || ""}
                  onChange={(e) => {
                    config.color = e.target.value;
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col p-2">
            <p className="font-medium hidden xl:block mb-2">Bredd</p>
            <MeasureInput
              label="Bredd"
              className="bg-white w-30"
              name="width"
              type="number"
              prependLabel="mm"
              placeholder="Ange bredd"
              value={config.width || 0}
              onChange={(e) => (config.width = e.target.value)}
            />
          </div>
          <div className="flex flex-col p-2">
            <p className="mb-2 font-medium hidden xl:block">Djup</p>
            <MeasureInput
              label="Djup"
              className="bg-white w-30"
              name="depth"
              type="number"
              prependLabel="mm"
              placeholder="Ange djup"
              value={config.depth || 0}
              onChange={(e) => (config.depth = e.target.value)}
            />
          </div>
          <div className="flex flex-col p-2">
            <p className="mb-2 font-medium hidden xl:block">Höjd</p>
            <MeasureInput
              label="Höjd"
              className="bg-white w-30"
              name="height"
              type="number"
              prependLabel="mm"
              placeholder="Ange höjd"
              value={config.height || 0}
              onChange={(e) => (config.height = e.target.value)}
            />
          </div>
          <div className="flex flex-col p-2">
            <p className="mb-2 font-medium hidden xl:block">Pris</p>
            <MeasureInput
              label="Pris"
              className="bg-white w-30"
              value={config.price / 100 || ""}
              onChange={(e) => {
                config.price = parseInt(e.target.value, 10) * 100;
              }}
              placeholder="Ange pris"
              prependLabel="SEK"
              name="price"
            />
          </div>
          {isInteriorProduct && (
            <div className="w-full p-2">
              <div className="flex flex-col lg:flex-row justify-center lg:justify-start p-2 border rounded-2xl">
                <Select
                  className="w-full sm:max-w-xs"
                  label="Tillbehör"
                  items={filteredAccessories}
                  onChange={(item) => addAccessory(item.value)}
                />
                <ul className="ml-0 md:ml-4 mt-2 lg:mt-0">
                  {config.accessories &&
                    config.accessories.map((accessory, index) => (
                      <li
                        key={`${accessory.id}-${index}`}
                        className="flex justify-between items-center px-2 py-0.5 mt-1 bg-grey-lighter rounded-2xl max-w-xs"
                      >
                        <p>
                          {getAccessoryName(
                            accessory.product,
                            accessory.config
                          )}
                        </p>
                        <div
                          className="ml-2 cursor-pointer"
                          onClick={() => removeAccessory(index)}
                        >
                          X
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}

          {isInteriorProduct && (
            <div className="p-2 pt-4 md:p-4 flex flex-col sm:flex-row items-start w-full">
              <div className="flex items-center sm:pr-2 md:pr-4 md:max-w-xs">
                <input
                  onChange={(e) => store.setFiles(e, configId)}
                  type="file"
                  accept="image/png, image/jpeg"
                  name="file"
                  ref={inputRef}
                  className="hidden"
                />
                <Circle
                  type="button"
                  actionText="Ladda upp texturbild"
                  onClick={(e) => {
                    inputRef.current.click();
                    e.preventDefault();
                  }}
                >
                  <Upload className="h-5 w-5 hidden lg:block" />
                </Circle>
              </div>
              {config.image && !uploadedImage && (
                <div
                  className="mt-6 sm:mt-0 sm:ml-2 md:ml-4 max-w-44 h-32 border-grey border rounded-lg overflow-hidden relative group"
                  onClick={() => {
                    config.image = null;
                  }}
                >
                  <div className="justify-center items-center absolute inset-0 bg-black bg-opacity-50 cursor-pointer hidden group-hover:flex z-20">
                    <span className="round-button button">
                      <MinusCircle />
                    </span>
                  </div>
                  <img
                    className="flex flex-1 w-full h-full bg-cover"
                    src={API_URL + config.image}
                  />
                  <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center pl-3">
                    <p>{store.product ? store.product.name : ""}</p>
                  </div>
                </div>
              )}
              {uploadedImage && (
                <div
                  className="mt-6 sm:mt-0 sm:ml-2 md:ml-4 max-w-44 h-32 border-grey border rounded-lg overflow-hidden relative group"
                  onClick={() => {
                    store.removeFile(edit ? config.id : index);
                  }}
                >
                  <div className="justify-center items-center absolute inset-0 bg-black bg-opacity-50 cursor-pointer hidden group-hover:flex z-20">
                    <span className="round-button button">
                      <MinusCircle />
                    </span>
                  </div>
                  <img
                    className="flex flex-1 w-full h-full bg-cover"
                    src={URL.createObjectURL(uploadedImage.file)}
                  />
                  <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center pl-3">
                    <p>{uploadedImage.file.name}</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
