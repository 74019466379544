import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Circle = ({
  type = "link",
  link = "/",
  onClick = null,
  children,
  className = "",
  actionText,
  infoText,
  ...props
}) => {
  const classes = `${
    actionText ? "text-button" : "round-button"
  } button ${className}`;
  return type === "button" ? (
    <div className="flex flex-col items-center">
      <button onClick={onClick} className={classes} {...props}>
        {children}
        {actionText ? <p>{actionText}</p> : null}
      </button>
      {infoText && <p className="text-xs mt-2">{infoText}</p>}
    </div>
  ) : type === "anchor" ? (
    <a href={link} className={classes} {...props}>
      {children}
      {actionText ? <p>{actionText}</p> : null}
    </a>
  ) : (
    <Link to={link} className={classes} {...props}>
      {children}
      {actionText ? <p>{actionText}</p> : null}
    </Link>
  );
};

Circle.propTypes = {
  type: PropTypes.oneOf(["button", "anchor", "link"]),
  link: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  actionText: PropTypes.string,
};
export default Circle;
