import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { setActiveUser } from "../../utils";
import Home from "../home";
import Settings from "../settings";
import AddUser from "../users/add-user";
import EditUser from "../users/edit-user";
import Customers from "../customers";
import Orders from "../orders";
import AddCustomer from "../customers/add-customer";
import EditCustomer from "../customers/edit-customer";
import EditProduct from "../products/edit-product";
import Products from "../products";
import { Page } from "../../components";
import SingleCustomer from "../customers/single-customer";
import { Observer, observer, useLocalObservable } from "mobx-react";
import AddProduct from "../products/add-product";
import SingleOrder from "../orders/single-order";
import Account from "../settings/account";
import { get } from "../../api";
import { store as globalStore } from "../../store";
import { Sidebar } from "./components";
import {
  CustomerHeader,
  ProductsHeader,
  AddCustomerHeader,
  EditHeader,
  OrdersHeader,
  AddProductHeader,
} from "./components/headers.js";
import AddCampaign from "../campaigns/AddCampaign";
import EditCampaign from "../campaigns/EditCampaign";

const ProductsPage = observer(({ store }) => {
  const { productType } = useParams();

  useEffect(() => {
    store.setActiveProductType(null);
  }, []);

  return (
    <Page
      header={
        <ProductsHeader
          onClick={() => (store.showProductGroups = !store.showProductGroups)}
          showProductGroups={store.showProductGroups}
          activeProductType={store.activeProductType}
          initialProduct={productType}
          onChange={(value) => store.setActiveProductType(value)}
        />
      }
      title="Produkter"
    >
      <Products
        showPriceGroups={store.showProductGroups}
        productType={store.activeProductType}
      />
    </Page>
  );
});

const Admin = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const store = useLocalObservable(() => ({
    search: "",
    customerSearch: "",
    showProductGroups: false,
    showMenu: false,
    activeProductType: null,
    showAddMenu: false,
    setActiveProductType(product) {
      this.activeProductType = product;
    },
    toggleShowMenu() {
      this.showMenu = !store.showMenu;
    },
    setCustomerSearch(term) {
      this.customerSearch = term;
    },
  }));

  useEffect(() => {
    if (store.customerSearch !== null) {
      store.setCustomerSearch(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!globalStore.activeUser) {
      get("/users/me").then((res) => {
        setActiveUser(res.data);
      });
    }
  }, [location.key]);

  return (
    <div className="bg-default-bg min-w-screen min-h-screen flex flex-col md:flex-row">
      <Sidebar />
      <div className="flex flex-1 flex-grow flex-col bg-white rounded-3xl p-4 pt-8 md:p-8 md:my-5 lg:p-16 mx-4 mb-4 lg:ml-0 lg:mr-8 lg:my-8 overflow-hidden">
        <Routes>
          <Route
            path="/"
            element={
              <Page title="Översikt">
                <Home />
              </Page>
            }
          />
          <Route path="/offerter/:orderId" element={<SingleOrder />} />
          <Route
            path="/offerter"
            element={
              <Observer>
                {() => (
                  <Page
                    header={
                      <OrdersHeader
                        onSubmit={(val) => {
                          store.search = val;
                        }}
                      />
                    }
                    title="Offerter & ordrar"
                  >
                    <Orders
                      search={store.search}
                      resetSearch={() => (store.search = "")}
                    />
                  </Page>
                )}
              </Observer>
            }
          />
          <Route
            path="/produkter/:type/:productId/edit"
            element={<EditProduct />}
          />
          <Route path="/produkter" element={<ProductsPage store={store} />} />
          <Route
            path="/produkter/:productType"
            element={<ProductsPage store={store} />}
          />
          <Route
            path="/add-product"
            element={
              <Page header={<AddProductHeader />} title="Lägg till produkt">
                <AddProduct />
              </Page>
            }
          />
          <Route
            path="/settings/account"
            element={
              <Page
                title="Inställningar"
                header={<EditHeader cross onClick={() => navigate(-1)} />}
              >
                <Account />
              </Page>
            }
          />
          <Route
            path="/settings"
            element={
              <Page title="Inställningar">
                <Settings />
              </Page>
            }
          />
          <Route
            path="/customers/:type/:customerId/edit"
            element={
              <Page
                title="Redigera kund"
                header={<EditHeader onClick={() => navigate(-1)} />}
              >
                <EditCustomer />
              </Page>
            }
          />
          <Route
            path="/customers/:type/:customerId/*"
            element={<SingleCustomer />}
          />
          <Route
            path="/customers"
            element={
              <Observer>
                {() => (
                  <Page
                    title="Kunder"
                    header={
                      <CustomerHeader setSearchTerm={store.setCustomerSearch} />
                    }
                  >
                    <Customers searchTerm={store.customerSearch} />
                  </Page>
                )}
              </Observer>
            }
          />
          <Route
            path="/add-customer"
            element={
              <Page title="Ny kund" header={<AddCustomerHeader />}>
                <AddCustomer />
              </Page>
            }
          />
          <Route
            path="/edit-customer/:customerId"
            element={
              <Page title="Redigera kund" header={<AddCustomerHeader />}>
                <AddCustomer edit />
              </Page>
            }
          />
          <Route
            path="/add-user"
            element={
              <Page backLink="/settings" title="Lägg till användare">
                <AddUser />
              </Page>
            }
          />
          <Route
            path="/edit-user/:userId"
            element={
              <Page backLink="/settings" title="Redigera användare">
                <EditUser />
              </Page>
            }
          />
          <Route
            path="/add-campaign"
            element={
              <Page backLink="/settings" title="Lägg till kampanj">
                <AddCampaign />
              </Page>
            }
          />
          <Route path="/edit-campaign" element={
            <Page backLink="/settings" title="Redigera kampanj">
                <EditCampaign />
            </Page>
          }/>
        </Routes>
      </div>
    </div>
  );
});

export default Admin;
