import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Select, Circle } from "../../../components";
import { userRoles } from "../../../constants";
import { Save } from "../../../icons";
import { get, put } from "../../../api";
import { validateEmail, validatePhone } from "../../../utils";

const EditUser = observer(() => {
  const navigate = useNavigate();
  const store = useLocalObservable(() => ({
    user: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: "",
    },
    sending: false,
    error: null,
    errors: {},
    handleSubmit(e) {
      this.error = null;
      this.errors = {};
      e.preventDefault();
      if (this.sending) {
        return;
      }
      this.sending = true;

      const { user } = this;

      if (!user.first_name || user.first_name.length < 2) {
        store.errors.first_name = "För kort namn";
      }
      if (!user.last_name || user.last_name.length < 2) {
        store.errors.last_name = "För kort efternamn";
      }
      if (!user.email || !validateEmail(user.email)) {
        store.errors.email = "Ogiltigt email";
      }
      if (
        !user.phone ||
        user.phone.length < 3 ||
        !validatePhone(user.phone.length)
      ) {
        store.errors.phone = "Ogiltigt telefonnummer";
      }
      if (!user.role) {
        store.errors.role = "Du måste välja en roll till användaren";
      }

      if (Object.keys(this.errors).length === 0) {
        put(`/users/${userId}`, user)
          .then(() => {
            this.sending = false;
            navigate(-1);
          })
          .catch((err) => {
            this.sending = false;
            store.error = err.response.data.error;
          });
      } else {
        this.sending = false;
      }
    },
  }));
  const params = useParams();

  const { userId } = params;

  useEffect(() => {
    get(`/users/${userId}`)
      .then((res) => {
        store.user = res.data;
      })
      .catch(() => {
        store.error = "Något gick fel. Ladda om och försök igen";
      });
  }, []);
  return (
    <form onSubmit={store.handleSubmit} className="flex flex-col">
      <div className="flex flex-1 flex-grow box p-4 md:py-7">
        <div className="flex flex-wrap xl:max-w-2xl">
          <Input
            error={store.errors.first_name}
            value={store.user.first_name}
            onChange={(e) => (store.user.first_name = e.target.value)}
            fieldname="Förnamn"
            name="first_name"
            type="text"
          />
          <Input
            error={store.errors.last_name}
            value={store.user.last_name}
            onChange={(e) => (store.user.last_name = e.target.value)}
            fieldname="Efternamn"
            name="last_name"
            type="text"
          />
          <Input
            error={store.errors.email}
            value={store.user.email}
            onChange={(e) => (store.user.email = e.target.value)}
            fieldname="E-post"
            name="email"
            type="email"
          />
          <Input
            error={store.errors.phone}
            value={store.user.phone}
            onChange={(e) => (store.user.phone = e.target.value)}
            fieldname="Telefon"
            name="phone"
            type="tel"
          />
          <div className="flex flex-col w-full md:w-1/2 md:px-3 md:max-w-md">
            <p className="title font-medium mb-2">Roll</p>
            {store.user.role && (
              <Select
                error={store.errors.role}
                items={userRoles}
                onChange={(value) => {
                  store.user.role = value.value;
                }}
                fieldName="Roll"
                initialItem={store.user.role}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center mt-6">
        <Circle
          actionText={store.sending ? "Sparar..." : "Spara ändringar"}
          value="submit"
          type="button"
        >
          <Save />
        </Circle>
        <p className="text-orange ml-6 ">{store.error}</p>
      </div>
    </form>
  );
});

export default EditUser;
