import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { Circle, Select } from "../../../components";
import { Cross, Loading, MinusCircle, Save } from "../../../icons";
import { put, get, postMedia, deleteRequest } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { store as globalStore } from "../../../store";
import { setProductGroups } from "../../../utils";
import {
  AddArea,
  AddCatProduct,
  AddProfile,
  AddConfiguratorProduct,
} from "../components";
import { ConfiguratorCategories, productTypes } from "../../../constants";

const PRODUCT_TYPE_AREA = "area_color";
const PRODUCT_TYPE_PROFILE = "profile_color";
const PRODUCT_TYPE_CONFIGURATOR = "configurator";

const EditProduct = observer(() => {
  const params = useParams();
  const navigate = useNavigate();
  const { productId, type } = params;

  const store = useLocalObservable(() => ({
    color: "#fff",
    product: {
      name: "",
      price: 0,
    },
    showRemoveDialog: false,
    removingProduct: false,
    productConfigurations: [
      {
        price: 0,
        meta: {
          dimensions: "",
          article_number: "",
        },
      },
    ],
    errors: {},
    file: null,
    files: [],
    setFiles(e, id) {
      const uploadedFile = e.target.files[0];
      if (uploadedFile) {
        const index = store.files.findIndex((file) => file.id === id);
        if (index > 0) {
          store.files.splice(index, 1, uploadedFile);
          return;
        }

        store.files.push({
          id,
          file: uploadedFile,
        });
      }
    },
    removeFile(id) {
      const index = store.files.findIndex((file) => file.id === id);
      store.files.splice(index, 1);
    },
    productType: null,
    priceGroups: [],
    selectedPriceGroup: null,
    productCategories: [],
    loading: true,
    resetErrors() {
      this.errors = {};
    },
    handleUpload(e) {
      const uploadedFile = e.target.files[0];
      if (uploadedFile) {
        this.file = uploadedFile;
      }
    },
    async saveAreaColor() {
      this.resetErrors();

      const name = store.product.name;
      const price_group_id = store.selectedPriceGroup.id;
      const group_id = store.productType.id;

      if (!name || name.length < 2) {
        this.errors.name = "Ogiltigt namn";
      }
      if (isNaN(price_group_id)) {
        this.errors.price_group = "Ogiltig prisgrupp";
      }
      if (!group_id) {
        this.errors.area_type = "Ogiltig typ";
      }

      const area = {
        name,
        price_group_id,
        group_id,
        image: this.file
          ? await postMedia(this.file)
              .then((res) => res.data.Path)
              .catch(() => {
                store.errors.file = "Något gick fel";
              })
          : store.product.image,
      };

      if (Object.keys(this.errors).length === 0) {
        put(`/products/${store.product.id}`, area)
          .then(() => {
            navigate(-1);
          })
          .catch(() => {
            store.error.general = "Något gick fel";
          });
      }
    },
    async saveProfileColor() {
      this.resetErrors();

      const name = this.product.name;
      const color = this.color;
      const group_id = store.productType.id;
      const price = store.product.price;

      if (!name || name.length < 2) {
        this.errors.name = "Ogiltigt namn";
      }
      if (!color || color.length < 3) {
        this.errors.color = "Ogiltig färg";
      }
      if (!group_id) {
        this.errors.group_id = "Välj en typ";
      }
      if (price < 0) {
        this.errors.price = "Ogiltigt pris";
      }

      const profile = {
        name,
        color,
        group_id,
        price,
        image: this.file
          ? await postMedia(this.file)
              .then((res) => res.data.Path)
              .catch(() => {
                store.errors.file = "Något gick fel";
              })
          : store.product.image,
      };

      if (Object.keys(this.errors).length === 0) {
        put(`/products/${store.product.id}`, profile)
          .then(() => {
            navigate(-1);
          })
          .catch(() => {
            store.error = "Något gick fel";
          });
      }
    },
    saveCatProduct() {
      const name = this.product.name;
      if (!name || name.length < 2) {
        this.errors.name = "Ogiltigt namn";
      }

      const product = {
        name,
        configurations: this.productConfigurations,
        group_id: store.product.group_id,
      };

      if (!this.errors.name) {
        put("/products/" + this.product.id, product)
          .then(() => {
            navigate(-1);
          })
          .catch((err) => (this.errors.general = err.response));
      }
    },
    async saveConfiguratorProduct() {
      const { name, number_of_holes, hole_offset, color_group } = store.product;

      if (!name || name.length < 2) {
        store.errors.name = "Ogiltigt namn";
      }

      if (store.product.category === ConfiguratorCategories.InteriorSection) {
        if (!number_of_holes) {
          store.errors.number_of_holes = "Ange antal hål";
        }
        if (hole_offset < 0) {
          store.errors.hole_offset = "Ange hål offset";
        }
        if (!color_group) {
          store.errors.color_group = "Ange färggrupp";
        }
      }

      if (Object.keys(store.errors).length !== 0) {
        return;
      }

      for (const config of store.productConfigurations) {
        const newImage = store.files.find((file) => file.id === config.id);
        if (newImage) {
          try {
            const { data } = await postMedia(newImage.file);
            config.image = data.Path;
          } catch (error) {
            store.errors.file = "Något gick fel";
          }
        }
      }

      // Upload product image
      const newImage = store.files.find((file) => file.id === store.product.id);
      let productImage = null;
      if (newImage) {
        try {
          const { data } = await postMedia(newImage.file);
          productImage = data.Path;
        } catch (error) {
          store.errors.file = "Något gick fel";
        }
      }

      const product = {
        name,
        number_of_holes: store.product.number_of_holes,
        hole_offset: store.product.hole_offset,
        color_group: store.product.color_group,
        configurations: store.productConfigurations,
        group_id: store.product.group_id,
        image: productImage,
      };

      try {
        await put(`/products/${store.product.id}`, product);
        navigate(-1);
      } catch (error) {
        this.errors.general = error.response;
      }
    },
    handleColorChange(e) {
      store.color = e.target.value;
    },
    setProduct(data) {
      store.product = data;
      store.color = data.color ? data.color : null;
    },
    removeItem() {
      deleteRequest("/products/" + this.product.id)
        .then(() => {
          this.showRemoveDialog = false;
          this.removingProduct = false;
          navigate(-1);
        })
        .catch(() => {
          this.errors.remove = "Något gick fel. Försök gärna igen";
          this.removingProduct = false;
        });
    },
    saveProduct() {
      switch (type) {
        case "area_color":
          this.saveAreaColor();
          break;
        case "profile_color":
          this.saveProfileColor();
          break;
        case "configurator":
          this.saveConfiguratorProduct();
          break;
        default:
          this.saveCatProduct();
          break;
      }
    },
  }));

  const inputRef = useRef();

  useEffect(() => {
    async function fetchData() {
      try {
        if (
          !globalStore.productCategories ||
          globalStore.productCategories.length < 1
        ) {
          const res = await get("/product_groups");
          setProductGroups(res.data.results);
          store.productCategories = res.data.results.filter(
            (cat) => cat.category === type
          );
        } else {
          store.productCategories = globalStore.productCategories.filter(
            (cat) => cat.category === type
          );
        }
        const response = await get(`/products/${productId}`);
        store.setProduct(response.data);
        if (type === PRODUCT_TYPE_AREA) {
          store.productType = globalStore.productCategories.find(
            (cat) => cat.id === response.data.group_id
          );
          const res = await get("/price_groups");
          res.data.sort((a, b) => {
            return parseInt(a.name, 10) - parseInt(b.name, 10);
          });
          store.priceGroups = res.data;
          store.selectedPriceGroup = res.data.find(
            (priceGroup) => priceGroup.id === response.data.price_group_id
          );
        } else if (type === PRODUCT_TYPE_PROFILE) {
          store.productType = globalStore.productCategories.find(
            (cat) => cat.id === response.data.group_id
          );
        } else {
          store.productConfigurations = response.data.configurations;
        }
      } catch (error) {
        store.error = "Något gick fel. Ladda om och försök igen";
      } finally {
        store.loading = false;
      }
    }
    fetchData();
  }, []);

  const isCatProduct = (type) => {
    return [
      "special",
      "lighting",
      "stand_and_wallmounted",
      "interior",
      "base",
    ].includes(type);
  };

  if (store.loading) {
    return <p>Hämntar produktdata.</p>;
  }

  return (
    <>
      <div className="flex flex-wrap justify-between items-center">
        <h1>Redigera Produkt</h1>
        <div className="mt-4 sm:mt-0 flex items-center space-x-4 xl:space-x-6">
          <Circle type="button" onClick={() => store.saveProduct()}>
            <Save />
          </Circle>
          <Circle type="button" onClick={() => (store.showRemoveDialog = true)}>
            <MinusCircle />
          </Circle>
          <Circle type="link" to="/produkter">
            <Cross />
          </Circle>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row mt-8">
        <Select
          className="sm:w-1/2 sm:pr-2 xl:w-1/3 z-20"
          label="Typ"
          items={productTypes}
          initialItem={type}
          disabled
        />
        {type !== PRODUCT_TYPE_AREA &&
          type !== PRODUCT_TYPE_PROFILE &&
          type !== PRODUCT_TYPE_CONFIGURATOR && (
            <Select
              className="mt-4 sm:mt-0 sm:w-1/2 sm:pl-2 xl:w-1/3 z-20"
              label="Kategori"
              initialItem={store.product.group_id}
              type="product_category"
              items={store.productCategories}
              onChange={(value) => {
                store.product.group_id = value.id;
              }}
            />
          )}
      </div>
      <div className="flex flex-col">
        {type === PRODUCT_TYPE_AREA && (
          <AddArea
            activeProduct={type}
            store={store}
            image={store.product.image}
            edit
          />
        )}
        {type === PRODUCT_TYPE_PROFILE && (
          <AddProfile
            store={store}
            image={store.product.image}
            inputRef={inputRef}
            activeProduct={type}
            edit
          />
        )}
        {isCatProduct(type) && (
          <AddCatProduct store={store} activeCategory={type} edit />
        )}
        {type === PRODUCT_TYPE_CONFIGURATOR && (
          <AddConfiguratorProduct
            store={store}
            isInteriorProduct={
              store.product.category === ConfiguratorCategories.InteriorSection
            }
            activeCategory={type}
            edit
          />
        )}
        {store.error && <p className="text-orange text-xl">{store.error}</p>}
        {store.showRemoveDialog && store.product && store.product.id && (
          <>
            <div className="absolute w-screen h-screen bg-black opacity-25 inset-0 z-20">
              &nbsp;
            </div>
            <div className="modal flex flex-col p-6 z-30">
              {store.removingProduct ? (
                <Loading />
              ) : (
                <>
                  <p>Är du säker på att du vill ta bort vald produkt?</p>
                  <div className="flex mt-6">
                    <button
                      className="border border-grey rounded-2xl px-6 py-2 mx-2 hover:border-blue-light hover:text-blue"
                      onClick={() => {
                        store.removingProduct = true;
                        store.removeItem();
                      }}
                    >
                      Ja
                    </button>
                    <button
                      className="border border-grey rounded-2xl px-6 py-2 mx-2 hover:border-blue-light hover:text-blue"
                      onClick={() => (store.showRemoveDialog = false)}
                    >
                      Avbryt
                    </button>
                  </div>
                  {store.errors && store.errors.remove && (
                    <p className="text-red-500 mt-6">{store.errors.remove}</p>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
});

export default EditProduct;
