import React from "react";
import PropTypes from "prop-types";
import { hexToHSL } from "../../../utils";

const Bars = ({ profileType, profileColor }) => {
  return (
    <div
      className="transform translate-y-1/2 absolute right-0 bottom-0 left-0 z-20 h-1 lg:h-2"
      style={
        profileColor
          ? {
              borderTop:
                profileType === 2
                  ? `solid 2px ${hexToHSL(profileColor, -10)}`
                  : null,
              borderBottom:
                profileType === 2
                  ? `solid 1px ${hexToHSL(profileColor, 6)}`
                  : null,
              background:
                profileType === 1
                  ? `transparent linear-gradient(180deg, ${hexToHSL(
                      profileColor,
                      6
                    )} 0%, ${profileColor} 93%, ${hexToHSL(
                      profileColor,
                      15
                    )} 100%) 0% 0% no-repeat padding-box`
                  : profileColor,
              boxShadow:
                profileType === 1 ? "0px 2px 2px rgba(0, 0, 0, 0.263)" : null,
            }
          : null
      }
    ></div>
  );
};

Bars.propTypes = {
  profileType: PropTypes.number,
  profileColor: PropTypes.string,
};

export default Bars;
