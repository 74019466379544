import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { Select } from "../../../components";
import {
  ConfiguratorCategories,
  productTypes,
  PRODUCT_TYPE_AREA,
  PRODUCT_TYPE_BASE,
  PRODUCT_TYPE_CONFIGURATOR,
  PRODUCT_TYPE_PROFILE,
} from "../../../constants";
import { post, get, postMedia } from "../../../api";
import { useNavigate } from "react-router-dom";
import { store as globalStore } from "../../../store";
import { setProductGroups } from "../../../utils";
import {
  AddArea,
  AddProfile,
  AddCatProduct,
  AddConfiguratorProduct,
} from "../components";

const AddProduct = observer(() => {
  const navigate = useNavigate();
  const store = useLocalObservable(() => ({
    activeProduct: null,
    activeCategory: null,
    file: null,
    filePath: null,
    files: [],
    setFiles(e, id) {
      const uploadedFile = e.target.files[0];
      if (uploadedFile) {
        const index = store.files.findIndex((file) => file.id === id);
        if (index > 0) {
          store.files.splice(index, 1, uploadedFile);
          return;
        }

        store.files.push({
          id,
          file: uploadedFile,
        });
      }
    },
    removeFile(id) {
      const index = store.files.findIndex((file) => file.id === id);
      store.files.splice(index, 1);
    },
    color: "#fff",
    productType: null,
    priceGroups: [],
    selectedPriceGroup: null,
    errors: {},
    productCategories: [],
    get filteredProductCategories() {
      if (store.activeProduct === PRODUCT_TYPE_CONFIGURATOR) {
        return store.productCategories.filter(
          (cat) =>
            cat.category === ConfiguratorCategories.InteriorSection ||
            cat.category === ConfiguratorCategories.InteriorAccessories ||
            cat.category === ConfiguratorCategories.OtherAccessories
        );
      }
      return store.productCategories.filter(
        (cat) => cat.category === store.activeProduct
      );
    },
    get showCategoriesSelect() {
      return (
        hasCategories(store.activeProduct) ||
        store.activeProduct === PRODUCT_TYPE_CONFIGURATOR
      );
    },
    product: { name: "", price: 0 },
    productConfigurations: [
      {
        price: 0,
        meta: {
          dimensions: "",
          article_number: "",
        },
      },
    ],
    setActiveProduct(productType) {
      store.activeProduct = productType;
    },
    resetErrors() {
      this.errors = {};
    },
    handleAreaSubmit(e) {
      e.preventDefault();
      const name = e.target.name.value;
      const price_group_id = store.selectedPriceGroup.id;
      const group_id = store.productType ? store.productType.id : null;

      this.resetErrors();

      if (!name || name.length < 2) {
        this.errors.name = "Ogiltigt namn";
      }
      if (!price_group_id) {
        this.errors.price = "Ogiltigt pris";
      }
      if (!group_id) {
        this.errors.group_id = "Välj en typ";
      }

      if (!this.file) {
        this.errors.file = "Du måste ladda upp en bild";
      }

      if (Object.keys(this.errors).length === 0) {
        postMedia(this.file).then((res) => {
          const image = res.data.Path;
          const area = {
            name,
            price_group_id,
            image,
            group_id,
          };
          post("/products", area).then(() => {
            navigate("/produkter/area_color");
          });
        });
      }
    },
    handleCatProduct(e) {
      e.preventDefault();
      const name = e.target.name.value;
      if (!name || name.length < 2) {
        this.errors.name = "Ogiltigt namn";
      }

      const product = {
        name,
        configurations: this.productConfigurations,
        group_id: this.activeCategory.id,
      };

      if (!this.errors.name) {
        post("/products", product)
          .then(() => {
            navigate("/produkter/" + this.activeProduct);
          })
          .catch((err) => {
            this.errors.general = err.response.data.error;
          });
      }
    },
    handleProfileSubmit(e) {
      e.preventDefault();
      this.resetErrors();

      const name = e.target.name.value;
      const price = parseInt(e.target.price.value, 10) * 100;
      const color = this.color;
      const group_id = store.productType ? store.productType.id : null;

      if (!name || name.length < 2) {
        this.errors.name = "Ogiltigt namn";
      }
      if (!color || color.length < 3) {
        this.errors.color = "Ogiltig färg";
      }
      if (!group_id) {
        this.errors.group_id = "Välj en typ";
      }
      if (!this.file) {
        this.errors.file = "Du måste ladda upp en bild";
      }

      if (Object.keys(this.errors).length === 0) {
        postMedia(this.file)
          .then((res) => {
            const image = res.data.Path;

            const profile = {
              name,
              price,
              color,
              group_id,
              image,
            };

            post("/products", profile).then(() => {
              navigate("/produkter/profile_color");
            });
          })
          .catch(() => {
            this.errors.general = "Något gick fel";
          });
      }
    },
    handleColorChange(e) {
      store.color = e.target.value;
    },
    handleUpload(e) {
      const uploadedFile = e.target.files[0];
      if (uploadedFile) {
        this.file = uploadedFile;
        this.filePath = URL.createObjectURL(uploadedFile);
      }
    },
    async handleConfiguratorSubmit(e) {
      e.preventDefault();
      store.resetErrors();

      const { name, number_of_holes, hole_offset, color_group } = store.product;

      if (!name || name.length < 2) {
        store.errors.name = "Ogiltigt namn";
      }

      if (
        store.activeCategory.category === ConfiguratorCategories.InteriorSection
      ) {
        if (!number_of_holes) {
          store.errors.number_of_holes = "Ange antal hål";
        }
        if (hole_offset < 0) {
          store.errors.hole_offset = "Ange hål offset";
        }
        if (!color_group) {
          store.errors.color_group = "Ange färggrupp";
        }
      }

      if (Object.keys(store.errors).length !== 0) {
        return;
      }

      for (const [index, config] of store.productConfigurations.entries()) {
        const newImage = store.files.find((file) => file.id === index);
        if (newImage) {
          try {
            const { data } = await postMedia(newImage.file);
            config.image = data.Path;
          } catch (error) {
            store.errors.file = "Något gick fel";
          }
        }
      }

      // Upload product image
      const newImage = store.files.find((file) => file.id === store.product.id);
      let productImage = null;
      if (newImage) {
        try {
          const { data } = await postMedia(newImage.file);
          productImage = data.Path;
        } catch (error) {
          store.errors.file = "Något gick fel";
        }
      }

      const product = {
        name,
        number_of_holes: store.product.number_of_holes,
        hole_offset: store.product.hole_offset,
        color_group: store.product.color_group,
        configurations: store.productConfigurations,
        group_id: store.activeCategory.id,
        image: productImage,
      };

      try {
        await post("/products", product);
        navigate("/produkter/" + store.activeProduct);
      } catch (error) {
        store.errors.general = error.response;
      }
    },
  }));

  const inputRef = useRef();
  const hasCategories = (type) => {
    return [
      "special",
      "lighting",
      "stand_and_wallmounted",
      "interior",
    ].includes(type);
  };

  useEffect(() => {
    if (
      !globalStore.productCategories ||
      globalStore.productCategories.length < 1
    ) {
      get("/product_groups").then((res) => {
        setProductGroups(res.data.results);
        store.productCategories = res.data.results;
      });
    } else {
      store.productCategories = globalStore.productCategories;
    }
    get("/price_groups").then((res) => {
      res.data.sort((a, b) => {
        return parseInt(a.name, 10) - parseInt(b.name, 10);
      });
      store.priceGroups = res.data;
    });
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col sm:flex-row">
        <Select
          className="sm:w-1/2 sm:pr-2 xl:w-1/3 z-30"
          label="Typ"
          items={productTypes.filter(
            (item) => item.value !== PRODUCT_TYPE_BASE
          )}
          onChange={(item) => {
            store.productConfigurations = [
              {
                price: 0,
                meta: {
                  dimensions: "",
                  article_number: "",
                },
              },
            ];
            store.activeCategory = null;
            store.errors = {};
            store.setActiveProduct(item.value);
          }}
        />
        {store.activeProduct && store.showCategoriesSelect && (
          <Select
            className="mt-4 sm:mt-0 sm:w-1/2 sm:pl-2 xl:w-1/3 z-20"
            label="Kategori"
            value={store.activeCategory}
            type="product_category"
            items={store.filteredProductCategories}
            onChange={(value) => {
              store.activeCategory = value;
            }}
          />
        )}
      </div>
      {store.activeProduct && store.activeProduct === PRODUCT_TYPE_AREA && (
        <AddArea
          activeProduct={store.activeProduct}
          store={store}
          inputRef={inputRef}
        />
      )}
      {store.activeProduct && store.activeProduct === PRODUCT_TYPE_PROFILE && (
        <AddProfile
          store={store}
          inputRef={inputRef}
          activeProduct={store.activeProduct}
        />
      )}
      {store.activeProduct &&
        hasCategories(store.activeProduct) &&
        store.activeCategory && (
          <AddCatProduct
            store={store}
            activeCategory={store.activeCategory.category}
          />
        )}
      {store.activeCategory &&
        store.activeProduct === PRODUCT_TYPE_CONFIGURATOR && (
          <AddConfiguratorProduct
            store={store}
            isInteriorProduct={
              store.activeCategory.category ===
              ConfiguratorCategories.InteriorSection
            }
            activeCategory={store.activeCategory.category}
          />
        )}
    </div>
  );
});

export default AddProduct;
