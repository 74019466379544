import axios from "axios";
import { store } from "../store";
import { logout, setTokens } from "../utils";
import { API_URL } from "../constants";

async function request(method, path, data, headers) {
  try {
    return await axios({
      method,
      url: API_URL + path,
      data,
      headers: {
        Authorization: `Bearer ${store.token}`,
        ...headers,
      },
    });
  } catch (err) {
    if (err.response.status !== 401) {
      throw err;
    }

    try {
      await refreshToken();
      return await request(method, path, data);
    } catch (e) {
      logout();
    }
  }
}

export const get = (path, data) => request("get", path, data);
export const post = (path, data) => request("post", path, data);
export const postMedia = (data) => {
  var formData = new FormData();
  formData.append("file", data);
  return request("post", "/upload", formData, {
    "Content-type": "multipart/form-data",
  });
};
export const put = (path, data) => request("put", path, data);
export const deleteRequest = (path, data) => request("delete", path, data);

export async function login(email, password) {
  const response = await axios.post(API_URL + "/token", {
    grant: {
      email,
      password,
    },
    grant_type: "password",
  });

  setTokens(response.data);
}

export async function refreshToken() {
  const { refreshToken } = store;

  const response = await axios.post(API_URL + "/token", {
    grant_type: "refresh",
    grant: {
      refresh_token: refreshToken,
    },
  });

  setTokens(response.data);
}
