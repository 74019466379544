import { useLocalObservable, observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { DownArrow, Eye, Information, Save } from "../../icons";
import PropTypes from "prop-types";

const ToggleBox = observer(
  ({
    closed,
    title = "",
    children,
    link,
    linkTitle,
    updateOrder,
    saving,
    className = "",
    toggle = true,
    showModel,
    wardrobeModelToggle,
    error,
    ...props
  }) => {
    const store = useLocalObservable(() => ({
      isOpen: !closed,
      toggleOpen() {
        store.isOpen = !store.isOpen;
      },
    }));
    return (
      <div
        className={`flex flex-col box ${className} ${
          !store.isOpen || !children ? "closed" : ""
        }${error ? " box-error" : ""}`}
        {...props}
      >
        <div
          onClick={() => (toggle ? store.toggleOpen() : null)}
          className={`box-header ${!store.isOpen || !children ? "closed" : ""}`}
        >
          <p>{title}</p>
          {link && linkTitle && (
            <Link className="text-grey-dark ml-6 text-xs font-light" to={link}>
              {linkTitle}
            </Link>
          )}
          <span className="flex ml-auto">
            {wardrobeModelToggle && (
              <div
                className="w-full flex items-center text-grey-dark border-l border-r border-grey px-6 py-4 -my-4 mr-6"
                onClick={(e) => {
                  e.stopPropagation();
                  wardrobeModelToggle();
                }}
              >
                {showModel ? (
                  <>
                    <Information className="hidden md:inline-block h-5 w-5 mr-2" />
                    <span>Visa detaljer</span>
                  </>
                ) : (
                  <>
                    <Eye className="hidden md:inline-block h-5 w-5 mr-2" />
                    <span>Visa modell</span>
                  </>
                )}
              </div>
            )}
            {!toggle && updateOrder && (
              <button
                className="round-button button"
                onClick={updateOrder}
                disabled={saving}
              >
                <Save />
              </button>
            )}
            {toggle && (
              <DownArrow
                className={`w-4 transform ${store.isOpen ? "rotate-180" : ""}`}
              />
            )}
          </span>
          {error && <p className="w-full text-orange text-xs mt-2">{error}</p>}
        </div>
        {store.isOpen && children}
      </div>
    );
  }
);

ToggleBox.propTypes = {
  closed: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  className: PropTypes.string,
  toggle: PropTypes.bool,
  showModel: PropTypes.bool,
  wardrobeModelToggle: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  error: PropTypes.string,
};

export default ToggleBox;
