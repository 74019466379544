import React from "react";
import { observer } from "mobx-react";
import { PartitionWidth } from "../../../constants";
import Measurement from "./Measurement";

const WardrobePartition = observer(
  ({ section, depth, wardrobeWidth, color, showMeasurement }) => {
    return (
      <div
        className="absolute h-full preserve-3d"
        style={{
          left: `${(section.position_x / wardrobeWidth) * 100}%`,
          width: `${(PartitionWidth / wardrobeWidth) * 100}%`,
          background: color,
        }}
      >
        {section.position_x !== 0 &&
          section.position_x + section.width !== wardrobeWidth && (
            <div
              className="absolute h-full"
              style={{
                width: `calc(50px + ${depth}px * 2)`,
                // Half the width of the parent element minus
                // half the width of the element itself
                right: `calc(50% - 25px - ${depth}px)`,
                transform: `rotateY(-90deg) translateX(-45px)`,
                borderLeft: "1px dashed #1D1211",
                borderTop: "1px dashed #1D1211",
                borderBottom: "1px dashed #1D1211",
              }}
            />
          )}
        {showMeasurement && (
          <Measurement size={PartitionWidth} type="partition" />
        )}
      </div>
    );
  }
);

export default WardrobePartition;
