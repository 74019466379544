import React from "react";
import { login } from "../../api";

import { useLocalObservable, observer } from "mobx-react";
import { ArrowForward, Loading } from "../../icons";
import { Circle } from "../../components";
import gardeLogo from "./assets/logo-blue-garderobsfabriken.png";

const Login = observer(() => {
  const store = useLocalObservable(() => ({
    errors: {},
    loading: false,
    error: null,
    handleSubmit(event) {
      event.preventDefault();
      store.error = null;
      store.loading = false;

      const email = event.target.username.value;
      const password = event.target.password.value;

      if (!email || email.length < 3) {
        store.errors.username = "Ogiltigt användarnamn";
      } else {
        delete store.errors.username;
      }
      if (!password || password.length < 3) {
        store.errors.password = "Ogiltigt lösenord";
      } else {
        delete store.errors.password;
      }

      if (Object.keys(store.errors).length === 0) {
        store.loading = true;
        login(email, password)
          .then(() => {
            store.loading = false;
          })
          .catch((err) => {
            store.loading = false;
            store.error = err.message;
          });
      }
    },
  }));

  return (
    <div className="bg-default-bg flex w-screen h-screen">
      <div className="flex flex-col-reverse lg:flex-col justify-center items-center mx-auto mt-16 max-w-5xl flex-1 flex-grow">
        <div className="flex w-full items-center flex-col-reverse lg:flex-row lg:flex-grow">
          <div className="flex lg:flex-1 lg:w-1/2 items-center justify-center mt-14 lg:mt-0">
            <img src={gardeLogo} className="h-10 lg:h-16 object-contain" />
          </div>
          <form
            className="flex lg:flex-1 w-full lg:w-1/2 justify-center items-center"
            onSubmit={store.handleSubmit}
          >
            <div className="bg-white rounded-3xl shadow-md flex flex-col items-center lg:max-w-full max-w-sm lg:w-auto w-full mx-8 pt-8 lg:pt-20 pb-8 lg:pb-16 px-8 lg:px-16">
              <h4 className="text-2xl normal-case font-semibold">Logga in</h4>
              {store.error && (
                <div className="mt-7 error-msg">{store.error}</div>
              )}
              <div className="w-full mt-7 lg:w-80">
                <input
                  className={store.errors.username && "error"}
                  placeholder="Användarnamn"
                  name="username"
                  error={store.errors.username}
                />
                {store.errors.username && (
                  <div className="form-error">{store.errors.username}</div>
                )}
              </div>
              <div className="w-full mt-7 lg:w-80">
                <input
                  className={store.errors.password && "error"}
                  placeholder="Lösenord"
                  name="password"
                  type="password"
                  error={store.errors.password}
                />
                {store.errors.password && (
                  <div className="form-error">{store.errors.password}</div>
                )}
              </div>
              {!store.loading && (
                <Circle type="button" className="mt-7" value="submit">
                  <ArrowForward />
                </Circle>
              )}
              {store.loading && (
                <div className="mt-7">
                  <Loading />
                </div>
              )}
            </div>
          </form>
        </div>
        <p className="text-grey text-center w-full mb-7 lg:mb-14 font-medium text-base tracking-wider uppercase">
          Admin
        </p>
      </div>
    </div>
  );
});

export default Login;
