import React from "react";
import { observer } from "mobx-react-lite";
import { PartitionWidth, InteriorWardrobeMaxHeight } from "../../../constants";
import Measurement from "./Measurement";

const WardrobeTopshelf = observer(
  ({ depth, wardrobeHeight, color, showMeasurement }) => {
    const emptySpaceAbove = wardrobeHeight - InteriorWardrobeMaxHeight;

    return (
      <>
        {emptySpaceAbove !== 0 && (
          <div
            className="relative"
            style={{ height: `${(emptySpaceAbove / wardrobeHeight) * 100}%` }}
          >
            {showMeasurement && (
              <Measurement size={emptySpaceAbove} type="left" />
            )}
          </div>
        )}
        <div
          style={{
            height: `${(PartitionWidth / wardrobeHeight) * 100}%`,
            background: color,
          }}
        />
        <div className="relative preserve-3d">
          <div
            className="absolute w-full h-64"
            style={{
              height: `${50 + depth * 2}px`,
              // translateZ negative half the height of the element
              transform: `rotateX(-90deg) translateZ(-${
                25 + depth
              }px) translateY(45px)`,
              borderRight: "1px dashed #1D1211",
              borderBottom: "1px dashed #1D1211",
              borderLeft: "1px dashed #1D1211",
            }}
          />
        </div>
      </>
    );
  }
);

export default WardrobeTopshelf;
