import React, { useState } from "react";
import Downshift from "downshift";
import PropTypes from "prop-types";
import { DownArrow } from "../../icons";
import { observer } from "mobx-react";
import { API_URL, ColorNames } from "../../constants";

const getConfigTitle = (config, index) => {
  let dimensions = "";
  if (config.section_type) {
    dimensions += config.section_type;
  }
  if (config.width || config.depth || config.height) {
    dimensions += ` ${config.width || 0}x${config.depth || 0}x${
      config.hieght || 0
    } `;
  }

  const color = ColorNames[config.color] || config.color || "";

  const name = dimensions + color;
  if (name !== "") {
    return name;
  }

  if (config.meta.article_number) {
    return config.meta.article_number;
  } else {
    return `Typ ${index + 1}`;
  }
};

const Select = observer(
  ({
    onChange,
    items,
    initialItem,
    disabled,
    className = "",
    error,
    groups,
    type = "general",
    label,
  }) => {
    const [isActive, setIsActive] = useState(false);
    function getTitle(type, item, index = 0) {
      if (!item) {
        return "Välj";
      }
      switch (type) {
        case "user":
          return item.first_name + " " + item.last_name;
        case "product_category":
          return item.name;
        case "fields":
          return item.name;
        case "area_products":
          return item.name;
        case "price_groups":
          return item.name;
        case "configurations":
          return item.meta.dimensions
            ? item.meta.dimensions
            : item.meta.color
            ? item.meta.color
            : getConfigTitle(item, index);

        default:
          return item ? item.title : "";
      }
    }

    let groupName = "";
    return (
      <div
        className={`flex flex-col min-w-48 relative select-wrapper ${className}`}
      >
        {label && <p className="font-medium mb-2">{label}</p>}
        <Downshift
          onOuterClick={() => {
            setIsActive(false);
          }}
          isOpen={!disabled && isActive}
          onChange={(value) => {
            if (!value) {
              return;
            }
            setIsActive(false);
            onChange ? onChange(value) : null;
          }}
          initialSelectedItem={
            initialItem
              ? typeof initialItem === "string"
                ? items.find((item) => item.value === initialItem)
                : typeof initialItem === "number"
                ? items.find((i) => i.id === initialItem)
                : items.find((i) => i.id === initialItem.id)
              : null
          }
          itemToString={(item) => getTitle(type, item)}
        >
          {({
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            selectedItem,
          }) => (
            <div
              className={`select relative w-full z-0 ${
                isOpen ? "box open" : ""
              }`}
            >
              <label
                className={`relative flex justify-between z-0 ${
                  isOpen ? "-m-px" : ""
                } ${disabled ? "bg-grey-lighter disabled" : ""}`}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setIsActive(!isActive);
                }}
                {...getLabelProps()}
              >
                <span className="flex">
                  {type === "fields" && initialItem && (
                    <img
                      src={API_URL + initialItem.image}
                      className="mr-4 h-6"
                    />
                  )}
                  {selectedItem
                    ? getTitle(type, selectedItem)
                    : initialItem
                    ? getTitle(type, initialItem)
                    : "Välj"}
                </span>
                <DownArrow />
              </label>
              {isOpen && (
                <ul
                  className="z-30 select-list absolute -mt-4 max-h-64 overflow-y-auto"
                  {...getMenuProps()}
                >
                  {!groups &&
                    items.map((item, index) => (
                      <li
                        key={index}
                        className="list-item"
                        {...getItemProps({
                          index,
                          item,
                        })}
                      >
                        {type === "fields" && (
                          <img
                            src={API_URL + item.image}
                            className="mr-4 h-6"
                          />
                        )}
                        <p>{getTitle(type, item, index)}</p>
                      </li>
                    ))}
                  {groups &&
                    items.map((item, index) => {
                      const showHeader = item.group !== groupName;
                      groupName = item.group;
                      return (
                        <li
                          key={index}
                          className="list-item"
                          {...getItemProps({
                            index,
                            item,
                          })}
                        >
                          {showHeader && (
                            <p className="p-2 bg-grey-lighter">{groupName}</p>
                          )}
                          <p className="p-6">{getTitle(type, item, index)}</p>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          )}
        </Downshift>
        {error && <div className="form-error">{error}</div>}
      </div>
    );
  }
);

Select.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.array,
  initialItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
export default Select;
