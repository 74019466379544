import React from "react";
import { observer } from "mobx-react";
import {
  API_URL,
  HandleColors,
  HoleSpacing,
  InteriorWardrobeMaxHeight,
} from "../../../constants";

const DrawerHandle = ({ product, sectionType, handleColor }) => {
  const handleColorObject = HandleColors.find(
    (color) => color.value === handleColor
  );

  return (
    <div
      className="w-1/4 h-1 border-b border-b-gray-300 mx-auto"
      style={{
        backgroundColor: handleColorObject.hex,
        marginTop:
          product.product.name === "Smyckeslåda"
            ? sectionType === "XL"
              ? "2%"
              : "4%"
            : null,
      }}
    ></div>
  );
};

const WardrobeProduct = observer(
  ({ product, color, sectionType, handleColor }) => {
    return (
      <div
        className="absolute w-full group z-10 bg-no-repeat bg-center"
        style={{
          bottom: `${
            ((product.position_y * HoleSpacing) /
              (InteriorWardrobeMaxHeight - 2 * HoleSpacing)) *
            100
          }%`,
          height: `${
            ((product.product.number_of_holes * HoleSpacing) /
              (InteriorWardrobeMaxHeight - 2 * HoleSpacing)) *
            100
          }%`,
          background: product.product_configuration.image
            ? `url(${API_URL + product.product_configuration.image})`
            : color,
          backgroundSize: "100% 100%",
        }}
      >
        {(product.product.name === "Låda med handtag" ||
          product.product.name === "Smyckeslåda") && (
          <DrawerHandle
            product={product}
            sectionType={sectionType}
            handleColor={handleColor}
          />
        )}
      </div>
    );
  }
);

export default WardrobeProduct;
