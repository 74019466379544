import React from "react";
import { observer } from "mobx-react";
import { CheckMarkNoBorder } from "../../icons";
import PropTypes from "prop-types";

const PriceInput = observer(
  ({ top, name, auto, autoHandler, className, ...props }) => {
    return (
      <div
        className={`flex flex-col ${className} ${
          top ? "self-start" : "self-end"
        }`}
      >
        <label className="flex items-center mb-2" htmlFor={name}>
          <p className="text-grey-dark font-medium mr-4">Pris</p>
          <button
            type="button"
            id={name}
            className={`rounded-full w-4 h-4 p-px border flex items-center justify-center ${
              auto ? "bg-grey-dark border-grey-dark" : "border-grey"
            }`}
            onClick={autoHandler}
          >
            {auto && (
              <CheckMarkNoBorder className="fill-current w-4 text-white pointer-events-none" />
            )}
          </button>
          <p className="ml-2 text-xs font-medium">Automatisk</p>
        </label>
        <label>
          <div className="input-wrapper flex relative bg-grey-lighter rounded-2xl group">
            <input
              type="number"
              disabled={auto}
              className="text-grey-dark w-32 flex-1 border border-grey border-r-0 rounded-2xl rounded-r-none px-6 group-hover:border-blue-light group-hover:text-blue"
              {...props}
            />
            <div className="flex input-prepend bg-grey-lighter text-grey-dark rounded-2xl rounded-l-none border border-l-0 border-grey group-hover:bg-blue-light group-hover:text-blue group-hover:border-blue-light">
              <span className="flex items-center px-4 whitespace-nowrap ">
                SEK
              </span>
            </div>
          </div>
        </label>
      </div>
    );
  }
);

PriceInput.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

export default PriceInput;
