import {
  StyleSheet,
  Page,
  Text,
  View,
  Document,
  Image,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import robotoFont from "../../../assets/fonts/Roboto/Roboto-Regular.ttf";
import robotoFontMedium from "../../../assets/fonts/Roboto/Roboto-Medium.ttf";
import montserratFont from "../../../assets/fonts/Montserrat/Montserrat-Medium.ttf";
import montserratFontSemiBold from "../../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf";
import React from "react";
import gardeLogo from "../../login/assets/logo-blue-garderobsfabriken.png";
import factoryImage from "../../../assets/factory.png";
import hammerImage from "../../../assets/hammer.png";
import skovdeImage from "../../../assets/made-in-skovde.png";
import rotImage from "../../../assets/rot.png";
import tapeImage from "../../../assets/tape-measure.png";
import trayImage from "../../../assets/tray.png";
import {
  API_URL,
  SOFTCLOSE_ID,
  DAMPING_ID,
  assemblyItems,
  ColorNames,
} from "../../../constants";
import { getShippingText } from "../../../utils";
const doorTypes = {
  sliding: "Skjutdörrar",
  folding: "Vikdörrar",
  hinged: "Gånggärnsdörrar",
};
const placements = {
  wall_to_wall: "Vägg till vägg",
  center: "Centrerad",
  left: "Till vänster",
  right: "Till höger",
};
export const OfferPdf = ({ order, profileName, figures }) => {
  const styles = StyleSheet.create({
    doorPrice: {
      height: "25pt",
      display: "flex",
      alignItems: "center",
      fontWeight: 500,
      textAlign: "right",
      marginBottom: "5pt",
    },
  });

  const PriceArea = ({ order }) => {
    const assembly = assemblyItems.find((obj) => {
      return obj.value === order.assembly_option;
    });

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10pt",
          fontSize: "10pt",
        }}
      >
        <Row justifyContent="space-between" bottomSpacing>
          <Text>Summa</Text>
          <Text>
            {(order.price / 100).toLocaleString("sv-SE", {
              minimumFractionDigits: 2,
            })}{" "}
            SEK
          </Text>
        </Row>
        {order.discount !== 0 && (
          <Row justifyContent="space-between" bottomSpacing>
            <Text>Rabatt</Text>
            <Text>
              {(order.discount / 100).toLocaleString("sv-SE", {
                minimumFractionDigits: 2,
              })}{" "}
              SEK
            </Text>
          </Row>
        )}
        <Row justifyContent="space-between" bottomSpacing>
          <Text>Montering</Text>
          <Text>
            {assembly.title}{" "}
            {assembly.value === "fixed_price"
              ? `${(order.assembly_price / 100).toLocaleString("sv-SE", {
                  minimumFractionDigits: 2,
                })} SEK`
              : null}
          </Text>
        </Row>
        <Row justifyContent="space-between" bottomSpacing>
          <Text>Frakt</Text>
          <Text>
            {(order.shipping_price / 100).toLocaleString("sv-SE", {
              minimumFractionDigits: 2,
            })}{" "}
            SEK
          </Text>
        </Row>
        <Row
          justifyContent="space-between"
          bottomSpacing
          style={{ fontWeight: 500 }}
        >
          <Text>Totalsumma</Text>
          <Text>
            {(order.total_price / 100).toLocaleString("sv-SE", {
              minimumFractionDigits: 2,
            })}{" "}
            SEK
          </Text>
        </Row>
        <Row
          justifyContent="space-between"
          bottomSpacing
          style={{ fontWeight: 500 }}
        >
          <Text>Förskottsbetalning</Text>
          <Text>
            {(order.advance_payment / 100).toLocaleString("sv-SE", {
              minimumFractionDigits: 2,
            })}{" "}
            SEK
          </Text>
        </Row>
        <Row justifyContent="space-between" style={{ fontWeight: 500 }}>
          <Text>Slutbetalning</Text>
          <Text>
            {(
              (order.price -
                order.advance_payment -
                order.discount +
                order.assembly_price +
                order.shipping_price) /
              100
            ).toLocaleString("sv-SE", {
              minimumFractionDigits: 2,
            })}{" "}
            SEK
          </Text>
        </Row>
      </View>
    );
  };

  const Lighting = ({ order }) => (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View style={{ width: "100%", dispaly: "flex", flexDirection: "row" }}>
        <View
          style={{
            padding: "5pt 10pt",
            width: "87.5%",
            borderRight: "1pt solid #DDDDDD",
          }}
        >
          <InfoTitle title="Belysning" />
        </View>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            padding: "0 10pt 5pt",
            width: "87.5%",
            borderRight: "1pt solid #DDDDDD",
          }}
        >
          <Field
            label="Dörrbelysning"
            value={order.wardrobe.door_lighting ? "Ja" : "Nej"}
          />
        </View>
        {!!order.wardrobe.door_lighting && (
          <Text
            style={{
              width: "12.5%",
              display: "flex",
              alignSelf: "center",
              textAlign: "right",
              fontWeight: 500,
              padding: "5pt 10pt",
            }}
          >
            {(order.wardrobe.door_lighting_cost / 100).toLocaleString("sv-SE")}{" "}
            SEK
          </Text>
        )}
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            padding: "0 10pt 5pt",
            width: "87.5%",
            borderRight: "1pt solid #DDDDDD",
          }}
        >
          <Field
            label="Inredningsbelysning"
            value={order.wardrobe.has_interior_lighting ? "Ja" : "Nej"}
          />
        </View>
        {!!order.wardrobe.interior_lighting_price && (
          <Text
            style={{
              width: "12.5%",
              textAlign: "right",
              fontWeight: 500,
              padding: "10pt",
            }}
          >
            {(order.wardrobe.interior_lighting_price / 100).toLocaleString(
              "sv-SE"
            )}{" "}
            SEK
          </Text>
        )}
      </View>
      {lightingProducts.length > 0 && (
        <View style={{ borderBottom: "1pt solid #DDDDDD" }}>
          <ProductRow title="Belysningsprodukter" titleRow />
          {lightingProducts.map((row, index) => (
            <ProductRow
              key={`${row.id}-${
                row.product ? row.product.name : row.name
              }-${index}`}
              title={row.product ? row.product.name : row.name}
              price={
                (row.selectedConf ? row.selectedConf.price : row.price) / 100
              }
            />
          ))}
        </View>
      )}
    </View>
  );
  const Doors = ({ doors, figures }) => {
    let sectionRows = 0;
    let sections = [];
    let sumDoors = 0;
    let sumBars = 0;
    let numBars = 0;
    doors.map((door) => {
      if (door.sections.length > sectionRows) {
        sectionRows = door.sections.length;
      }
      sumDoors += door.price;
      numBars += door.bar_count;
      sumBars += door.bar_price;
    });
    for (let i = 0; i < sectionRows; i++) {
      sections[i] = [];
      doors.forEach((door) => {
        sections[i].push(i < door.sections.length ? door.sections[i] : null);
      });
    }

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderBottom: "1pt solid #DDDDDD",
        }}
      >
        <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
            }}
          >
            <BoxTitle
              title="Fältindelning"
              style={{
                width: "14.5%",
                borderBottom: "none",
                borderRight: "1pt solid #DDDDDD",
              }}
            />
            {doors &&
              doors.map((door, i) => (
                <View
                  key={door.id}
                  style={{
                    flex: 1,
                    maxWidth: "12.5%",
                    padding: "10pt",
                    borderRight:
                      doors.length === 6 && doors.length - 1 === i
                        ? null
                        : "1pt solid #DDDDDD",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Dörr {i + 1}
                  </Text>
                </View>
              ))}
            <View
              style={{
                padding: "10pt",
                width: "12.5%",
                marginLeft: "auto",
                borderRight: "1pt solid #DDDDDD",
                borderLeft: "1pt solid #DDDDDD",
              }}
            ></View>
            <View
              style={{
                padding: "10pt",
                width: "12.5%",
              }}
            ></View>
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text
            style={{
              textAlign: "right",
              padding: "10pt",
              width: "14.5%",
              borderRight: "1pt solid #DDDDDD",
            }}
          >
            Figurer
          </Text>
          {doors &&
            doors.map((door, i) => (
              <View
                key={door.id}
                style={{
                  display: "flex",
                  flex: 1,
                  maxWidth: "12.5%",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight:
                    doors.length === 6 && doors.length - 1 === i
                      ? null
                      : "1pt solid #DDDDDD",
                }}
              >
                <Image
                  style={{
                    width: "15pt",
                    height: "21pt",
                  }}
                  src={
                    API_URL +
                    figures.find((fig) => fig.id === door.layout_id).image
                  }
                />
              </View>
            ))}
          <View
            style={{
              padding: "10pt",
              width: "12.5%",
              marginLeft: "auto",
              borderRight: "1pt solid #DDDDDD",
              borderLeft: "1pt solid #DDDDDD",
            }}
          ></View>
          <View
            style={{
              padding: "10pt",
              width: "12.5%",
            }}
          ></View>
        </View>
        {sections.map((section, i) => (
          <View key={i} style={{ display: "flex", flexDirection: "row" }}>
            <Text
              style={{
                padding: "10pt",
                width: "14.5%",
                borderRight: "1pt solid #DDDDDD",
                textAlign: "right",
              }}
            >
              Fält {i + 1}
            </Text>
            {section.map((row, i) => {
              return row ? (
                <Text
                  key={i}
                  style={{
                    textAlign: "center",
                    padding: "10pt 2pt",
                    flex: 1,
                    maxWidth: "12.5%",
                    borderRight:
                      doors.length === 6 && doors.length - 1 === i
                        ? null
                        : "1pt solid #DDDDDD",
                    fontWeight: 500,
                  }}
                >
                  {row
                    ? `${row.area_color.product_group.name}: ${row.area_color.name}`
                    : ""}
                </Text>
              ) : (
                <View
                  key={i}
                  style={{
                    padding: "10pt 2pt",
                    flex: 1,
                    maxWidth: "12.5%",
                    borderRight:
                      doors.length === 6 && doors.length - 1 === i
                        ? null
                        : "1pt solid #DDDDDD",
                  }}
                ></View>
              );
            })}
            <View
              style={{
                padding: "10pt",
                width: "12.5%",
                marginLeft: "auto",
                borderRight: "1pt solid #DDDDDD",
                borderLeft: "1pt solid #DDDDDD",
              }}
            ></View>
            <View
              style={{
                padding: "10pt",
                width: "12.5%",
              }}
            ></View>
          </View>
        ))}
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text
            style={{
              padding: "10pt",
              width: "14.5%",
              borderRight: "1pt solid #DDDDDD",
              textAlign: "right",
            }}
          >
            Pris
          </Text>
          {doors.map((door, i) => (
            <Text
              key={door.id}
              style={{
                textAlign: "center",
                padding: "10pt 2pt",
                flex: 1,
                maxWidth: "12.5%",
                borderRight:
                  doors.length === 6 && doors.length - 1 === i
                    ? null
                    : "1pt solid #DDDDDD",
                fontWeight: 500,
              }}
            >
              {(door.price / 100).toLocaleString("sv-SE")} SEK
            </Text>
          ))}
          <View
            style={{
              padding: "10pt",
              width: "12.5%",
              marginLeft: "auto",
              borderRight: "1pt solid #DDDDDD",
              borderLeft: "1pt solid #DDDDDD",
            }}
          ></View>
          <Text
            style={{
              padding: "10pt",
              width: "12.5%",
              textAlign: "right",
              fontWeight: 500,
            }}
          >
            {(sumDoors / 100).toLocaleString("sv-SE")} SEK
          </Text>
        </View>
        {numBars > 0 && (
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text
              style={{
                padding: "10pt",
                width: "14.5%",
                borderRight: "1pt solid #DDDDDD",
                textAlign: "right",
              }}
            >
              Spröjs
            </Text>
            {doors.map((door, i) => (
              <Text
                key={i}
                style={{
                  textAlign: "center",
                  padding: "10pt",
                  flex: 1,
                  maxWidth: "12.5%",
                  borderRight:
                    doors.length === 6 && doors.length - 1 === i
                      ? null
                      : "1pt solid #DDDDDD",
                  fontWeight: 500,
                }}
              >
                {door.bar_count > 0 ? door.bar_count : ""}
              </Text>
            ))}
            <Text
              style={{
                padding: "10pt",
                width: "12.5%",
                marginLeft: "auto",
                borderRight: "1pt solid #DDDDDD",
                borderLeft: "1pt solid #DDDDDD",
                textAlign: "right",
                fontWeight: 500,
              }}
            ></Text>
            <Text
              style={{
                padding: "10pt",
                width: "12.5%",
                textAlign: "right",
                fontWeight: 500,
              }}
            >
              {(sumBars / 100).toLocaleString("sv-SE")} SEK
            </Text>
          </View>
        )}
      </View>
    );
  };

  const BuyInfo = ({ image, title, text }) => (
    <View
      style={{
        display: "flex",
        alignItems: "center",
        width: "48%",
        padding: "0 10pt",
        marginBottom: "20pt",
      }}
    >
      <Image src={image} style={{ height: "30pt", marginBottom: "5pt" }} />
      <Text
        style={{
          fontSize: "10pt",
          fontFamily: "Montserrat",
          fontWeight: 600,
          marginBottom: "5pt",
        }}
      >
        {title}
      </Text>
      <Text style={{ textAlign: "center" }}>{text}</Text>
    </View>
  );

  const ProductRow = ({ title, price, titleRow, titleBorder }) => (
    <View
      style={{
        width: "100%",
        dispaly: "flex",
        flexDirection: "row",
        borderBottom: titleBorder ? "1pt solid #DDDDDD" : null,
      }}
    >
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "5pt 10pt",
          width: "87.5%",
          borderRight: "1pt solid #DDDDDD",
        }}
      >
        <Text
          style={
            titleRow
              ? { fontSize: "10pt", fontFamily: "Montserrat" }
              : { fontSize: "10pt", fontWeight: 500 }
          }
        >
          {title}
        </Text>
      </View>
      {price && (
        <View
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "flex-end",
            padding: "5pt 10pt",
          }}
        >
          <Text style={{ fontWeight: 500 }}>
            {price.toLocaleString("sv-SE")} SEK
          </Text>
        </View>
      )}
    </View>
  );

  const WardrobeProducts = () => (
    <>
      {interiorProducts.length > 0 && (
        <View>
          <ProductRow title="Möbelinredning" titleRow />
          {interiorProducts.map((row, index) => (
            <ProductRow
              key={`${row.id}-${
                row.product ? row.product.name : row.name
              }-${index}`}
              title={row.product ? row.product.name : row.name}
              price={
                (row.selectedConf ? row.selectedConf.price : row.price) / 100
              }
            />
          ))}
        </View>
      )}
      {specialProducts.length > 0 && (
        <View>
          <ProductRow title="Specialprodukter" titleRow />
          {specialProducts.map((row, index) => (
            <ProductRow
              key={`${row.id}-${
                row.product ? row.product.name : row.name
              }-${index}`}
              title={row.product ? row.product.name : row.name}
              price={
                (row.selectedConf ? row.selectedConf.price : row.price) / 100
              }
            />
          ))}
        </View>
      )}
      {standProducts.length > 0 && (
        <View>
          <ProductRow title="Stativ och vägghängd inredning" titleRow />
          {standProducts.map((row, index) => (
            <ProductRow
              key={`${row.id}-${
                row.product ? row.product.name : row.name
              }-${index}`}
              title={row.product ? row.product.name : row.name}
              price={
                (row.selectedConf ? row.selectedConf.price : row.price) / 100
              }
            />
          ))}
        </View>
      )}
    </>
  );

  const WardrobePrice = () => (
    <>
      <Box>
        <PriceArea order={order} />
      </Box>
      <Row justifyContent="space-between" style={{ marginTop: "20pt" }} noWrap>
        <Column>
          <Box>
            <BoxTitle title="Instruktioner och info" />
            <InfoBox
              title="Monteringsinstruktioner"
              text={order.assembly_note}
            />
            <InfoBox title="Övrig info" text={order.note} />
          </Box>
        </Column>
      </Row>
    </>
  );

  const lightingProducts = order.rows.filter((row) => {
    const category = row.product ? row.product.category : row.category;
    return category === "lighting";
  });

  const interiorProducts = order.rows.filter((row) => {
    const category = row.product ? row.product.category : row.category;
    return (
      category === "interior" ||
      category === "interior_section" ||
      category === "interior_accessories" ||
      category === "base"
    );
  });
  const specialProducts = order.rows.filter((row) => {
    const category = row.product ? row.product.category : row.category;
    return category === "special" || category === "other_accessories";
  });
  const standProducts = order.rows.filter((row) => {
    const category = row.product ? row.product.category : row.category;
    return category === "stand_and_wallmounted";
  });

  const softCloseProduct = order.rows.find(
    (row) => row.product_id === SOFTCLOSE_ID
  );

  const dampingProduct = order.rows.find(
    (row) => row.product_id === DAMPING_ID
  );

  const getInteriorStyling = () => {
    const styling = {
      objectFit: "contain",
      width: "100vw",
      height: "99vh",
    };

    if (order.wardrobe.width > order.wardrobe.height) {
      styling.transform = "rotate(90deg)";
      styling.transformOrigin = "center";
      styling.width = "100vh";
      styling.height = "100vw";
    }

    return styling;
  };

  return (
    <GFDocument>
      <GFPage>
        <Logo />
        <Row justifyContent="space-between">
          <Column>
            <Box row>
              <DataRow title="Offertnummer" value={order.id} spacing="10pt" />
            </Box>
            <Box>
              <BoxTitle title="Kundinformation" />
              <DataRow
                title="Namn"
                value={
                  order.customer.type === "company"
                    ? order.customer.company_name
                    : order.customer.first_name + " " + order.customer.last_name
                }
                titleRight
              />
              <DataRow
                title="Leveransadress"
                value={`${order.customer.delivery_address} ${order.customer.delivery_zipcode} ${order.customer.delivery_city}`}
                titleRight
              />
              <DataRow
                title="Faktureringsadress"
                value={`${order.customer.billing_address} ${order.customer.billing_zipcode} ${order.customer.billing_city}`}
                titleRight
              />
              <DataRow
                title="E-post"
                value={
                  order.customer.type === "company"
                    ? order.customer.contact_email
                    : order.customer.email
                }
                titleRight
              />
              <DataRow
                title="Telefon"
                value={order.customer.contact_phone}
                titleRight
              />
            </Box>
          </Column>
          <Column>
            <Box>
              <DataRow
                title="Öppettider"
                value="mån stängt"
                titleRight
                spacing="5pt 10pt 0"
              />
              <DataRow value="tis 11-18" spacing="5pt 10pt 0" />
              <DataRow value="ons 11-18" spacing="5pt 10pt 0" />
              <DataRow value="tors 11-18" spacing="5pt 10pt 0" />
              <DataRow value="fre 11-16" spacing="5pt 10pt 0" />
              <DataRow value="lör 10-14" spacing="5pt 10pt 0" />
              <DataRow title="Växel" value="0500 - 42 00 21" titleRight />
              <BoxTitle
                title="Säljare"
                style={{ borderTop: "1pt solid #DDDDDD" }}
              />
              <DataRow
                title="Namn"
                value={
                  order.seller
                    ? `${order.seller.first_name} ${order.seller.last_name}`
                    : "-"
                }
                titleRight
              />
              <DataRow
                title="E-post"
                value={order.seller ? order.seller.email : "-"}
                titleRight
              />
              <DataRow
                title="Telefon"
                value={order.seller ? order.seller.phone : "-"}
                titleRight
              />
            </Box>
          </Column>
        </Row>
        {order.wardrobe ? (
          <>
            <Box fill>
              <BoxTitle title="Din garderob" />
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottom: "1pt solid #DDDDDD",
                    height: "265pt",
                    flexDirection: "row",
                    backgroundColor: "#C9C5BC",
                  }}
                >
                  <Image
                    src={API_URL + order.wardrobe.image}
                    style={{
                      height:
                        order.wardrobe.wardrobe_type !== "full_wardrobe"
                          ? "100%"
                          : null,
                    }}
                  />
                </View>
                <View>
                  <PriceArea order={order} />
                </View>
              </View>
            </Box>
            <Box>
              <Text
                style={{
                  borderBottom: "1pt solid #DDDDDD",
                  padding: "8pt 10pt",
                }}
              >
                <Text style={{ opacity: 0 }}>Underskrift</Text>
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "8pt 10pt",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text>Underskrift</Text>
                  <Text style={{ fontWeight: 500, marginLeft: "3pt" }}>
                    {order.customer.type === "company"
                      ? order.customer.company_name
                      : order.customer.first_name +
                        " " +
                        order.customer.last_name}
                  </Text>
                </View>
                <View>
                  <Text>Ort och datum</Text>
                </View>
              </View>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <WardrobeProducts />
            </Box>
            <WardrobePrice />
          </>
        )}
      </GFPage>
      {order.wardrobe && (
        <>
          <GFPage>
            <Box>
              <BoxTitle title="Garderobsspecifikationer" />
              <Row borderBottom>
                <View
                  style={{
                    width: "50%",
                    padding: "10pt",
                    borderRight: "1pt solid #DDDDDD",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InfoTitle title="Mått" />
                  <Row>
                    <Field label="Bredd" value={order.wardrobe.width + " mm"} />
                    <Field label="Höjd" value={order.wardrobe.height + " mm"} />
                    {order.wardrobe.depth && (
                      <Field
                        label="Djup"
                        value={order.wardrobe.depth + " mm"}
                      />
                    )}
                    <Field
                      label="Öppningsmått"
                      value={order.wardrobe.opening_width + " mm"}
                    />
                  </Row>
                </View>
                <View
                  style={{
                    width: "50%",
                    padding: "10pt",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <InfoTitle title="Placering" />
                  <Field value={placements[order.wardrobe.placement]} />
                </View>
              </Row>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1pt solid #DDDDDD",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                    padding: "10pt",
                  }}
                >
                  <InfoTitle title="Dörrar" />
                  <Row bottomSpacing>
                    <Field
                      label="Typ av dörrar"
                      value={doorTypes[order.wardrobe.door_type]}
                    />
                    <Field
                      label="Antal dörrar"
                      value={order.wardrobe.door_count}
                    />
                    <Field label="Spår" value={order.wardrobe.rail_count} />
                  </Row>
                  <Row bottomSpacing>
                    <Field label="Rammodell" value={profileName} />
                    <Field label="Färg" value={order.wardrobe.profile.name} />
                  </Row>
                  <Row bottomSpacing>
                    <Field
                      label="Mjukstängning"
                      value={order.wardrobe.soft_close_count}
                    />
                  </Row>
                  <Row>
                    <Field
                      label="Filtlister"
                      value={order.wardrobe.damping_strips}
                    />
                  </Row>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "12.5%",
                    borderRight: "1pt solid #DDDDDD",
                    borderLeft: "1pt solid #DDDDDD",
                    padding: "10pt",
                  }}
                >
                  <InfoTitle title="Styckpris" />
                  <Row style={styles.doorPrice}></Row>
                  <Row style={styles.doorPrice}>
                    <Text>
                      {(order.wardrobe.profile.price / 100).toLocaleString(
                        "sv-SE"
                      )}{" "}
                      SEK
                    </Text>
                  </Row>
                  <Row style={styles.doorPrice}>
                    {softCloseProduct && (
                      <Text>
                        {(softCloseProduct.price / 100).toLocaleString("sv-SE")}{" "}
                        SEK
                      </Text>
                    )}
                  </Row>
                  <Row style={styles.doorPrice}>
                    {dampingProduct && (
                      <Text>
                        {(dampingProduct.price / 100).toLocaleString("sv-SE")}{" "}
                        SEK
                      </Text>
                    )}
                  </Row>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "12.5%",
                    padding: "10pt",
                  }}
                >
                  <InfoTitle title="Pris" />
                  <Row style={styles.doorPrice}>
                    {order.wardrobe.rail_price > 0 && (
                      <Text>
                        {(order.wardrobe.rail_price / 100).toLocaleString(
                          "sv-SE"
                        )}{" "}
                        SEK
                      </Text>
                    )}
                  </Row>
                  <Row style={styles.doorPrice}>
                    <Text>
                      {(order.wardrobe.profile_price / 100).toLocaleString(
                        "sv-SE"
                      )}{" "}
                      SEK
                    </Text>
                  </Row>
                  <Row style={styles.doorPrice}>
                    {softCloseProduct && (
                      <Text>
                        {(softCloseProduct.price / 100).toLocaleString("sv-SE")}{" "}
                        SEK
                      </Text>
                    )}
                  </Row>
                  <Row style={styles.doorPrice}>
                    {dampingProduct && (
                      <Text>
                        {(dampingProduct.price / 100).toLocaleString("sv-SE")}{" "}
                        SEK
                      </Text>
                    )}
                  </Row>
                </View>
              </View>
              <Doors doors={order.wardrobe.doors} figures={figures} />
              {Boolean(
                order.wardrobe.sloping_cieling ||
                  order.wardrobe.side_wall_count > 0 ||
                  order.wardrobe.bead_count > 0
              ) && (
                <Row borderBottom>
                  <View
                    style={{
                      width: "87.5%",
                      padding: "10pt",
                      borderRight: "1pt solid #DDDDDD",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {order.wardrobe.side_wall_count > 0 && (
                      <Row bottomSpacing>
                        <Field
                          label="Sidovägg"
                          value={
                            order.wardrobe.side_wall_product_configuration
                              ? order.wardrobe.side_wall_product_configuration
                                  .meta.dimensions
                              : "-"
                          }
                        />
                        <Field
                          label="Färg"
                          value={
                            order.wardrobe.side_wall_product_configuration
                              ? order.wardrobe.side_wall_product_configuration
                                  .meta.color
                              : "-"
                          }
                        />
                        <Field
                          label="Antal"
                          value={order.wardrobe.side_wall_count}
                        />
                      </Row>
                    )}
                    {order.wardrobe.sloping_cieling && (
                      <Row bottomSpacing>
                        <Field
                          label="Snedtak bakåt"
                          value={order.wardrobe.sloping_cieling ? "Ja" : "Nej"}
                        />
                        <Field
                          label="Antal snedtaksvinklar"
                          value={order.wardrobe.sloping_cieling_count}
                        />
                      </Row>
                    )}
                    {order.wardrobe.bead_count > 0 && (
                      <Row bottomSpacing>
                        <Field
                          label="Anslagslister"
                          value={order.wardrobe.bead_count > 0 ? "Ja" : "Nej"}
                        />
                        <Field
                          label="Färg"
                          value={
                            order.wardrobe.bead_product_configuration.meta.color
                          }
                        />
                        <Field
                          label="Antal"
                          value={order.wardrobe.bead_count}
                        />
                      </Row>
                    )}
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "12.5%",
                      padding: "10pt",
                    }}
                  >
                    {order.wardrobe.side_wall_count > 0 && (
                      <Row style={styles.doorPrice}>
                        <Text>{order.wardrobe.side_wall_price / 100} SEK</Text>
                      </Row>
                    )}

                    {order.wardrobe.sloping_cieling && (
                      <Row style={styles.doorPrice}>
                        <Text>
                          {order.wardrobe.sloping_cieling_price / 100} SEK
                        </Text>
                      </Row>
                    )}

                    {order.wardrobe.bead_count > 0 && (
                      <Row style={styles.doorPrice}>
                        <Text>{order.wardrobe.bead_price / 100} SEK</Text>
                      </Row>
                    )}
                  </View>
                </Row>
              )}
              <Lighting order={order} />
              <WardrobeProducts />
            </Box>
          </GFPage>
          <GFPage>
            <WardrobePrice />
          </GFPage>
        </>
      )}
      {order.wardrobe.wardrobe_type !== "only_doors" && (
        <Page
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#C9C5BC",
          }}
        >
          <Image
            src={API_URL + order.wardrobe.interior_image}
            style={getInteriorStyling()}
          />
        </Page>
      )}
      <GFPage
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: "26pt",
            fontFamily: "Montserrat",
            fontWeight: 600,
            marginBottom: "20pt",
          }}
        >
          När du köper från oss
        </Text>
        <Row
          style={{ width: "90%", flexWrap: "wrap", marginBottom: "40pt" }}
          justifyContent="space-between"
        >
          <BuyInfo
            image={tapeImage}
            title="Specialmått är vår standard"
            text={
              <>
                Vi måttanpassar alla våra skjutdörrar och designar era
                skjutdörrar precis så ni önskar dem. Valmöjligheterna är
                oändliga, bara fantasin sätter gränser.
              </>
            }
          />
          <BuyInfo
            image={trayImage}
            title="Kundanpassad och platsbyggd inredning"
            text={
              <>
                Behov från hushåll till hushåll ser olika ut och därför är det
                självklart för oss att vi ska anpassa inredningen efter era
                behov. Tillsammans planerar vi en inredning precis som ni önskar
                den. Vi skapar en lösning utan passbitar och använder all yta
                maximalt.
              </>
            }
          />
          <BuyInfo
            image={skovdeImage}
            title="Garderober tillverkade i Skövde"
            text={
              <>
                Alla skjutdörrar tillverkas i våra lokaler i Skövde, där har vi
                både fabrik och showroom. Vi köper in material och delar från de
                bästa leverantörerna i Sverige och övriga Europa för att kunna
                leverera Sveriges bästa och mest prisvärda garderob. Vi har idag
                butiker i Örebro och Skövde men skickar varje vecka garderober
                till hela Sverige.
              </>
            }
          />
          <BuyInfo
            image={hammerImage}
            title="Våra snickare"
            text={
              <>
                Vi erbjuder givetvis montering av våra garderober. Vi har
                duktiga snickare som har monterat ett par hundra garderober och
                de önskar att montera även er. Våra snickare börjar ta betalt
                när de laster bilen med er garderob t.o.m. att de är tillbaka
                hos oss efter arbetet har slutförts. En startkostnad/färdkostnad
                tillkommer. Bor ni långt bort kan milersättning tillkomma.
              </>
            }
          />
          <BuyInfo
            image={rotImage}
            title="Rotavdrag"
            text={
              <>
                Uppfyller ni skatteverkets krav drar vi av 30% på
                monteringskostnaden på er slutfaktura. Vi sköter
                pappershantering och ansöker om rotavdrag från skatteverket. Ni
                behöver bara uppge personnummer samt uppgifter om er bostad så
                sköter vi resten. Läs gärna mer på skatteverkets hemsida eller
                fråga oss om mer information.
              </>
            }
          />
          <BuyInfo
            image={factoryImage}
            title="Direkt från fabrik"
            text={
              <>
                När ni köper era måttanpassade skjutdörrar och inredning av oss
                på Garderobsfabriken slipper ni dyra mellanhänder. Vi tillverkar
                era garderober i Skövde. Detta gör att vi även har full kontroll
                på produktion och sortiment. Vi är proffs på garderober!
              </>
            }
          />
        </Row>
        <Row>
          <Logo />
        </Row>
      </GFPage>
    </GFDocument>
  );
};
export const PicklistPDF = ({ order, interiorSectionProducts }) => {
  let tableRowCount = 0;
  const TableRow = ({ artnr, num, product, measure, color, vw, header }) => {
    const fontWeight = header ? 500 : 400;
    tableRowCount++;

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          padding: header ? 0 : "4pt 0",
          backgroundColor: tableRowCount % 2 ? "#f9f9f9" : null,
        }}
      >
        <Text
          style={{
            width: "10%",
            paddingLeft: "10pt",
            fontWeight: fontWeight,
          }}
        >
          {artnr}
        </Text>
        <Text
          style={{
            width: "5%",
            textAlign: "right",
            fontWeight: fontWeight,
          }}
        >
          {num}
        </Text>
        <Text
          style={{
            width: "20%",
            paddingLeft: "10pt",
            fontWeight: fontWeight,
          }}
        >
          {product}
        </Text>
        <Text
          style={{
            width: "20%",
            paddingLeft: "10pt",
            fontWeight: fontWeight,
          }}
        >
          {measure}
        </Text>
        <Text
          style={{
            width: "15%",
            paddingLeft: "10pt",
            fontWeight: fontWeight,
          }}
        >
          {ColorNames[color] || color}
        </Text>
        <Text
          style={{
            width: "20%",
            paddingLeft: "10pt",
            fontWeight: fontWeight,
          }}
        >
          {vw}
        </Text>
        {header ? (
          <Text
            style={{
              width: "10%",
              textAlign: "right",
              paddingRight: "10pt",
              fontWeight: fontWeight,
            }}
          >
            Plockad
          </Text>
        ) : (
          <View
            style={{
              height: "8pt",
              width: "8pt",
              border: "1pt solid #DDDDDD",
              marginRight: "10pt",
              borderRadius: "99999999",
              marginLeft: "auto",
            }}
          />
        )}
      </View>
    );
  };

  return (
    <GFDocument>
      <GFPage>
        <Logo />
        <Row justifyContent="space-between">
          <Column>
            <Box row>
              <DataRow title="Ordernummer" value={order.id} spacing="10pt" />
            </Box>
            <Box>
              <BoxTitle title="Kundinformation" />
              <DataRow
                title="Namn"
                value={
                  order.customer.type === "company"
                    ? order.customer.company_name
                    : order.customer.first_name + " " + order.customer.last_name
                }
                titleRight
              />
              <DataRow
                title="Leveransadress"
                value={`${order.customer.delivery_address} ${order.customer.delivery_zipcode} ${order.customer.delivery_city}`}
                titleRight
              />
              <DataRow
                title="Faktureringsadress"
                value={`${order.customer.billing_address} ${order.customer.billing_zipcode} ${order.customer.billing_city}`}
                titleRight
              />
              <DataRow
                title="E-post"
                value={
                  order.customer.type === "company"
                    ? order.customer.contact_email
                    : order.customer.email
                }
                titleRight
              />
              <DataRow
                title="Telefon"
                value={order.customer.contact_phone}
                titleRight
              />
            </Box>
          </Column>
          <Column>
            <Box>
              <BoxTitle title="Instruktioner och info" />
              {order.assembly_note !== "" && (
                <InfoBox
                  title="Monteringsinstruktioner"
                  text={order.assembly_note}
                />
              )}
              {order.note !== "" && (
                <InfoBox title="Övrig info" text={order.note} />
              )}
              <InfoBox
                title="Fraktsätt"
                text={getShippingText(order.delivery_option)}
              />
            </Box>
          </Column>
        </Row>
        <Box>
          <BoxTitle title="Plocklista" />
          <View
            style={{
              backgroundColor: "#f9f9f9",
              padding: "2pt 0",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TableRow
              artnr="Art.nr"
              num="Antal"
              product="Produkt"
              color="Färg"
              measure="Mått"
              vw="V och W"
              header
            />
          </View>
          {order.rows.length > 0 &&
            order.rows.map((row, i) => (
              <React.Fragment key={i}>
                {row.quantity > 0 &&
                  row.product_id !== SOFTCLOSE_ID &&
                  row.product_id !== DAMPING_ID && (
                    <TableRow
                      artnr={
                        row.product_configuration
                          ? row.product_configuration.meta.article_number
                          : null
                      }
                      num={row.quantity}
                      product={row.product.name}
                      color={
                        row.product_configuration
                          ? row.product_configuration.color
                          : null
                      }
                      vw={
                        row.product_configuration
                          ? row.product_configuration.meta.v_and_w
                          : null
                      }
                      measure={
                        row.product_configuration
                          ? row.product_configuration.meta.dimensions
                          : null
                      }
                    />
                  )}
              </React.Fragment>
            ))}
          {interiorSectionProducts.length > 0 &&
            interiorSectionProducts.map((row) => (
              <TableRow
                key={row.id}
                artnr={
                  row.product_configuration
                    ? row.product_configuration.meta.article_number
                    : null
                }
                num={row.quantity}
                product={row.product.name}
                color={
                  row.product_configuration
                    ? row.product_configuration.color
                    : null
                }
                vw={
                  row.product_configuration
                    ? row.product_configuration.meta.v_and_w
                    : null
                }
                measure={
                  row.product_configuration
                    ? row.product_configuration.meta.dimensions
                    : null
                }
              />
            ))}
          {order.wardrobe.side_wall_count > 0 && (
            <TableRow
              num={order.wardrobe.side_wall_count}
              product="Sidovägg"
              measure={
                order.wardrobe.side_wall_product_configuration.meta.dimensions
              }
              color={order.wardrobe.side_wall_product_configuration.meta.color}
            />
          )}
          {order.wardrobe.top_shelf_count > 0 && (
            <TableRow
              num={order.wardrobe.top_shelf_count}
              product="Tophylla"
              measure={
                order.wardrobe.top_shelf_product_configuration.meta.dimensions
              }
              color={order.wardrobe.top_shelf_product_configuration.color}
            />
          )}
          {order.wardrobe.sloping_cieling_count > 0 && (
            <TableRow
              num={order.wardrobe.sloping_cieling_count}
              product="Snedtaksvinklar"
            />
          )}
          {order.wardrobe.bead_count > 0 && (
            <TableRow
              num={order.wardrobe.bead_count}
              product="Anslagslist"
              measure={
                order.wardrobe.bead_product_configuration.meta.dimensions
              }
              color={order.wardrobe.bead_product_configuration.meta.color}
            />
          )}
          {order.wardrobe.has_interior_lighting &&
            order.wardrobe.interior_lighting.products.map((row) => (
              <TableRow
                key={row.id}
                artnr={
                  row.product_configuration
                    ? row.product_configuration.meta.article_number
                    : null
                }
                num={row.quantity}
                product={row.product.name}
                color={
                  row.product_configuration
                    ? row.product_configuration.color
                    : null
                }
                vw={
                  row.product_configuration
                    ? row.product_configuration.meta.v_and_w
                    : null
                }
                measure={
                  row.product_configuration
                    ? row.product_configuration.meta.dimensions
                    : null
                }
              />
            ))}
        </Box>
      </GFPage>
    </GFDocument>
  );
};

export const ProductionPDF = ({ order, profileName, figures }) => {
  const DoorTableTitle = ({ title }) => (
    <View
      style={{
        display: "flex",
        flex: 3,
        padding: "5pt",
        borderLeft: "1pt solid #DDDDDD",
        borderBottom: "1pt solid #DDDDDD",
      }}
    >
      <Text>{title}</Text>
    </View>
  );

  const DoorTable = ({ doors, figures }) => (
    <View style={{ display: "flex", flex: 1 }}>
      <Row>
        <View style={{ flex: 2, paddingLeft: "10pt" }}></View>
        <DoorTableTitle title="Fältindelning" />
        <DoorTableTitle title="Fyllning" />
        <DoorTableTitle title="Fyllningshöjd" />
      </Row>
      {doors.map((door, i) => (
        <Row key={door.id}>
          <View style={{ display: "flex", flex: 2, padding: "5pt 0 5pt 10pt" }}>
            <Text>Dörr {i + 1}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 3,
              padding: "5pt",
              borderLeft: "1pt solid #DDDDDD",
            }}
          >
            <Image
              style={{
                width: "10pt",
                height: "14pt",
                marginRight: "3pt",
              }}
              src={
                API_URL + figures.find((fig) => fig.id === door.layout_id).image
              }
            />
            <Text>{figures.find((fig) => fig.id === door.layout_id).name}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 3,
              padding: "5pt",
              borderLeft: "1pt solid #DDDDDD",
            }}
          >
            {door.sections.length > 0 &&
              door.sections.map((section, i) => (
                <Text key={i} style={{ fontWeight: 500, paddingBottom: "5pt" }}>
                  {section.area_color.product_group.name}:{" "}
                  {section.area_color.name}
                </Text>
              ))}
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 3,
              padding: "5pt",
              borderLeft: "1pt solid #DDDDDD",
            }}
          >
            {door.sections.length > 0 &&
              door.sections.map((section) => (
                <Text
                  key={section.id}
                  style={{ fontWeight: 500, paddingBottom: "5pt" }}
                >
                  {section.height.toFixed(2) + " mm"}
                </Text>
              ))}
          </View>
        </Row>
      ))}
      <Row style={{ flex: 1 }}>
        <View style={{ flex: 2, paddingLeft: "10pt" }}></View>
        <View
          style={{
            flex: 3,
            paddingHorizontal: "5pt",
            borderLeft: "1pt solid #DDDDDD",
          }}
        ></View>
        <View
          style={{
            flex: 3,
            paddingHorizontal: "5pt",
            borderLeft: "1pt solid #DDDDDD",
          }}
        ></View>
        <View
          style={{
            flex: 3,
            paddingHorizontal: "5pt",
            borderLeft: "1pt solid #DDDDDD",
          }}
        ></View>
      </Row>
    </View>
  );

  return (
    <GFDocument>
      <GFPage>
        <Logo />
        <Row justifyContent="space-between">
          <Column>
            <Box row>
              <DataRow title="Ordernummer" value={order.id} spacing="10pt" />
            </Box>
            <Box>
              <BoxTitle title="Kundinformation" />
              <DataRow
                title="Namn"
                value={
                  order.customer.type === "company"
                    ? order.customer.company_name
                    : order.customer.first_name + " " + order.customer.last_name
                }
                titleRight
              />
              <DataRow
                title="Leveransadress"
                value={`${order.customer.delivery_address} ${order.customer.delivery_zipcode} ${order.customer.delivery_city}`}
                titleRight
              />
              <DataRow
                title="Faktureringsadress"
                value={`${order.customer.billing_address} ${order.customer.billing_zipcode} ${order.customer.billing_city}`}
                titleRight
              />
              <DataRow
                title="E-post"
                value={
                  order.customer.type === "company"
                    ? order.customer.contact_email
                    : order.customer.email
                }
                titleRight
              />
              <DataRow
                title="Telefon"
                value={order.customer.contact_phone}
                titleRight
              />
            </Box>
          </Column>
          <Column>
            <Box>
              <BoxTitle title="Instruktioner och info" />
              {order.assembly_note !== "" && (
                <InfoBox
                  title="Monteringsinstruktioner"
                  text={order.assembly_note}
                />
              )}
              {order.note !== "" && (
                <InfoBox title="Övrig info" text={order.note} />
              )}
              <InfoBox
                title="Fraktsätt"
                text={getShippingText(order.delivery_option)}
              />
            </Box>
          </Column>
        </Row>
        {order.wardrobe.wardrobe_type !== "only_interior" && order.wardrobe && (
          <Box>
            <BoxTitle title="Garderobsspecifikationer" />
            <Row borderBottom>
              <View
                style={{
                  width: "50%",
                  padding: "10pt",
                  borderRight: "1pt solid #DDDDDD",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InfoTitle title="Mått" />
                <Row>
                  <Field label="Bredd" value={order.wardrobe.width + " mm"} />
                  <Field label="Höjd" value={order.wardrobe.height + " mm"} />
                  {order.wardrobe.depth && (
                    <Field label="Djup" value={order.wardrobe.depth + " mm"} />
                  )}
                  <Field
                    label="Öppningsmått"
                    value={order.wardrobe.opening_width + " mm"}
                  />
                </Row>
              </View>
              <View
                style={{
                  width: "50%",
                  padding: "10pt",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InfoTitle title="Dörrar" />
                <Row>
                  <Field
                    label="Typ av dörrar"
                    value={doorTypes[order.wardrobe.door_type]}
                  />
                  <Field
                    label="Antal dörrar"
                    value={order.wardrobe.door_count}
                  />
                  <Field
                    label="Dörrbredd"
                    value={order.wardrobe.door_width.toFixed(2) + " mm"}
                  />
                  <Field
                    label="Överlapp"
                    value={order.wardrobe.overlap + " mm"}
                  />
                </Row>
              </View>
            </Row>
            <Row>
              <View
                style={{
                  dispay: "flex",
                  flexDirection: "column",
                  width: "50%",
                  borderRight: "1pt solid #DDDDDD",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    padding: "10pt",
                    flexDirection: "column",
                    borderBottom: "1pt solid #DDDDDD",
                  }}
                >
                  <InfoTitle title="Spår och skenor" />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Field label="Spår" value={order.wardrobe.rail_count} />
                    <Field
                      label="Dörrbelysning"
                      value={order.wardrobe.door_lighting ? "Ja" : "Nej"}
                    />
                    {order.wardrobe.damping_strips > 0 && (
                      <Field
                        label="Dämplist"
                        value={order.wardrobe.damping_strips}
                      />
                    )}
                    {order.wardrobe.soft_close_count > 0 && (
                      <Field
                        label="Mjukstängning"
                        value={order.wardrobe.soft_close_count}
                      />
                    )}
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    padding: "10pt",
                    flexDirection: "column",
                    borderBottom: "1pt solid #DDDDDD",
                  }}
                >
                  <InfoTitle title="Profilmått" />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Field label="Rammodell" value={profileName} />
                    <Field label="Färg" value={order.wardrobe.profile.name} />
                    <Field
                      label="Sidoprofiler"
                      value={order.wardrobe.side_profile_length + " mm"}
                    />
                    <Field
                      label="Topp och botten"
                      value={
                        order.wardrobe.top_bottom_profile_length.toFixed(2) +
                        " mm"
                      }
                    />
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    padding: "10pt",
                    flexDirection: "column",
                  }}
                >
                  <InfoTitle title="Fyllningsmått" />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Field
                      label="Höjd"
                      value={`${order.wardrobe.area_height.toFixed(2)} mm`}
                    />
                    <Field
                      label="Bredd"
                      value={`${order.wardrobe.area_width.toFixed(2)} mm`}
                    />
                  </View>
                </View>
              </View>
              <View style={{ dispay: "flex", width: "50%" }}>
                <DoorTable figures={figures} doors={order.wardrobe.doors} />
              </View>
            </Row>
          </Box>
        )}
      </GFPage>
    </GFDocument>
  );
};
export const LabelPdf = ({ order, profileName }) => {
  const styles = StyleSheet.create({
    contentBox: {
      border: "1pt solid #DDDDDD",
      borderRadius: "10pt",
      display: "flex",
      flexDirection: "col",
      padding: "10pt",
      marginTop: "10pt",
    },
  });

  return (
    <GFDocument>
      <GFPage orientation="landscape" size="A5">
        <Row>
          <Column>
            <Box>
              <DataRow
                title="Kundnr."
                value={order.customer.customer_number}
                borderBottom
              />
              <DataRow title="Ordernr." value={order.id} />
            </Box>
          </Column>
          <Logo />
        </Row>
        <Text
          style={{
            fontSize: "24pt",
            marginTop: "20pt",
          }}
        >
          {order.customer.type === "company"
            ? order.customer.company_name
            : order.customer.first_name + " " + order.customer.last_name}
        </Text>
        <View style={styles.contentBox}>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Field label="Öppningsmått" value={order.wardrobe.width + " mm"} />
            <Field label="Höjd" value={order.wardrobe.height + " mm"} />
            {order.wardrobe.wardrobe_type !== "only_interior" && (
              <>
                <Field label="Antal dörrar" value={order.wardrobe.door_count} />
                <Field label="Antal spår" value={order.wardrobe.rail_count} />
              </>
            )}
            <Field
              label="Belysning"
              value={order.wardrobe.door_lighting ? "Ja" : "Nej"}
            />
          </View>
          {order.wardrobe.wardrobe_type !== "only_interior" && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10pt",
              }}
            >
              <Field label="Profil" value={profileName} />
              <Field label="Färg" value={order.wardrobe.profile.name} />
              <Field
                label="Mjukstängning"
                value={order.wardrobe.soft_close_count}
              />
              <Field label="Filtlister" value={order.wardrobe.damping_strips} />
            </View>
          )}
        </View>
        <View style={[styles.contentBox, { flexDirection: "row" }]}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "50pt",
            }}
          >
            <Field
              label="Adress"
              value={
                order.delivery_address
                  ? order.delivery_address
                  : order.customer.delivery_address
              }
            />
            <Text
              style={{
                fontSize: "10pt",
                fontWeight: 500,
                marginTop: "3pt",
              }}
            >
              {order.delivery_zipcode && order.delivery_city
                ? `${order.delivery_zipcode} ${order.delivery_city}`
                : `${order.customer.delivery_zipcode} ${order.customer.delivery_city}`}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "50pt",
            }}
          >
            <Field
              label="Kontaktuppgifter"
              value={
                order.customer.type === "company"
                  ? order.customer.contact_email
                  : order.customer.email
              }
            />
            <Text
              style={{
                fontSize: "10pt",
                fontWeight: 500,
                marginTop: "3pt",
              }}
            >
              {order.customer.type === "company"
                ? order.customer.contact_phone
                : order.customer.contact_phone}
            </Text>
          </View>
        </View>
      </GFPage>
    </GFDocument>
  );
};

Font.register({
  family: "Roboto",
  fonts: [{ src: robotoFont }, { src: robotoFontMedium, fontWeight: 500 }],
});

Font.register({
  family: "Montserrat",
  fonts: [
    { src: montserratFont },
    { src: montserratFontSemiBold, fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  document: {
    width: "100%",
    height: "100%",
  },
  page: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f8f8f8",
    color: "#777777",
    fontFamily: "Roboto",
    fontSize: "8pt",
    padding: "10pt",
  },
  pageView: {
    display: "flex",
    flex: 1,
    backgroundColor: "#fff",
    border: "1pt solid #DDDDDD",
    borderRadius: "16pt",
    padding: "20pt",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    border: "1pt solid #DDDDDD",
    flexWrap: "nowrap",
    borderRadius: "8pt",
    marginBottom: "10pt",
  },
  boxTitle: {
    fontFamily: "Montserrat",
    borderBottom: "1pt solid #DDDDDD",
    padding: "8pt 10pt",
  },
  dataRow: {
    display: "flex",
    flexDirection: "row",
  },
  dataRowTitle: {
    width: "40%",
    backgroundColor: "#f9f9f9",
    borderRight: "1pt solid #DDDDDD",
    padding: "5pt 10pt",
  },
  dataRowValue: { width: "60%", padding: "5pt 10pt" },
  infoBox: {
    title: {
      backgroundColor: "#f9f9f9",
      fontSize: "6pt",
      fontWeight: 500,
      padding: "5pt 10pt",
    },
    text: {
      flexShrink: 1,
      padding: "10pt",
    },
  },
  field: {
    display: "flex",
    flexDirection: "column",
    minWidth: "50pt",
    marginRight: "10pt",
    label: {
      marginBottom: "4pt",
    },
    value: {
      fontSize: "10pt",
      fontWeight: 500,
    },
  },
});

const GFDocument = ({ children }) => (
  <PDFViewer style={{ width: "100%", height: "90vh" }}>
    <Document style={styles.document}>{children}</Document>
  </PDFViewer>
);

const GFPage = ({ size, orientation, style, children }) => (
  <Page
    size={size ? size : "A4"}
    orientation={orientation ? orientation : "portrait"}
    style={styles.page}
  >
    <View style={[styles.pageView, style]}>{children}</View>
  </Page>
);

const Logo = () => (
  <View
    style={{
      marginBottom: "16pt",
      marginLeft: "auto",
    }}
  >
    <Image
      src={gardeLogo}
      style={{
        height: "30pt",
      }}
    />
  </View>
);

const Row = ({
  children,
  borderBottom,
  justifyContent,
  bottomSpacing,
  noWrap,
  style,
}) => (
  <View
    style={[
      styles.row,
      {
        borderBottom: borderBottom ? "1pt solid #DDDDDD" : null,
        justifyContent: justifyContent ? justifyContent : null,
        marginBottom: bottomSpacing ? "5pt" : null,
        ...style,
      },
    ]}
    wrap={!noWrap}
  >
    {children}
  </View>
);

const Column = ({ children }) => <View style={styles.column}>{children}</View>;

const Box = ({ children, row, fill }) => (
  <View
    style={[
      styles.box,
      {
        flexDirection: row ? "row" : null,
        flex: fill ? 1 : null,
      },
    ]}
  >
    {children}
  </View>
);

const BoxTitle = ({ title, style }) => (
  <Text style={[styles.boxTitle, style]}>{title}</Text>
);

const DataRow = ({ title, value, titleRight, borderBottom, spacing }) => (
  <View
    style={[
      styles.dataRow,
      {
        borderBottom: borderBottom ? "1pt solid #DDDDDD" : null,
      },
    ]}
  >
    <View
      style={[
        styles.dataRowTitle,
        {
          padding: spacing ? spacing : null,
        },
      ]}
    >
      <Text style={titleRight ? { textAlign: "right", fontWeight: 500 } : null}>
        {title}
      </Text>
    </View>
    <View
      style={[
        styles.dataRowValue,
        {
          padding: spacing ? spacing : null,
        },
      ]}
    >
      <Text>{value}</Text>
    </View>
  </View>
);

const InfoTitle = ({ title }) => (
  <Text style={{ fontSize: "10pt", marginBottom: "5pt" }}>{title}</Text>
);

const InfoBox = ({ title, text }) => (
  <>
    <Text style={styles.infoBox.title}>{title}</Text>
    <Text style={styles.infoBox.text}>{text}</Text>
  </>
);

const Field = ({ label, value }) => (
  <View style={styles.field}>
    <Text style={styles.field.label}>{label}</Text>
    <Text style={styles.field.value}>{value}</Text>
  </View>
);
