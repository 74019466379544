import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login/";
import ResetPassword from "./pages/resetpassword/";
import Admin from "./pages/admin/";
import { store } from "./store";
import { observer, Observer } from "mobx-react";
import { configure } from "mobx";

configure({
  enforceActions: "never",
});

const App = observer(() => {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <Observer>
              {() => (!store.token ? <Login /> : <Navigate to="/" />)}
            </Observer>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <Observer>
              {() => (!store.token ? <ResetPassword /> : <Navigate to="/" />)}
            </Observer>
          }
        />
        <Route
          path="/*"
          element={
            <Observer>
              {() => (store.token ? <Admin /> : <Navigate to="/login" />)}
            </Observer>
          }
        />
      </Routes>
    </Router>
  );
});

export default App;
