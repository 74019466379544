import React, { useEffect } from "react";
import { ToggleBox, Circle } from "../../components";
import { Calendar, RightArrow } from "../../icons";
import DatePicker from "react-datepicker";
import { useLocalObservable, observer } from "mobx-react";
import "react-datepicker/dist/react-datepicker.css";
import { months } from "../../constants";
import { get } from "../../api";
import { Link } from "react-router-dom";
import { getBadge } from "../../utils";

function changeDateText(start, end, setDateText) {
  if (!start || !end) {
    return;
  }
  if (start.getMonth() === end.getMonth()) {
    if (start.getDate() === 1 && isLastDay(end)) {
      setDateText(months[start.getMonth()]);
    } else if (start.getDate() === end.getDate()) {
      setDateText(`${start.getDate()} ${months[start.getMonth()]}`);
    } else {
      setDateText(
        `${start.getDate()} - ${end.getDate()} ${months[start.getMonth()]}`
      );
    }
  } else {
    setDateText(
      `${start.getDate()} ${months[start.getMonth()]} - ${end.getDate()} ${
        months[end.getMonth()]
      }`
    );
  }
}

function isLastDay(dt) {
  let date = new Date(dt.getTime()),
    month = date.getMonth();

  date.setDate(date.getDate() + 1);
  return date.getMonth() !== month;
}

const Statistics = observer(() => {
  const store = useLocalObservable(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    return {
      startDate: firstDayOfMonth,
      endDate: today,
      showDatePicker: false,
      dateText: "",
      stats: [],
      totalOrders: 0,
      totalSum: 0,
      setDateText(text) {
        this.dateText = text;
      },
    };
  });

  useEffect(() => {
    changeDateText(store.startDate, store.endDate, store.setDateText);
    getStatistcs();
  }, []);

  useEffect(() => {
    getStatistcs();
  }, [store.startDate, store.endDate]);

  const getStatistcs = async () => {
    try {
      const res = await get(
        `/statistics${
          store.startDate && store.endDate
            ? `?start=${store.startDate.toLocaleDateString(
                "sv-SE"
              )}&end=${store.endDate.toLocaleDateString("sv-SE")}`
            : ""
        }`
      );
      store.stats = res.data;
      let sum = 0;
      let sumOrders = 0;
      res.data.forEach((stat) => {
        sum += stat.sum;
        sumOrders += stat.num_orders;
      });
      store.totalSum = sum;
      store.totalOrders = sumOrders;
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;

    store.startDate = start;
    store.endDate = end;
    if (start && end) {
      changeDateText(start, end, store.setDateText);
      store.showDatePicker = false;
    }
  };

  return (
    <ToggleBox toggle={false} title="Statistik" className="overflow-visible">
      <div className="flex relative border-b border-grey items-center py-3 px-4 md:px-7 md:justify-center">
        <p className="text-xs font-mont text-left md:text-center">
          {store.dateText}
        </p>
        <Circle
          type="button"
          onClick={() => (store.showDatePicker = !store.showDatePicker)}
          className={`date-picker ${store.showDatePicker ? "active" : ""}`}
        >
          <Calendar />
        </Circle>
        {store.showDatePicker && (
          <DatePicker
            calendarClassName="date-picker-cal"
            selected={store.startDate}
            onChange={onChange}
            startDate={store.startDate}
            endDate={store.endDate}
            selectsRange
            inline
          />
        )}
      </div>
      <div className="grid grid-cols-3 stats-grid">
        <p className="bg-grey-lighter border-b border-grey text-left left font-medium">
          Säljare
        </p>
        <p className="bg-grey-lighter border-b border-grey whitespace-nowrap font-medium">
          Antal ordrar
        </p>
        <p className="bg-grey-lighter border-b border-grey whitespace-nowrap font-medium right">
          Totalbelopp
        </p>

        {store.stats.map((stat, index) => (
          <React.Fragment key={`${stat.full_name}-${index}`}>
            <p className="border-b border-grey left">{stat.full_name}</p>
            <p className="border-b border-grey">{stat.num_orders}</p>
            <p className="whitespace-nowrap uppercase border-b border-grey right">
              {(stat.sum / 100).toLocaleString("sv-SE", {
                minimumFractionDigits: 2,
              })}{" "}
              SEK
            </p>
          </React.Fragment>
        ))}

        <p className="my-1 left font-medium">Summa</p>
        <p className="my-1 font-medium">{store.totalOrders}</p>
        <p className="whitespace-nowrap my-1 uppercase font-medium right">
          {(store.totalSum / 100).toLocaleString("sv-SE", {
            minimumFractionDigits: 2,
          })}{" "}
          SEK
        </p>
      </div>
    </ToggleBox>
  );
});

const Home = observer(() => {
  const store = useLocalObservable(() => ({
    latestOrders: [],
    loadingOrders: false,
  }));

  useEffect(() => {
    const getLatestOrders = async () => {
      try {
        store.loadingOrders = true;
        const {
          data: { results: orders },
        } = await get("/orders?limit=5&order=-created_date");
        store.latestOrders = orders;
      } catch (error) {
        console.error(error.message);
      } finally {
        store.loadingOrders = false;
      }
    };
    getLatestOrders();
  }, []);

  return (
    <div className="flex flex-col xl:flex-row xl:flex-grow space-y-4 md:space-y-6 xl:space-y-0 xl:space-x-16">
      <div className="flex flex-grow w-full xl:w-1/2 flex-col">
        <ToggleBox title="Senaste offerterna" className="latest-offers">
          {store.latestOrders.length > 0 ? (
            <div className="w-full">
              {store.latestOrders.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="border-b border-grey last:border-b-0 py-4 pl-4 pr-2 md:px-7 md:pr-4 flex items-center w-full"
                  >
                    <div className="flex flex-col sm:flex-row sm:items-center w-full flex-grow">
                      <div className="w-full sm:w-32">
                        <label
                          className={`md:min-w-30 px-3 flex items-center justify-center py-1 capitalize rounded-full bg-${
                            getBadge(item.order_status).color
                          }`}
                        >
                          {getBadge(item.order_status).label}
                        </label>
                      </div>
                      <div className="flex items-center pt-4 sm:pt-0 text-grey-dark">
                        <div className="w-10 pl-4 md:pl-7">{item.id}</div>
                        <div className="flex-grow pl-4 md:pl-7">
                          {item.customer.type === "company"
                            ? item.customer.company_name
                            : item.customer.first_name +
                              " " +
                              item.customer.last_name}
                        </div>
                      </div>
                    </div>
                    <Link
                      className="flex items-center justify-end pl-4 md:pl-7"
                      to={`/offerter/${item.id}`}
                    >
                      <RightArrow className="h-10 p-3 rounded-full bg-transparent transition-colors hover:bg-blue-light hover:text-blue" />
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-full flex-grow p-4 md:p-7 text-grey-dark">
              Inga offerter har kommit in
            </div>
          )}
        </ToggleBox>
      </div>
      <div className="flex flex-grow space-y-4 md:space-y-6 xl:space-y-8 w-full xl:w-1/2 flex-col">
        <Statistics />
        <ToggleBox toggle={false} className="bg-grey-lighter flex-1">
          <span className="px-6 md:px-7 py-4 flex-grow flex-1"></span>
        </ToggleBox>
      </div>
    </div>
  );
});

export default Home;
