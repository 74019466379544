import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect } from "react";
import { get, put } from "../../../api";
import { Circle, Input, ToggleBox } from "../../../components";
import { Loading, Save } from "../../../icons";

const Account = observer(() => {
  const store = useLocalObservable(() => ({
    loadingMe: false,
    user: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      old_password: "",
      password: "",
      repeat_password: "",
    },
    errors: {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      password: null,
      repeat_password: null,
      old_password: null,
      general: null,
    },
    resetErrors() {
      this.errors = {
        first_name: null,
        phone: null,
        email: null,
        last_name: null,
        password: null,
        old_password: null,
        general: null,
      };
    },
    async updateMe(e) {
      e.preventDefault();
      this.loadingMe = true;
      this.errorMe = null;
      const first_name = this.user.first_name;
      const email = this.user.email;
      const phone = this.user.phone;
      const last_name = this.user.last_name;
      const password = this.user.password;
      const repeat_password = this.user.repeat_password;
      const old_password = this.user.old_password;

      store.resetErrors();

      if (password) {
        if (!password || password.length < 3 || !old_password) {
          store.errors.password = "Ogiltigt lösenord";
          store.errors.old_password = "Ogiltigt lösenord";
        } else if (!(password === repeat_password)) {
          store.password = "Lösenorden matchar inte";
        } else {
          await put("/users/me/change_password", {
            old_password,
            new_password: password,
          }).catch((err) => {
            store.errors.password = err.response.data.error;
          });
        }
      }

      if (!store.errors.password) {
        put("/users/me", {
          first_name,
          last_name,
          email,
          phone,
        })
          .then(() => {
            store.loadingMe = false;
          })
          .catch((err) => {
            store.errors.general = err.response.data.error;
            store.loadingMe = false;
          });
      } else {
        store.loadingMe = false;
      }
    },
    setUser(user) {
      this.user.first_name = user.first_name;
      this.user.last_name = user.last_name;
      this.user.email = user.email;
      this.user.phone = user.phone;
      this.user.id = user.id;
    },
  }));

  useEffect(() => {
    get("/users/me").then((response) => {
      store.setUser(response.data);
    });
  }, []);

  return (
    <form onSubmit={store.updateMe}>
      <ToggleBox title="Kontoinställningar" toggle={false}>
        <div className="flex flex-col p-4">
          <div className="flex flex-wrap w-full xl:max-w-2xl">
            <Input
              fieldname="Förnamn"
              type="text"
              className="xl:w-80 mt-3 edit"
              value={store.user.first_name}
              onChange={(e) => (store.user.first_name = e.target.value)}
              name="first_name"
              error={store.errors.first_name}
            />
            <Input
              fieldname="Efternamn"
              type="text"
              className="xl:w-80 mt-3 edit"
              value={store.user.last_name}
              onChange={(e) => (store.user.last_name = e.target.value)}
              name="last_name"
              error={store.errors.last_name}
            />
            <Input
              fieldname="E-post"
              type="email"
              className="xl:w-80 mt-3 edit"
              onChange={(e) => (store.user.email = e.target.value)}
              value={store.user.email}
              error={store.errors.email}
              name="email"
            />
            <Input
              fieldname="Telefon"
              type="tel"
              className="xl:w-80 mt-3 edit"
              onChange={(e) => (store.user.phone = e.target.value)}
              value={store.user.phone}
              error={store.errors.phone}
              name="phone"
            />
          </div>
          <div className="flex flex-wrap">
            <Input
              className="xl:w-80 mt-3 edit"
              autoComplete="on"
              error={store.errors.old_password}
              fieldname="Nuvarande lösenord"
              type="password"
              onChange={(e) => (store.user.old_password = e.target.value)}
              value={store.user.old_password}
              placeholder="************"
              name="old_password"
            />
            <Input
              fieldname="Nytt lösenord"
              type="password"
              className="xl:w-80 mt-3 edit"
              onChange={(e) => (store.user.password = e.target.value)}
              value={store.user.password}
              error={store.errors.password}
              placeholder="************"
              name="password"
            />
            <Input
              fieldname="Repetera nytt lösenord"
              type="password"
              placeholder="************"
              className="xl:w-80 mt-3 edit"
              name="repeat_password"
              error={store.errors.password}
              onChange={(e) => (store.user.repeat_password = e.target.value)}
              value={store.user.repeat_password}
            />

            {store.errors.general && (
              <div className="form-error mt-6">{store.errors.general}</div>
            )}
          </div>
        </div>
      </ToggleBox>
      <div className="mt-6 mr-auto">
        {!store.loadingMe ? (
          <Circle type="button" value="submit">
            <Save />
          </Circle>
        ) : (
          <Circle type="button" onClick={() => {}}>
            <Loading />
          </Circle>
        )}
      </div>
    </form>
  );
});

export default Account;
