import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Circle, Input, Select } from "../../../components";
import {
  productTypes,
  PRODUCT_TYPE_AREA,
  RITVERKTYG_URL,
} from "../../../constants";
import { AddCircle, MinusCircle, Cross } from "../../../icons";

export const EditHeader = ({ onClick, cross, actionText }) => {
  return (
    <div className="flex items-center flex-grow justify-end">
      <Circle type="button" actionText={actionText} onClick={onClick}>
        {cross ? <Cross /> : <MinusCircle />}
      </Circle>
    </div>
  );
};

export const CustomerHeader = observer(({ setSearchTerm }) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <div className="w-full md:w-auto flex flex-row-reverse flex-wrap-reverse md:flex-row md:flex-wrap items-center md:justify-end flex-grow lg:space-x-2 pb-0">
      <Input
        placeholder="Sök efter en kund"
        name="customer-search"
        type="search"
        className="-mb-2 md:-mb-0 md:mb-4"
        onKeyDown={handleKeyDown}
      />
      <Circle
        actionText="Ny kund"
        type="link"
        className="mb-4"
        link="/add-customer"
      >
        <AddCircle />
      </Circle>
    </div>
  );
});

export const OrdersHeader = ({ onSubmit }) => (
  <div className="flex flex-1 items-center lg:space-x-6 ml-auto flex-wrap-reverse md:flex-nowrap justify-end">
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e.target.search.value);
      }}
      className="w-full md:w-auto"
    >
      <Input
        onChange={(e) => {
          if (e.target.value === "") {
            onSubmit("");
          }
        }}
        placeholder="Sök efter en offert/order"
        type="search"
        name="search"
        className="pr-0 w-full md:w-full md:pr-4 xl:w-80 mb-4 md:mb-0"
      />
    </form>
    <Circle
      actionText="Ny offert"
      type="anchor"
      link={RITVERKTYG_URL}
      className="mb-4 md:mb-0"
    >
      <AddCircle />
    </Circle>
  </div>
);

export const AddCustomerHeader = () => (
  <div className="flex flex-grow justify-end items-center space-x-6">
    <Circle actionText="Avbryt" type="link" link="/customers">
      <MinusCircle />
    </Circle>
  </div>
);

export const ProductsHeader = observer(
  ({
    onChange,
    showProductGroups,
    activeProductType,
    onClick,
    initialProduct,
  }) => {
    useEffect(() => {
      if (initialProduct) {
        const product = productTypes.find(
          (prod) => prod.value === initialProduct
        );
        if (product) {
          onChange(product);
        }
      }
    }, [initialProduct]);
    return (
      <div
        className={`flex mt-4 md:my-0 w-full md:w-auto md:flex-grow flex-wrap md:flex-wrap-none md:justify-end 
        ${
          activeProductType &&
          activeProductType.value === PRODUCT_TYPE_AREA &&
          "lg:mt-4 xl:mt-0"
        } `}
      >
        {activeProductType && activeProductType.value === PRODUCT_TYPE_AREA && (
          <button
            className={`button aligned mb-4 w-full md:w-auto md:mb-0
              ${showProductGroups ? "active" : ""} 
              `}
            onClick={onClick}
          >
            <p className={`${showProductGroups ? "text-blue" : ""}`}>
              Prisgrupper
            </p>
          </button>
        )}
        <Select
          className="flex-grow md:flex-grow-0 md:ml-7 lg:flex-grow xl:flex-grow-0 header"
          onChange={(val) => {
            showProductGroups = false;
            onChange(val);
          }}
          initialItem={
            initialProduct
              ? productTypes.find((prod) => prod.value === initialProduct)
                ? productTypes.find((prod) => prod.value === initialProduct)
                    .value
                : null
              : null
          }
          items={productTypes}
        />
        <Circle className="my-1 ml-4 md:ml-7" type="link" link="/add-product">
          <AddCircle />
        </Circle>
      </div>
    );
  }
);

export const AddProductHeader = () => (
  <div className="flex my-4 lg:my-0 items-center flex-grow justify-end pl-4 md:pl-7">
    <span className="sm:hidden">
      <Circle type="link" link="/produkter">
        <MinusCircle />
      </Circle>
    </span>
    <span className="hidden sm:flex">
      <Circle actionText="Avbryt" type="link" link="/produkter">
        <MinusCircle />
      </Circle>
    </span>
  </div>
);
