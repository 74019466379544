import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { Circle } from "../../../components";
import {
  AddCircle,
  Cross,
  Exit,
  Person,
  Menu as MenuIcon,
  Settings as SettingsIcon,
} from "../../../icons";
import { logout } from "../../../utils";
import GardeLogoGrey from "../assets/logo-grey-garderobsfabriken.png";
import { store as globalStore } from "../../../store";
import { RITVERKTYG_URL } from "../../../constants";

const Menu = ({ onClick, isMobile }) => (
  <>
    <Link
      onClick={isMobile ? onClick : null}
      className="text-grey-dark option"
      to="/"
    >
      <p className="lg:tracking-wide lg:font-medium text-base text-grey-dark lg:border-b-0 hover:text-blue">
        Översikt
      </p>
    </Link>
    <Link
      onClick={isMobile ? onClick : null}
      className="text-grey-dark option"
      to="/offerter"
    >
      <p className="lg:tracking-wide lg:font-medium text-base text-grey-dark lg:border-b-0 hover:text-blue">
        Offerter & ordrar
      </p>
    </Link>
    <Link
      onClick={isMobile ? onClick : null}
      className="text-grey-dark option"
      to="/produkter"
    >
      <p className="lg:tracking-wide lg:font-medium text-base text-grey-dark hover:text-blue">
        Produkter
      </p>
    </Link>
  </>
);

export const Sidebar = observer(() => {
  const store = useLocalObservable(() => ({
    showAddMenu: false,
    showMenu: false,
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
    },
  }));
  return (
    <div className="menu lg:w-72 lg:mx-8 flex justify-center font-mont relative z-20">
      <div className="max-w-md lg:max-w-none relative flex lg:fixed flex-col items-center lg:h-screen py-0 lg:py-8 w-full lg:w-72">
        <div className="hidden w-full lg:flex flex-col lg:h-32 justify-end bg-white rounded-3xl p-8">
          <p className="font-semibold text-2xl text-grey-dark">
            {globalStore.activeUser
              ? globalStore.activeUser.first_name +
                " " +
                globalStore.activeUser.last_name
              : ""}
          </p>
          <p className="uppercase text-base tracking-wider font-roboto">
            {globalStore.activeUser ? globalStore.activeUser.role : ""}
          </p>
        </div>
        <div
          className={`button-menu w-full flex z-30 justify-between md:flex-col lg:flex-row my-5 px-4 md:pr-0 lg:pr-4 ${
            store.showAddMenu || store.showMenu
              ? "sticky top-4 left-0 lg:relative lg:top-0"
              : ""
          }`}
        >
          <Circle
            type="button"
            onClick={() => store.toggleShowMenu()}
            className={`block lg:hidden relative ${store.showMenu && "active"}`}
          >
            {store.showMenu ? <Cross /> : <MenuIcon />}
          </Circle>
          {store.showMenu && (
            <>
              <div className="options-container">
                <div className="options-menu font-mont w-full absolute mt-20 ml-4 z-30 md:mt-5 md:ml-0">
                  <Menu isMobile onClick={() => store.toggleShowMenu()} />
                </div>
              </div>
            </>
          )}
          <Circle
            className={`lg:relative ${store.showAddMenu && "active"}`}
            type="button"
            onClick={() => (store.showAddMenu = !store.showAddMenu)}
          >
            {!store.showAddMenu ? <AddCircle /> : <Cross />}
          </Circle>
          {store.showAddMenu && (
            <div className="options-container">
              <div className="options-menu font-roboto absolute ml-4 lg:w-72 left-0 mt-22 lg:ml-0 lg:mt-5 z-30">
                <a href={RITVERKTYG_URL} className="option pt-5 rounded-t-2xl">
                  Lägg till ny offert
                </a>
                <Link
                  to="/add-product"
                  className="option"
                  onClick={() => (store.showAddMenu = !store.showAddMenu)}
                >
                  Lägg till ny produkt
                </Link>
                <Link
                  to="/add-customer"
                  className="option"
                  onClick={() => (store.showAddMenu = !store.showAddMenu)}
                >
                  Lägg till ny kund
                </Link>
                <Link
                  to="/add-user"
                  className="option"
                  onClick={() => (store.showAddMenu = !store.showAddMenu)}
                >
                  Lägg till ny användare
                </Link>
              </div>
            </div>
          )}
          <Circle type="link" to="/customers">
            <Person />
          </Circle>
          <Circle link="/settings">
            <SettingsIcon />
          </Circle>
          <Circle
            className="flex justify-center items-center"
            type="button"
            onClick={logout}
          >
            <Exit />
          </Circle>
        </div>
        <div className="hidden lg:flex flex-col w-full flex-1 bg-white rounded-3xl p-8 py-16 relative">
          <div className="flex flex-col space-y-6">
            <Menu onClick={() => store.toggleShowMenu()} />
          </div>
          <img
            src={GardeLogoGrey}
            className="absolute bottom-0 h-8 self-center mb-8"
          />
        </div>
      </div>
    </div>
  );
});
