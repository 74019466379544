import React, { useEffect, useRef } from "react";
import { observer, useLocalObservable } from "mobx-react";
import { Input, Circle, ToggleBox } from "../../../components";
import {
  Checkmark,
  Upload,
  CheckMarkNoBorder,
  MinusCircle,
} from "../../../icons";
import { useNavigate, useParams } from "react-router-dom";
import { get, put, postMedia } from "../../../api";

const CUSTOMER_TYPE_COMPANY = "company";
const CUSTOMER_TYPE_PRIVATE = "person";

const EditCustomer = observer(({ type }) => {
  const params = useParams();
  const navigate = useNavigate();

  const { customerId } = params;

  if (!type) {
    type = params.type;
  }

  const store = useLocalObservable(() => ({
    loading: true,
    file: null,
    error: null,
    customer: {
      type: "",
      orgnumber: "",
      company: "",
      name: "",
      last_name: "",
      note: "",
      agreement: "",
      agreement_url: "",
      email: "",
      phone: "",
      delivery_address: "",
      delivery_zipcode: "",
      delivery_city: "",
      billing_address: "",
      billing_zipcode: "",
      billing_city: "",
      file: { name: "" },
    },
    handleUpload(e) {
      const file = e.target.files[0];
      if (file) {
        store.customer.agreement_url = file;
      }
    },
    async saveCustomer(e) {
      e.preventDefault();
      let data = {};
      if (store.customer.type === "person") {
        data = {
          first_name: store.customer.first_name,
          last_name: store.customer.last_name,
          email: store.customer.email,
          contact_phone: store.customer.contact_phone,
          note: store.customer.note,
          delivery_address: store.customer.delivery_address,
          delivery_zipcode: store.customer.delivery_zipcode,
          delivery_city: store.customer.delivery_city,
          billing_address: store.copyBillingAdress
            ? store.customer.delivery_address
            : store.customer.billing_address,
          billing_zipcode: store.copyBillingAdress
            ? store.customer.delivery_zipcode
            : store.customer.billing_zipcode,
          billing_city: store.copyBillingAdress
            ? store.customer.delivery_city
            : store.customer.billing_city,
        };
      } else {
        data = {
          contact_first_name: store.customer.contact_first_name,
          contact_last_name: store.customer.contact_last_name,
          contact_email: store.customer.contact_email,
          contact_phone: store.customer.contact_phone,
          agreement: store.customer.agreement,
          agreement_url: store.customer.agreement_url,
          company_name: store.customer.company_name,
          billing_address: store.customer.billing_address,
          billing_zipcode: store.customer.billing_zipcode,
          billing_city: store.customer.billing_city,
        };
      }

      try {
        if (data.agreement_url instanceof File) {
          const res = await postMedia(data.agreement_url);
          data.agreement_url = res.data.Path;
        }

        await put(`/customers/${store.customer.id}`, data);
        navigate(-1);
      } catch (error) {
        store.error.general = "Något gick fel, försök igen";
      }
    },
    copyBillingAdress: false,
  }));
  const ref = useRef();

  useEffect(() => {
    get(`/customers/${customerId}`).then((response) => {
      store.customer = Object.assign(store.customer, response.data);
      store.loading = false;
    });
  }, []);

  if (store.loading) {
    return <p>Hämtar kunddata.</p>;
  }

  return (
    <div className="flex flex-col">
      {type === CUSTOMER_TYPE_COMPANY && (
        <form
          onSubmit={store.saveCustomer}
          className="flex mt-4 md:mt-7 flex-col"
        >
          <ToggleBox toggle={false} title="Företagsinformation">
            <div className="flex p-4 md:py-7">
              <div className="w-full flex flex-col xl:flex-row">
                <div className="flex flex-col xl:w-2/3">
                  <div className="flex flex-col md:flex-row md:flex-wrap w-full">
                    <Input
                      className="disabled"
                      value={store.customer.customer_number}
                      name="customer-number"
                      fieldname="Kundnummer"
                      disabled
                    />
                    <Input
                      value={store.customer.company_name}
                      onChange={(event) =>
                        (store.customer.company_name = event.target.value)
                      }
                      className="xl:w-1/2"
                      name="company"
                      fieldname="Företagsnamn"
                    />
                    <Input
                      value={store.customer.organization_number}
                      onChange={(event) =>
                        (store.customer.organization_number =
                          event.target.value)
                      }
                      name="orgnumber"
                      fieldname="Organisationsnummer"
                    />
                    <div className="flex flex-col w-full xl:w-1/2">
                      <Input
                        className="w-full md:w-full"
                        value={store.customer.billing_address}
                        onChange={(event) =>
                          (store.customer.billing_address = event.target.value)
                        }
                        placeholder="Gata"
                        fieldname="Faktureringsadress"
                      />
                      <div className="flex flex-col sm:flex-row w-full">
                        <Input
                          className="w-full sm:w-1/3 md:w-1/3 xl:w-1/3 md:pr-0 md:min-w-0"
                          value={store.customer.billing_zipcode}
                          onChange={(event) =>
                            (store.customer.billing_zipcode =
                              event.target.value)
                          }
                          name="zip"
                          placeholder="123 45"
                        />
                        <Input
                          className="w-full sm:w-2/3 md:w-2/3 xl:w-2/3 sm:pl-4 md:min-w-0"
                          value={store.customer.billing_city}
                          onChange={(event) =>
                            (store.customer.billing_city = event.target.value)
                          }
                          name="city"
                          placeholder="Ort"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mb-4 md:px-3 xl:w-1/3">
                  <p className="font-medium mb-2">Avtal</p>
                  <div
                    className={`rounded-2xl p-0 w-full ${
                      store.error ? "border border-orange-light" : ""
                    }`}
                  >
                    <textarea
                      className={`w-full mr-4 ${store.error ? "error" : ""}`}
                      value={store.customer.agreement || ""}
                      onChange={(event) =>
                        (store.customer.agreement = event.target.value)
                      }
                    />
                    {store.error && (
                      <p className="p-4 text-orange">{store.error}</p>
                    )}
                  </div>
                  <div className="flex flex-wrap items-center mt-4">
                    <input
                      onChange={store.handleUpload}
                      type="file"
                      accept="application/pdf"
                      name="file"
                      ref={ref}
                      className="hidden"
                    />
                    <Circle
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        ref.current.click();
                      }}
                      className="mr-4 mb-2"
                    >
                      <Upload />
                    </Circle>
                    {store.customer.agreement_url && (
                      <Circle
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (confirm(`Ta bort avtalet?`)) {
                            store.customer.agreement_url = null;
                          }
                        }}
                        className="mr-4 mb-2"
                      >
                        <MinusCircle />
                      </Circle>
                    )}
                    <p>
                      {store.customer.agreement_url
                        ? typeof store.customer.agreement_url === "object"
                          ? store.customer.agreement_url.name
                          : store.customer.agreement_url.split("/")[2]
                        : "Ladda upp en kopia av avtalet"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ToggleBox>
          <div className="flex mt-4 md:mt-7 flex-col">
            <ToggleBox toggle={false} title="Kontaktperson">
              <div className="flex p-4 pb-0 md:py-7 md:pb-4 flex-col md:flex-row md:flex-wrap xl:w-2/3">
                <Input
                  value={store.customer.contact_first_name}
                  onChange={(event) =>
                    (store.customer.contact_first_name = event.target.value)
                  }
                  className="md:w-1/2"
                  name="name"
                  fieldname="Förnamn"
                  placeholder="Namn"
                />
                <Input
                  value={store.customer.contact_last_name}
                  onChange={(event) =>
                    (store.customer.contact_last_name = event.target.value)
                  }
                  className="md:w-1/2"
                  name="last_name"
                  fieldname="Efternamn"
                  placeholder="Efternamn"
                />
                <Input
                  value={store.customer.contact_email}
                  onChange={(event) =>
                    (store.customer.contact_email = event.target.value)
                  }
                  className="md:w-1/2"
                  name="email"
                  type="email"
                  fieldname="E-post"
                  placeholder="namn@foretag.se"
                />
                <Input
                  value={store.customer.contact_phone}
                  onChange={(event) =>
                    (store.customer.contact_phone = event.target.value)
                  }
                  className="md:w-1/2"
                  name="phone"
                  type="tel"
                  fieldname="Telefon"
                  placeholder="0700000000"
                />
              </div>
            </ToggleBox>
          </div>
          <div className="flex mt-6 items-center">
            <Circle actionText="Spara" type="button" value="submit">
              <Checkmark />
            </Circle>
          </div>
        </form>
      )}

      {type === CUSTOMER_TYPE_PRIVATE && (
        <form
          onSubmit={store.saveCustomer}
          className="flex mt-4 md:mt-7 flex-col"
        >
          <ToggleBox title="Kundinformation" toggle={false}>
            <div className="flex p-4 md:py-7 flex-wrap">
              <div className="w-full md:w-1/2 xl:w-1/3">
                <Input
                  className="disabled md:w-full"
                  value={store.customer.customer_number}
                  name="customer-number"
                  fieldname="Kundnummer"
                  disabled
                />
              </div>
              <div className="flex flex-wrap xl:flex-row flex-col items-start">
                <div className="flex w-full flex-wrap xl:w-2/3">
                  <Input
                    value={store.customer.first_name}
                    onChange={(event) => {
                      store.customer.first_name = event.target.value;
                    }}
                    name="surname"
                    fieldname="Förnamn"
                    placeholder="Förnamn"
                  />
                  <Input
                    value={store.customer.last_name}
                    onChange={(event) =>
                      (store.customer.last_name = event.target.value)
                    }
                    name="last_name"
                    fieldname="Efternamn"
                    placeholder="Efternamn"
                  />
                  <Input
                    value={store.customer.email}
                    onChange={(event) => {
                      store.customer.email = event.target.value;
                    }}
                    name="email"
                    fieldname="E-post"
                    type="email"
                    placeholder="namn@example.se"
                  />
                  <Input
                    value={store.customer.contact_phone}
                    onChange={(event) => {
                      store.customer.contact_phone = event.target.value;
                    }}
                    name="phone"
                    fieldname="Telefon"
                    type="tel"
                    placeholder="0700000000"
                  />
                  <div className="w-full md:px-3">
                    <p className="font-medium">Kommentar</p>
                    <div
                      className={`rounded-2xl mt-2 p-0 w-full ${
                        store.error ? "border border-orange-light" : ""
                      }`}
                    >
                      <textarea
                        value={store.customer.note}
                        onChange={(event) =>
                          (store.customer.note = event.target.value)
                        }
                        placeholder="Kommentar..."
                        className={`w-full mr-4 ${store.error ? "error" : ""}`}
                      />
                      {store.error && (
                        <p className="p-4 text-orange">{store.error}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap w-full xl:w-1/3">
                  <div className="flex flex-wrap w-full flex-col mt-4 xl:mt-0">
                    <Input
                      value={store.customer.delivery_address}
                      onChange={(event) => {
                        store.customer.delivery_address = event.target.value;
                      }}
                      name="delivery_address"
                      className="w-full md:w-full"
                      placeholder="Gata"
                      fieldname="Leveransaddress"
                    />
                    <div className="flex flex-col w-full sm:flex-row">
                      <Input
                        value={store.customer.delivery_zipcode}
                        onChange={(event) => {
                          store.customer.delivery_zipcode = event.target.value;
                        }}
                        className="flex flex-col sm:w-1/3 md:w-1/3 md:pr-0 md:min-w-0"
                        name="delivery_zipcode"
                        placeholder="123 45"
                      />
                      <Input
                        value={store.customer.delivery_city}
                        onChange={(event) => {
                          store.customer.delivery_city = event.target.value;
                        }}
                        className="flex flex-col sm:w-2/3 md:w-2/3 sm:pl-4 pr-0 md:min-w-0"
                        name="delivery_city"
                        placeholder="Ort"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap w-full flex-col mt-4">
                    <Input
                      onChange={(event) => {
                        store.customer.billing_address = event.target.value;
                      }}
                      value={
                        store.copyBillingAdress
                          ? store.customer.delivery_address
                          : store.customer.billing_address
                      }
                      disabled={store.copyBillingAdress}
                      name="billing_address"
                      className="w-full md:w-full"
                      placeholder="Gata"
                      fieldname="Faktureringsadress"
                    />
                    <div className="flex flex-col sm:flex-row w-full">
                      <Input
                        onChange={(event) => {
                          store.customer.billing_zipcode = event.target.value;
                        }}
                        value={
                          store.copyBillingAdress
                            ? store.customer.delivery_zipcode
                            : store.customer.billing_zipcode
                        }
                        disabled={store.copyBillingAdress}
                        className="flex flex-col sm:w-1/3 md:w-1/3 md:pr-0 md:min-w-0"
                        name="billing_zipcode"
                        placeholder="123 45"
                      />
                      <Input
                        onChange={(event) => {
                          store.customer.billing_city = event.target.value;
                        }}
                        value={
                          store.copyBillingAdress
                            ? store.customer.delivery_city
                            : store.customer.billing_city
                        }
                        disabled={store.copyBillingAdress}
                        className="flex flex-col sm:w-2/3 md:w-2/3 sm:pl-4 pr-0 md:min-w-0"
                        name="billing_city"
                        placeholder="Ort"
                      />
                    </div>
                    <div className="flex items-center md:px-3">
                      <button
                        className={`checkbox ${
                          store.copyBillingAdress ? "selected" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          store.copyBillingAdress = !store.copyBillingAdress;
                        }}
                      >
                        <CheckMarkNoBorder />
                      </button>
                      <p className="ml-4">Samma som leveransadress</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ToggleBox>
          <div className="flex mt-4 md:mt-7 items-center">
            <Circle actionText="Spara" type="button" value="submit">
              <Checkmark />
            </Circle>
          </div>
        </form>
      )}
    </div>
  );
});

export default EditCustomer;
