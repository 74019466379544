import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { API_URL } from "../../../constants";
import Profile from "./Profile";
import Bars from "./Bars";

const addedSize = 5;
const fieldLayouts = {
  1: ["h-full"],
  2: ["h-1/2", "h-1/2"],
  3: ["h-1/3", "h-1/3", "h-1/3"],
  4: ["h-1/4", "h-1/4", "h-1/2"],
  5: ["h-1/4", "h-1/4", "h-1/4", "h-1/4"],
  6: ["h-1/5", "h-3/5", "h-1/5"],
  7: ["flex-1", "h-1/10", "flex-1"],
  8: ["h-3/5", "h-1/5", "h-1/5"],
};
const trackConfig = {
  2: {
    2: [2, 1],
  },
  3: {
    2: [1, 2, 1],
    3: [3, 2, 1],
  },
  4: {
    2: [2, 1, 2, 1],
  },
  5: {
    2: [2, 1, 2, 1, 2],
    3: [1, 3, 2, 3, 1],
  },
  6: {
    2: [2, 1, 2, 1, 2, 1],
    3: [1, 2, 3, 2, 1, 2],
  },
};
const doorTrackStyles = {
  1: {
    zIndex: 10,
  },
  2: {
    zIndex: 20,
  },
  3: {
    zIndex: 30,
  },
};

const Door = observer(
  ({ door, index, doorAmount, railCount, profileColor, profileType }) => {
    const doorStyling = Object.assign(
      {
        width: `calc(${100 / doorAmount}% + ${addedSize}px)`,
        left:
          index !== doorAmount - 1
            ? `calc(${(100 / doorAmount) * index}% - ${
                index !== 0 ? addedSize / 2 : 0
              }px)`
            : null,
        right: index === doorAmount - 1 ? 0 : null,
        background: null,
      },
      doorTrackStyles[trackConfig[doorAmount][railCount][index]]
    );

    return (
      <div
        className={`door-track-${trackConfig[doorAmount][railCount][index]} absolute flex flex-col h-full z-10 shadow-door`}
        style={doorStyling}
      >
        {profileColor && (
          <Profile profileType={profileType} profileColor={profileColor} />
        )}
        {door.sections.map((field, i) => {
          return (
            <div
              key={i}
              className={`flex justify-center items-center relative ${
                fieldLayouts[door.layout_id][i]
              }`}
            >
              <div
                className="absolute top-0 right-0 bottom-0 left-0"
                style={{
                  backgroundColor: field.area_color.color,
                  backgroundImage: `url(${API_URL + field.area_color.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              ></div>
              {door.sections.length - 1 !== i && (
                <Bars profileType={profileType} profileColor={profileColor} />
              )}
            </div>
          );
        })}
      </div>
    );
  }
);

Door.propTypes = {
  doorAmount: PropTypes.number.isRequired,
  door: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  index: PropTypes.number.isRequired,
  profileColor: PropTypes.string.isRequired,
  railCount: PropTypes.number.isRequired,
};

export default Door;
