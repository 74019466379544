import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddCircle,
  MinusCircle,
  Pencil,
  RightArrow,
  Eye,
} from "../../../icons";
import { store as globalStore } from "../../../store";
import { ToggleBox, Circle } from "../../../components";
import { deleteRequest, get, post } from "../../../api";
import { observer, useLocalObservable } from "mobx-react";
import { API_URL, RITVERKTYG_URL } from "../../../constants";
import { Mail, Phone } from "../../../icons";

const Field = ({ fieldname, value, className, textarea, type, ...props }) => (
  <div className={className || "flex flex-col w-full md:w-1/2 mt-4 px-4"}>
    {fieldname && (
      <p className="text-base text-grey-dark font-medium">{fieldname}</p>
    )}
    <div
      className={`field relative flex w-full z-10 text-grey-dark rounded-2xl border border-grey-light py-4 px-6 mr-4 mt-2 overflow-x-auto
      ${textarea ? "min-h-44 lg:h-40" : ""}${
        type ? " field-type-" + type : ""
      }`}
      {...props}
    >
      {type === "email" && <Mail className="input-icon" />}
      {type === "tel" && <Phone className="input-icon" />}
      <p className="">{value ? value : <>&nbsp;</>}</p>
    </div>
  </div>
);

const SingleCustomerComponent = ({ type, customer }) => (
  <div className="flex flex-col">
    {type === "person" && (
      <div className="flex md:mt-7 flex-col">
        <ToggleBox title="Kundinformation" toggle={false}>
          <div className="md:px-3 md:pt-3 overflow-x-hidden">
            <Field
              className="w-auto field flex flex-col mt-4 px-4 sm:w-1/2 xl:w-1/3"
              fieldname="Kundnummer"
              value={customer.customer_number}
            />
          </div>
          <div className="flex flex-col xl:flex-row pb-4 md:pb-7 md:px-3">
            <div className="flex flex-col w-full xl:w-2/3">
              <div className="flex flex-col md:flex-row w-full">
                <Field fieldname="Förnamn" value={customer.first_name} />
                <Field fieldname="Efternamn" value={customer.last_name} />
              </div>
              <div className="flex flex-col md:flex-row w-full">
                <Field value={customer.email} type="email" fieldname="E-post" />
                <Field
                  value={customer.contact_phone}
                  type="tel"
                  fieldname="Telefon"
                />
              </div>
              <Field
                value={customer.note}
                fieldname="Kommentar"
                textarea
                className="w-full mt-4 px-4"
              />
            </div>
            <div className="flex w-full flex-col px-4 xl:w-1/3">
              <div className="flex flex-wrap flex-col">
                <Field
                  value={customer.delivery_address}
                  fieldname="Leveransadress"
                  className="w-full mt-4"
                />
                <div className="w-full flex flex-col sm:flex-row sm:mt-3">
                  <Field
                    value={customer.delivery_zipcode}
                    className="sm:w-1/3"
                  />
                  <Field
                    value={customer.delivery_city}
                    className="sm:w-2/3 sm:pl-4"
                  />
                </div>
              </div>
              <div className="w-full flex flex-wrap flex-col">
                <Field
                  value={customer.billing_address}
                  fieldname="Faktureringsadress"
                  className="w-full mt-4"
                />
                <div className="flex flex-col sm:flex-row sm:mt-3">
                  <Field
                    value={customer.billing_zipcode}
                    className="sm:w-1/3"
                  />
                  <Field
                    value={customer.billing_city}
                    className="sm:w-2/3 sm:pl-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </ToggleBox>
      </div>
    )}
    {type === "company" && (
      <div className="flex md:mt-7 flex-col">
        <ToggleBox title="Företagsinformation" toggle={false}>
          <div className="flex flex-col flex-wrap md:p-3">
            <div className="flex flex-col xl:flex-row">
              <div className="w-full flex-wrap flex xl:w-2/3">
                <Field
                  fieldname="Kundnummer"
                  value={customer.customer_number}
                />
                <Field fieldname="Företagsnamn" value={customer.company_name} />
                <Field
                  fieldname="Organisationsnummer"
                  value={customer.organization_number}
                />
                <div className="flex flex-wrap w-full lg:w-1/2 flex-col px-4 mt-4">
                  <Field
                    value={customer.billing_address}
                    fieldname="Leveransadress"
                    className="w-full"
                  />
                  <div className="flex flex-col sm:flex-row">
                    <Field
                      value={customer.billing_zipcode}
                      className="w-full sm:w-1/3"
                    />
                    <Field
                      value={customer.billing_city}
                      className="w-full sm:w-2/3 sm:pl-4"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full px-4 mt-4 xl:w-1/3">
                <Field
                  fieldname="Avtal"
                  className="w-full"
                  value={customer.agreement}
                />
                {customer.agreement_url && (
                  <div className="flex items-center mt-4">
                    <a
                      href={API_URL + customer.agreement_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Circle type="button">
                        <Eye />
                      </Circle>
                    </a>
                    <p className="ml-6 font-medium">
                      {customer.agreement_url.split("/")[2]}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <h3 className="px-4 mt-7 mb-2 md:px-7 font-medium">Kontaktperson</h3>
          <div className="border-t border-grey pb-4 md:p-3 md:pb-7">
            <div className="w-full flex-wrap flex xl:w-2/3">
              <Field value={customer.contact_first_name} fieldname="Förnamn" />
              <Field fieldname="Efternamn" value={customer.contact_last_name} />
              <Field
                value={customer.contact_email}
                fieldname="E-post"
                type="email"
              />
              <Field
                value={customer.contact_phone}
                fieldname="Telefon"
                type="tel"
              />
            </div>
          </div>
        </ToggleBox>
      </div>
    )}
  </div>
);

const SingleCustomer = observer(() => {
  const { type, customerId } = useParams();
  const navigate = useNavigate();
  const types = {
    person: "Privat",
    company: "Företag",
  };
  const store = useLocalObservable(() => ({
    customer: { name: "" },
  }));

  const deleteCustomer = async () => {
    try {
      await deleteRequest(`/customers/${customerId}`);

      navigate("/customers");
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    get(`/customers/${customerId}`).then((response) => {
      store.customer = response.data;
    });
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-between items-center flex-wrap">
        <div className="flex flex-wrap items-center pb-4 self-start">
          <h1 className="capitalize">{types[type] ? types[type] : type}</h1>
          <RightArrow className="h-6 ml-6 mr-7 text-grey-dark" />
          <p className="text-3xl font-light">
            {type === "company"
              ? store.customer.company_name
              : store.customer.first_name + " " + store.customer.last_name}
          </p>
        </div>
        <div className="flex space-x-4 mb-4 md:mb-0">
          <Circle link="edit" type="link" title="Redigera kund">
            <Pencil />
          </Circle>

          <Circle
            type="button"
            onClick={async () => {
              const {
                data: { global_id },
              } = await post("/orders", {
                customer: {
                  first_name: store.customer.first_name,
                  last_name: store.customer.last_name,
                  email: store.customer.email,
                  contact_phone: store.customer.contact_phone,
                  billing_address: store.customer.billing_address,
                  billing_zipcode: store.customer.billing_zipcode,
                  billing_city: store.customer.billing_city,
                  delivery_address: store.customer.delivery_address,
                  delivery_zipcode: store.customer.delivery_zipcode,
                  delivery_city: store.customer.delivery_city,
                  type: store.customer.type,
                },
                seller_id: globalStore.activeUser.id,
                order_status: "drawing_in_progress",
                wardrobe: {},
              });
              window.location.assign(`${RITVERKTYG_URL}/?id=${global_id}`);
            }}
            title="Ny offert med kund"
          >
            <AddCircle />
          </Circle>

          <Circle
            title="Radera kund"
            type="button"
            onClick={() => {
              if (confirm(`Ta bort kund #${customerId}`)) {
                deleteCustomer();
              }
            }}
          >
            <MinusCircle />
          </Circle>
        </div>
      </div>
      <SingleCustomerComponent customer={store.customer} type={type} />
    </div>
  );
});

export default SingleCustomer;
