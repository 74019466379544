import React from "react";
import "./wardrobe.less";
import Door from "./Door";
import WardrobeSection from "./WardrobeSection";
import WardrobePartition from "./WardrobePartition";
import WardrobeClothesRail from "./WardrobeClothesRail";
import WardrobeTopshelf from "./WardrobeTopshelf";
import Light from "./Light";
import Measurement from "./Measurement";
import {
  BodyColors,
  DetailColors,
  InteriorWardrobeMaxHeight,
} from "../../../constants";

const Wardrobe = ({
  wardrobe,
  profileType,
  download,
  interior = false,
  handleColor,
}) => {
  // Math to handle depth logic
  let depthMath = wardrobe.depth >= 600 ? 1 + (wardrobe.depth - 600) / 10 : 0;
  if (depthMath > 21) {
    depthMath = 21;
  }

  const bodyColor =
    BodyColors.find((bodyColor) => bodyColor.value === wardrobe.body_color) ||
    null;
  const detailColor =
    DetailColors.find(
      (detailColor) => detailColor.value === wardrobe.detail_color
    ) || null;

  const shouldRenderDoors = !interior;
  const shouldRenderSections = interior;

  const dimensions = {
    width: {
      min: 800,
      max: wardrobe.wardrobe_type === "only_interior" ? 6000 : 4800,
    },
    height: {
      min:
        wardrobe.wardrobe_type === "only_doors"
          ? 800
          : InteriorWardrobeMaxHeight,
      max: 2540,
    },
    depth: {
      min: wardrobe.wardrobe_type === "only_interior" ? 505 : 600,
      max: 800,
    },
  };

  const minWidth = dimensions.width.min;
  const maxWidth = dimensions.width.max;
  const cappedWidth = wardrobe.width
    ? Math.min(Math.max(minWidth, wardrobe.width), maxWidth)
    : (maxWidth + minWidth) / 2;

  const minHeight = dimensions.height.min;
  const maxHeight = dimensions.height.max;
  const cappedHeight = wardrobe.height
    ? Math.min(Math.max(minHeight, wardrobe.height), maxHeight)
    : (maxHeight + minHeight) / 2;

  const ratio = cappedHeight / cappedWidth;

  return (
    <div className="w-full relative">
      <div
        className="wardrobe-container"
        style={{
          paddingTop: ratio < 1 ? `${ratio * 100}%` : "100%",
        }}
      >
        <div className="absolute inset-0">
          <div className="w-full h-full flex items-center relative">
            <div
              className="room-wrapper absolute right-0 bottom-0 left-0"
              style={{
                transform: `perspective(440px) translateZ(-${depthMath}px)`,
              }}
            >
              <div className="room">
                <div className="room__face room__face--front">
                  {wardrobe.placement && (
                    <div
                      className={`wardrobe-wrapper placement-${wardrobe.placement} transition-opacity duration-200`}
                    >
                      <div className="wardrobe">
                        <div
                          className="wardrobe__face wardrobe__face--front"
                          style={{
                            borderColor: shouldRenderDoors
                              ? "transparent"
                              : null,
                            transform: `rotateY(0deg) translateZ(${
                              25 + depthMath
                            }px)`,
                          }}
                        >
                          {interior && download && (
                            <>
                              <Measurement size={wardrobe.width} type="top" />
                              <Measurement
                                size={wardrobe.height}
                                type="right"
                              />
                            </>
                          )}
                          {shouldRenderDoors && (
                            <>
                              <div
                                className="h-1.5 sm:h-2 xl:h-4 shadow-popup absolute top-0 right-0 left-0 z-40"
                                style={{
                                  backgroundColor: wardrobe.profile.color,
                                }}
                              ></div>
                              {wardrobe.doors.map((door, i) => (
                                <Door
                                  key={door.id}
                                  door={door}
                                  index={i}
                                  doorAmount={wardrobe.doors.length}
                                  railCount={wardrobe.rail_count}
                                  profileColor={wardrobe.profile.color}
                                  profileType={profileType}
                                />
                              ))}
                              {wardrobe.door_lighting && <Light />}
                            </>
                          )}
                          {shouldRenderSections && (
                            <div className="flex flex-col h-full preserve-3d">
                              <WardrobeTopshelf
                                depth={depthMath}
                                wardrobeHeight={wardrobe.height}
                                color={bodyColor ? bodyColor.hex : null}
                              />
                              <div className="flex items-center justify-center flex-grow preserve-3d">
                                {wardrobe.has_interior_lighting && <Light />}
                                {wardrobe.interior_sections.map((section) => {
                                  if (section.type === "wall") {
                                    return (
                                      <WardrobePartition
                                        key={section.id}
                                        section={section}
                                        wardrobeWidth={
                                          wardrobe.opening_width ||
                                          wardrobe.width
                                        }
                                        depth={depthMath}
                                        color={bodyColor ? bodyColor.hex : null}
                                        showMeasurement={interior && download}
                                      />
                                    );
                                  }

                                  if (
                                    section.type === "clothes_rail" ||
                                    section.type === "clothes_rail_x2"
                                  ) {
                                    return (
                                      <WardrobeClothesRail
                                        key={section.id}
                                        wardrobeWidth={
                                          wardrobe.opening_width ||
                                          wardrobe.width
                                        }
                                        depth={depthMath}
                                        width={section.width}
                                        offset={section.position_x}
                                        double={
                                          section.type === "clothes_rail_x2"
                                        }
                                        color={
                                          detailColor ? detailColor.hex : null
                                        }
                                        showMeasurement={interior && download}
                                      />
                                    );
                                  }

                                  return (
                                    <WardrobeSection
                                      key={section.id}
                                      section={section}
                                      wardrobeWidth={
                                        wardrobe.opening_width || wardrobe.width
                                      }
                                      color={bodyColor ? bodyColor.hex : null}
                                      showMeasurement={interior && download}
                                      handleColor={handleColor}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="wardrobe__face wardrobe__face--back"
                          style={{
                            transform: `rotateY(180deg) translateZ(${
                              25 + depthMath
                            }px)`,
                          }}
                        ></div>
                        <div
                          className="wardrobe__face wardrobe__face--right"
                          style={{
                            width: `calc(50px + ${depthMath}px * 2)`,
                            right: `calc(-25px - ${depthMath}px)`,
                            transform: "rotateY(90deg)",
                            borderColor: shouldRenderDoors
                              ? "transparent"
                              : null,
                          }}
                        ></div>
                        <div
                          className="wardrobe__face wardrobe__face--left"
                          style={{
                            width: `calc(50px + ${depthMath}px * 2)`,
                            transform: `rotateY(-90deg) translateZ(${
                              75 + depthMath
                            }px)`,
                            borderColor: shouldRenderDoors
                              ? "transparent"
                              : null,
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="room__face room__face--back"
                  style={{
                    transform: `rotateY(180deg) translateZ(${
                      50 + depthMath
                    }px)`,
                  }}
                ></div>
                <div
                  className="room__face room__face--right"
                  style={{
                    width: `calc(100px + ${depthMath}px * 2)`,
                    right: `calc(-50px - ${depthMath}px)`,
                    transform: "rotateY(90deg)",
                  }}
                ></div>
                <div
                  className="room__face room__face--left"
                  style={{
                    width: `calc(100px + ${depthMath}px * 2)`,
                    transform: `rotateY(-90deg) translateZ(${
                      100 + depthMath
                    }px)`,
                  }}
                ></div>
                <div
                  className="room__face room__face--top"
                  style={{
                    height: `calc(100px + ${depthMath}px * 2)`,
                    transform: `rotateX(90deg) translateZ(${
                      100 + depthMath
                    }px)`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wardrobe;
