import React from "react";
import { Checkmark } from "../../../icons";
import { observer, useLocalObservable } from "mobx-react";
import { Input, Select, Circle } from "../../../components";
import { userRoles } from "../../../constants";
import { post } from "../../../api";
import { validateEmail, validatePhone } from "../../../utils";
import { useNavigate } from "react-router-dom";

export const AddUser = observer(() => {
  const navigate = useNavigate();
  const store = useLocalObservable(() => ({
    sending: false,
    errors: {},
    error: "",
    user: {},
    userRole: null,
    handleSubmit(event) {
      store.error = "";
      store.errors = {};
      if (store.sending) {
        return;
      }
      event.preventDefault();
      store.sending = true;

      const target = event.target;
      const user = {
        first_name: target.first_name.value,
        last_name: target.last_name.value,
        email: target.email.value,
        phone: target.phone.value,
        role: store.userRole,
      };

      if (!user.first_name || user.first_name.length < 2) {
        store.errors.first_name = "För kort namn";
      }
      if (!user.last_name || user.last_name.length < 2) {
        store.errors.last_name = "För kort efternamn";
      }
      if (!user.email || !validateEmail(user.email)) {
        store.errors.email = "Ogiltig e-post";
      }
      if (
        !user.phone ||
        user.phone.length < 3 ||
        !validatePhone(user.phone.length)
      ) {
        store.errors.phone = "Ogiltigt telefonnummer";
      }
      if (!user.role) {
        store.errors.role = "Du måste välja en roll till användaren";
      }

      if (Object.keys(store.errors).length === 0) {
        post("/users", user)
          .then(() => {
            navigate(-1);
          })
          .catch((err) => {
            store.sending = false;
            store.error = " - " + err.response.data.error;
          });
      } else {
        store.sending = false;
      }
    },
  }));

  return (
    <form onSubmit={store.handleSubmit} className="flex flex-col">
      <div className="flex flex-1 flex-grow box p-4 md:py-7">
        <div className="flex flex-wrap max-w-2xl">
          <Input
            error={store.errors.first_name}
            fieldname="Förnamn"
            name="first_name"
            type="text"
          />
          <Input
            error={store.errors.last_name}
            fieldname="Efternamn"
            name="last_name"
            type="text"
          />
          <Input
            error={store.errors.email}
            fieldname="E-post"
            name="email"
            type="email"
          />
          <Input
            error={store.errors.phone}
            fieldname="Telefon"
            name="phone"
            type="tel"
          />
          <div className="flex flex-col w-full lg:w-1/2 md:px-3">
            <p className="mb-2 font-medium">Roll</p>
            <Select
              error={store.errors.role}
              items={userRoles}
              onChange={(value) => (store.userRole = value.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-6">
        <Circle type="button" value="submit">
          <Checkmark />
        </Circle>
        <p className="ml-4">
          {store.sending
            ? "Skickar..."
            : "Skapa och skicka uppgifter till användaren"}
        </p>
        <p className="text-orange">{store.error}</p>
      </div>
    </form>
  );
});

export default AddUser;
