import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { MeasurementArrow } from "../../../icons";

const Measurement = observer(({ size, type }) => (
  <span
    className={`flex items-center absolute ${
      type === "top"
        ? "w-full transform -translate-y-1/2 -mt-2.5"
        : type === "right"
        ? "flex-col h-full right-0 transform translate-x-1/2 -mr-6"
        : type === "left"
        ? "flex-col h-full left-0 transform -translate-x-1/2 -ml-6"
        : type === "bottom"
        ? "w-full bottom-0 transform translate-y-1/2 -mb-2.5"
        : "flex items-center absolute bottom-0 transform -translate-x-1/2 translate-y-1/2 -mb-6"
    }`}
  >
    {type !== "partition" && (
      <span
        className={`flex flex-1 ${
          type === "top" || type === "bottom"
            ? "h-px items-center"
            : "w-px justify-center"
        } bg-grey-darkest`}
      >
        <MeasurementArrow
          className={`absolute transform ${
            type === "top" || type === "bottom"
              ? "rotate-180"
              : "-rotate-90 -top-px"
          }`}
        />
      </span>
    )}
    <p className="text-grey-darkest text-center leading-none text-[10px] px-2">
      {size} mm
    </p>
    {type !== "partition" && (
      <span
        className={`flex flex-1 ${
          type === "top" || type === "bottom"
            ? "h-px items-center"
            : "w-px justify-center"
        } bg-grey-darkest`}
      >
        <MeasurementArrow
          className={`absolute transform ${
            type === "top" || type === "bottom"
              ? "right-0"
              : "-bottom-px rotate-90"
          }`}
        />
      </span>
    )}
  </span>
));

Measurement.propTypes = {
  size: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["top", "right", "bottom", "left", "partition"]),
};

export default Measurement;
