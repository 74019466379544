export const customerTypes = [
  { title: "Företag", value: "company" },
  { title: "Privat", value: "person" },
];

export const userRoles = [
  { title: "Administratör", value: "administrator" },
  { title: "Säljare", value: "sales" },
];

export const productTypes = [
  { title: "Basprodukter", value: "base" },
  { title: "Ritverktyg", value: "configurator" },
  { title: "Fältfyllningar", value: "area_color" },
  { title: "Profiler", value: "profile_color" },
  { title: "Möbelinredning", value: "interior" },
  { title: "Belysning", value: "lighting" },
  { title: "Stativ och vägghängd inredning", value: "stand_and_wallmounted" },
  { title: "Specialprodukter", value: "special" },
];

export const months = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

export const assemblyItems = [
  { title: "Ja", value: "yes" },
  { title: "Nej", value: "no" },
  { title: "Fastpris", value: "fixed_price" },
  { title: "Tillkommer på pris", value: "added_on_price_1" },
  {
    title:
      "Tillkommer med 600kr/timme, en startavgift på 300kr + ev. milersättning (innan 30% rotavdrag).",
    value: "added_on_price_2",
  },
];

export const PartitionWidth = 19;
export const InteriorWardrobeMaxHeight = 2080;
export const HoleSpacing = 32;

export const API_URL = process.env.API_URL;
export const RITVERKTYG_URL = process.env.RITVERKTYG_URL;
export const SOFTCLOSE_ID = parseInt(process.env.SOFTCLOSE_ID, 10);
export const DAMPING_ID = parseInt(process.env.DAMPING_ID, 10);
export const KLARNA_PORTAL_URL = process.env.KLARNA_PORTAL_URL;
export const KLARNA_STORE = process.env.KLARNA_STORE;

export const ConfiguratorCategories = {
  InteriorSection: "interior_section",
  InteriorAccessories: "interior_accessories",
  OtherAccessories: "other_accessories",
  Base: "base",
};

export const ColorGroups = {
  Detail: "detail",
  Body: "body",
};

export const ColorGroupItems = [
  { title: "Inredningsdetalj", value: "detail" },
  { title: "Stomme", value: "body" },
];

export const DetailColors = [
  { title: "Vit", value: "white", hex: "#FFFFFF" },
  { title: "Mörkgrå", value: "dark_grey", hex: "#585C56" },
];

export const BodyColors = [
  { title: "Vit", value: "white", hex: "#FFFFFF" },
  { title: "Antracit", value: "antracit", hex: "#666665" },
];

export const HandleColors = [
  { title: "Silver", value: "silver", hex: "#D2D2D2" },
  { title: "Svart", value: "black", hex: "#171717" },
  { title: "Vit", value: "white", hex: "#FFFFFF" },
];

export const SectionTypes = [
  { title: "S", value: "S" },
  { title: "M", value: "M" },
  { title: "L", value: "L" },
  { title: "XL", value: "XL" },
];

export const PRODUCT_TYPE_AREA = "area_color";
export const PRODUCT_TYPE_PROFILE = "profile_color";
export const PRODUCT_TYPE_BASE = "base";
export const PRODUCT_TYPE_CONFIGURATOR = "configurator";

export const ColorNames = {
  white: "Vit",
  dark_grey: "Mörkgrå",
  antracit: "Antracit",
  silver: "Silver",
  black: "Svart",
};
