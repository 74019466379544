import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalObservable, observer } from "mobx-react";
import { post } from "../../api";
import { ArrowForward } from "../../icons";
import { Circle } from "../../components";

const ResetPassword = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const store = useLocalObservable(() => ({
    token: null,
    errors: {},
    async handleSubmit(e) {
      e.preventDefault();
      this.errors = {};

      const query = new URLSearchParams(location.search);
      store.token = query.get("token");
      const password = e.target.password.value;

      try {
        await post(`/users/activate?change_password_token=${store.token}`, {
          password,
        });

        navigate("/login");
      } catch (err) {
        store.errors.password =
          "Lösenordet måste vara minst 8 tecken och innehålla minst en stor bokstav";
      }
    },
  }));

  return (
    <div className="bg-default-bg flex w-screen h-screen">
      <div className="flex flex-col-reverse lg:flex-col justify-center items-center mx-auto mt-16 max-w-5xl flex-1 flex-grow">
        <div className="flex w-full items-center flex-col-reverse lg:flex-row lg:flex-grow">
          <form
            className="flex lg:flex-1 w-full lg:w-1/2 justify-center items-center"
            onSubmit={store.handleSubmit}
          >
            <div className="bg-white rounded-3xl shadow-md flex flex-col items-center lg:max-w-full max-w-sm lg:w-auto w-full mx-8 pt-8 lg:pt-20 pb-8 lg:pb-16 px-8 lg:px-16">
              <h4 className="text-2xl normal-case font-semibold">
                Återställ lösenord
              </h4>
              <div className="w-full mt-7 lg:w-80">
                <input
                  className={store.errors.password && "error"}
                  placeholder="Lösenord"
                  name="password"
                  type="password"
                />
                {store.errors.password && (
                  <div className="form-error">{store.errors.password}</div>
                )}
              </div>
              <Circle type="button" className="mt-7" value="submit">
                <ArrowForward />
              </Circle>
            </div>
          </form>
        </div>
        <p className="text-grey text-center w-full mb-7 lg:mb-14 font-medium text-base tracking-wider uppercase">
          Admin
        </p>
      </div>
    </div>
  );
});

export default ResetPassword;
