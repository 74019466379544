import React, { useEffect } from "react";
import { Pencil } from "../../icons";
import { AddCircle } from "../../icons";
import { useLocalObservable, observer } from "mobx-react";
import {
  Input,
  Circle,
  ToggleBox,
  ToggleRow,
} from "../../components";
import { get } from "../../api";
import { userRoles } from "../../constants";
import { useLocation } from "react-router-dom";

const Settings = observer(() => {
  const location = useLocation();
  const store = useLocalObservable(() => ({
    editableUsers: [],
    user: {
      first_name: "",
      phone: "",
      email: "",
      last_name: "",
      role: "",
    },
    errors: {
      first_name: null,
      phone: null,
      email: null,
      last_name: null,
      password: null,
      general: null,
      old_password: null,
    },
    password: "",
    repeat_password: "",
    old_password: "",
    users: [],
    loadingMe: false,
    setUser(user) {
      store.user = user;
    },
    setUsers(users) {
      store.users = users;
    },
    campaign: null,
    setCampaign(campaign) {
      store.campaign = campaign;
    },
  }));

  useEffect(() => {
    get("/users/me").then((response) => {
      store.setUser(response.data);
    });
    get("/users").then((response) => {
      store.setUsers(response.data.results);
    });

    const fetchData = async () => {
      const [user, users, campaign] = await Promise.all([
        get("/users/me"),
        get("/users"),
        get("/campaigns"),
      ]);
      store.setUser(user.data);
      store.setUsers(users.data.results);
      store.setCampaign(campaign.data);
    };
    fetchData();
  }, [location.key]);

  return (
    <div className="flex flex-col">
      <ToggleBox title="Kontoinställningar" toggle={false}>
        <form
          onSubmit={store.updateMe}
          className="settings flex flex-col p-4 md:py-7"
        >
          <div className="flex flex-wrap w-full xl:max-w-2xl">
            <Input
              className="xl:w-80"
              fieldname="Förnamn"
              type="text"
              value={store.user.first_name}
              onChange={(e) => (store.user.first_name = e.target.value)}
              name="first_name"
              error={store.errors.first_name}
              disabled
            />
            <Input
              className="xl:w-80"
              fieldname="Efternamn"
              type="text"
              value={store.user.last_name}
              onChange={(e) => (store.user.last_name = e.target.value)}
              name="last_name"
              error={store.errors.last_name}
              disabled
            />
            <Input
              className="xl:w-80"
              fieldname="E-post"
              type="email"
              onChange={(e) => (store.user.email = e.target.value)}
              value={store.user.email}
              error={store.errors.email}
              name="email"
              disabled
            />
            <Input
              className="xl:w-80"
              fieldname="Telefon"
              type="tel"
              onChange={(e) => (store.user.phone = e.target.value)}
              value={store.user.phone}
              error={store.errors.phone}
              name="phone"
              disabled
            />
            <Input
              className="xl:w-80"
              fieldname="Lösenord"
              type="password"
              onChange={(e) => (store.password = e.target.value)}
              value={store.password}
              error={store.errors.password}
              placeholder="************"
              name="password"
              disabled
            />
            {store.editable && (
              <>
                <Input
                  fieldname="Repetera lösenord"
                  type="password"
                  name="repeat_password"
                  error={store.errors.password}
                  onChange={(e) => (store.repeat_password = e.target.value)}
                  value={store.repeat_password}
                />
                <Input
                  error={store.errors.old_password}
                  fieldname="Nuvarande lösenord"
                  type="password"
                  onChange={(e) => (store.old_password = e.target.value)}
                  value={store.old_password}
                  placeholder="************"
                  name="old_password"
                />
              </>
            )}
            {store.errors.general && (
              <p className="text-orange w-full mt-6">{store.errors.general}</p>
            )}
          </div>
          <div className="flex justify-end mt-2">
            {!store.editable && !store.loadingMe && (
              <Circle type="link" to="/settings/account">
                <Pencil />
              </Circle>
            )}
          </div>
        </form>
      </ToggleBox>
      <ToggleBox className="mt-8" title="Användare" toggle={false}>
        <div className="overflow-x-auto overflow-y-visible">
          {store.users.map((user, i) => {
            return (
              <ToggleRow
                className="xl:hidden"
                title={`${user.first_name} ${user.last_name}`}
                key={i}
              >
                <div className="py-6">
                  <div className="flex px-4 pb-4 md:px-7">
                    <p className="mr-4 font-medium">Roll</p>
                    <p>{user.role}</p>
                  </div>
                  <div className="flex px-4 pb-4 md:px-7">
                    <p className="mr-4 font-medium">E-post</p>
                    <p>{user.email}</p>
                  </div>
                  <div className="flex px-4 md:px-7">
                    <p className="mr-4 font-medium">Tel</p>
                    <p>{user.phone}</p>
                  </div>
                  <div className="flex justify-end px-4 mt-2 md:px-7">
                    <Circle type="link" to={`/edit-user/${user.id}`}>
                      <Pencil />
                    </Circle>
                  </div>
                </div>
              </ToggleRow>
            );
          })}
          <table className="hidden xl:table w-full">
            <tbody className="w-full">
              <tr className="bg-grey-lighter">
                <th className="text-xs text-left py-2 pl-4 lg:pl-7">
                  <p>Förnamn</p>
                </th>
                <th className="text-xs text-left py-2 pl-4 lg:pl-7">
                  <p>Efternamn</p>
                </th>
                <th className="text-xs text-left py-2 pl-4 lg:pl-7">
                  <p>E-post</p>
                </th>
                <th className="text-xs text-left py-2 pl-4 lg:pl-7">
                  <p>Telefon</p>
                </th>
                <th className="text-xs text-left py-2 pl-4 lg:pl-7">
                  <p>Roll</p>
                </th>
                <th></th>
              </tr>
              {store.users.map((user, i) => {
                const userRole = userRoles.find(
                  (role) => role.value === user.role
                );

                return (
                  <tr className="border-b border-grey" key={i}>
                    <td className="pl-4 lg:pl-7">
                      <p>{user.first_name}</p>
                    </td>
                    <td className="pl-4 lg:pl-7">
                      <p>{user.last_name}</p>
                    </td>
                    <td className="pl-4 lg:pl-7">
                      <p>{user.email}</p>
                    </td>
                    <td className="pl-4 lg:pl-7">
                      <p>{user.phone}</p>
                    </td>
                    <td className="pl-4 lg:pl-7">
                      <p>{userRole.title}</p>
                    </td>
                    <td className="py-4 px-4 pr-7 flex justify-end">
                      <Circle type="link" to={`/edit-user/${user.id}`}>
                        <Pencil />
                      </Circle>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex pl-4 lg:pl-7 py-4 items-center">
          <Circle
            actionText="Lägg till ny användare"
            type="link"
            to="/add-user"
          >
            <AddCircle />
          </Circle>
        </div>
      </ToggleBox>
      <ToggleBox className="mt-8" title="Kampanj" toggle={false}>
        {store.campaign ? (
          <table className="w-full">
            <thead>
              <tr className="bg-grey-lighter">
                <th className="text-xs text-left py-2 pl-4 lg:pl-7">
                  <p>Beskrivning</p>
                </th>
                <th className="text-xs text-left py-2 pl-4 lg:pl-7">
                  <p>Rabatt</p>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="w-full">
              <tr className="border-b border-grey">
                <td className="pl-4 lg:pl-7">
                  <p>{store.campaign.description}</p>
                </td>
                <td className="pl-4 lg:pl-7">
                  <p> {store.campaign.discount_percentage}%</p>
                </td>

                <td className="py-4 px-4 pr-7 flex justify-end">
                  <Circle type="link" to={`/edit-campaign`}>
                    <Pencil />
                  </Circle>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="flex pl-4 lg:pl-7 py-4 items-center">
            <Circle
              actionText="Lägg till ny kampanj"
              type="link"
              to="/add-campaign"
            >
              <AddCircle />
            </Circle>
          </div>
        )}
      </ToggleBox>
    </div>
  );
});

export default Settings;
