import React, { useEffect } from "react";
import { observer, useLocalObservable } from "mobx-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Cross, DownArrow, Loading, RightArrow, UpArrow } from "../../icons";
import { Link } from "react-router-dom";
import { get, put } from "../../api";
import { LabelSelect, PaymentLabelSelect } from "../../components";
import { months } from "../../constants";
import {
  getOrderStatus,
  getBadge,
  getPaymentBadge,
  getShippingText,
} from "../../utils";
const ORDER_STATUS = "order_status";
const ORDER_ID = "id";
const ORDER_CUSTOMER = "customer_id";
const ORDER_CREATED_DATE = "created_date";
const ORDER_COMPLETED_DATE = "completed_date";
const ORDER_SALESMAN = "seller_id";
const ORDER_PAYMENT = "payment_status";
const ORDER_TOTAL_PRICE = "total_price";
const ORDER_DELIVERY = "delivery_option";

function getDateText(dateString) {
  const date = new Date(dateString);
  return (
    date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
  );
}

const Table = ({
  items = [],
  sort,
  toggleSort,
  sortValue,
  updateStatus,
  type,
}) => {
  return items.length > 0 ? (
    <table className="w-full">
      <tbody className="whitespace-nowrap">
        <tr className="text-left border-b border-grey">
          {type !== "done" && (
            <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm">
              <button
                onClick={() => toggleSort(ORDER_STATUS)}
                className="flex items-center sort"
              >
                <p className="text-grey-dark font-medium">Status</p>

                <div className="flex flex-col items-center ml-2 -space-y-2 sort-arrows">
                  <UpArrow
                    className={`w-4 sort-arrow ${
                      sort === 1 && sortValue === ORDER_STATUS
                        ? "text-blue active"
                        : ""
                    }`}
                  />
                  <DownArrow
                    className={`w-4 sort-arrow ${
                      sort === 2 && sortValue === ORDER_STATUS
                        ? "text-blue active"
                        : ""
                    }`}
                  />
                </div>
              </button>
            </th>
          )}

          <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm">
            <button
              onClick={() => toggleSort(ORDER_ID)}
              className="flex items-center sort"
            >
              <p className="text-grey-dark font-medium">ID</p>

              <div className="flex flex-col items-center ml-2 -space-y-2">
                <UpArrow
                  className={`w-4 sort-arrow ${
                    sort === 1 && sortValue === ORDER_ID
                      ? "text-blue active"
                      : ""
                  }`}
                />
                <DownArrow
                  className={`w-4 sort-arrow ${
                    sort === 2 && sortValue === ORDER_ID
                      ? "text-blue active"
                      : ""
                  }`}
                />
              </div>
            </button>
          </th>
          <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm">
            <button
              onClick={() => toggleSort(ORDER_CUSTOMER)}
              className="flex items-center sort"
            >
              <p className="text-grey-dark font-medium">Kund</p>

              <div className="flex flex-col items-center ml-2 -space-y-2">
                <UpArrow
                  className={`w-4 sort-arrow ${
                    sort === 1 && sortValue === ORDER_CUSTOMER
                      ? "text-blue active"
                      : ""
                  }`}
                />
                <DownArrow
                  className={`w-4 sort-arrow ${
                    sort === 2 && sortValue === ORDER_CUSTOMER
                      ? "text-blue active"
                      : ""
                  }`}
                />
              </div>
            </button>
          </th>
          <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm hidden sm:table-cell">
            <button
              onClick={() => toggleSort(ORDER_CREATED_DATE)}
              className="flex items-center sort"
            >
              <p className="text-grey-dark font-medium">Skapad</p>

              <div className="flex flex-col items-center ml-2 -space-y-2">
                <UpArrow
                  className={`w-4 sort-arrow ${
                    sort === 1 && sortValue === ORDER_CREATED_DATE
                      ? "text-bluea active"
                      : ""
                  }`}
                />
                <DownArrow
                  className={`w-4 sort-arrow ${
                    sort === 2 && sortValue === ORDER_CREATED_DATE
                      ? "text-blue active"
                      : ""
                  }`}
                />
              </div>
            </button>
          </th>
          {type === "delivery" && (
            <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm">
              <button
                onClick={() => toggleSort(ORDER_DELIVERY)}
                className="flex items-center sort"
              >
                <p className="text-grey-dark font-medium">Leveranssätt</p>

                <div className="flex flex-col items-center ml-2 -space-y-2 sort-arrows">
                  <UpArrow
                    className={`w-4 sort-arrow ${
                      sort === 1 && sortValue === ORDER_DELIVERY
                        ? "text-blue active"
                        : ""
                    }`}
                  />
                  <DownArrow
                    className={`w-4 sort-arrow ${
                      sort === 2 && sortValue === ORDER_DELIVERY
                        ? "text-blue active"
                        : ""
                    }`}
                  />
                </div>
              </button>
            </th>
          )}
          {type === "done" && (
            <>
              <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm hidden sm:table-cell">
                <button
                  onClick={() => toggleSort(ORDER_COMPLETED_DATE)}
                  className="flex items-center sort"
                >
                  <p className="text-grey-dark font-medium">Slutförd</p>

                  <div className="flex flex-col items-center ml-2 -space-y-2">
                    <UpArrow
                      className={`w-4 sort-arrow ${
                        sort === 1 && sortValue === ORDER_COMPLETED_DATE
                          ? "text-blue active"
                          : ""
                      }`}
                    />
                    <DownArrow
                      className={`w-4 sort-arrow ${
                        sort === 2 && sortValue === ORDER_COMPLETED_DATE
                          ? "text-blue active"
                          : ""
                      }`}
                    />
                  </div>
                </button>
              </th>
              <th className="pl-8 pt-4 pb-3 text-grey-dark text-sm hidden sm:table-cell">
                <button
                  onClick={() => toggleSort(ORDER_TOTAL_PRICE)}
                  className="flex items-center sort"
                >
                  <p className="text-grey-dark font-medium">Totalbelopp</p>

                  <div className="flex flex-col items-center ml-2 -space-y-2">
                    <UpArrow
                      className={`w-4 sort-arrow ${
                        sort === 1 && sortValue === ORDER_TOTAL_PRICE
                          ? "text-blue active"
                          : ""
                      }`}
                    />
                    <DownArrow
                      className={`w-4 sort-arrow ${
                        sort === 2 && sortValue === ORDER_TOTAL_PRICE
                          ? "text-blue active"
                          : ""
                      }`}
                    />
                  </div>
                </button>
              </th>
            </>
          )}
          <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm hidden sm:table-cell">
            <button
              onClick={() => toggleSort(ORDER_SALESMAN)}
              className="flex items-center sort"
            >
              <p className="text-grey-dark font-medium">Säljare</p>

              <div className="flex flex-col items-center ml-2 -space-y-2">
                <UpArrow
                  className={`w-4 sort-arrow ${
                    sort === 1 && sortValue === ORDER_SALESMAN
                      ? "text-blue active"
                      : ""
                  }`}
                />
                <DownArrow
                  className={`w-4 sort-arrow ${
                    sort === 2 && sortValue === ORDER_SALESMAN
                      ? "text-blue active"
                      : ""
                  }`}
                />
              </div>
            </button>
          </th>
          {(type === "production" || type === "order") && (
            <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm hidden sm:table-cell">
              <button
                onClick={() => toggleSort(ORDER_PAYMENT)}
                className="flex items-center sort"
              >
                <p className="text-grey-dark font-medium">Betalning</p>

                <div className="flex flex-col items-center ml-2 -space-y-2">
                  <UpArrow
                    className={`w-4 sort-arrow ${
                      sort === 1 && sortValue === ORDER_PAYMENT
                        ? "text-blue active"
                        : ""
                    }`}
                  />
                  <DownArrow
                    className={`w-4 sort-arrow ${
                      sort === 2 && sortValue === ORDER_PAYMENT
                        ? "text-blue active"
                        : ""
                    }`}
                  />
                </div>
              </button>
            </th>
          )}
          <th></th>
        </tr>
        {items.length > 0 &&
          items.map((item, i) => {
            return (
              <tr key={i} className="border-b border-grey last:border-b-0">
                {type !== "done" && (
                  <td className="pl-4 md:pl-7 py-4 w-40">
                    <LabelSelect
                      value={item.order_status}
                      onChange={(value) => {
                        item.order_status = value;
                        updateStatus(item);
                      }}
                    />
                  </td>
                )}

                <td className="pl-4 md:pl-7 py-4 text-sm md:text-base">
                  {item.id}
                </td>
                <td className="pl-4 md:pl-7 py-4 text-sm md:text-base">
                  {item.customer.type === "company"
                    ? item.customer.company_name
                    : item.customer.first_name + " " + item.customer.last_name}
                </td>
                <td className="pl-4 md:pl-7 py-4 hidden sm:table-cell text-sm md:text-base">
                  {getDateText(item.created_date)}
                </td>
                {type === "done" && (
                  <>
                    <td className="pl-4 md:pl-7 py-4 text-sm md:text-base">
                      {getDateText(item.completed_date)}
                    </td>
                    <td className="pl-4 md:pl-7 py-4 text-sm md:text-base">
                      {item.total_price / 100} SEK
                    </td>
                  </>
                )}
                {type === "delivery" && (
                  <td className="pl-4 md:pl-7 py-4 text-sm md:text-base">
                    {getShippingText(item.delivery_option)}
                  </td>
                )}
                <td className="pl-4 md:pl-7 py-4 hidden sm:table-cell text-sm md:text-base">
                  {item.seller &&
                    item.seller.first_name + " " + item.seller.last_name}
                </td>
                {(type === "production" || type === "order") && (
                  <td className="pl-4 md:pl-7 py-4 hidden sm:table-cell max-w-44">
                    <PaymentLabelSelect
                      value={item.payment_status}
                      onChange={(value) => {
                        item.payment_status = value;
                        updateStatus(item);
                      }}
                    />
                  </td>
                )}
                <td className="text-right px-4 md:px-7 py-4">
                  <Link
                    className="flex items-center justify-end"
                    to={`/offerter/${item.id}`}
                  >
                    <RightArrow className="h-10 p-3 rounded-full bg-transparent transition-colors hover:bg-blue-light hover:text-blue" />
                  </Link>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  ) : (
    <p className="p-4 md:p-7 text-sm md:text-base">
      Inga offerter i denna kategori
    </p>
  );
};
export const Orders = observer(({ search, resetSearch }) => {
  const store = useLocalObservable(() => ({
    tabIndex: 0,
    sort: 0,
    sortValue: null,
    orders: [],
    searchOrders: [],
    loading: false,
    loadingSearch: false,
    errors: {},
    toggleSort(value) {
      if (value === this.sortValue) {
        if (this.sort < 2) {
          this.sort += 1;
          this.sortValue = value;
        } else {
          this.sortValue = null;
          this.sort = 0;
        }
      } else {
        this.sort = 1;
        this.sortValue = value;
      }
      getFieldsBySort(this.sort, value);
    },
    updateStatus(order) {
      this.errors.general = null;
      const obj = {
        ...order,
        order_status: order.order_status,
        payment_status: order.payment_status,
      };

      put("/orders/" + order.id, obj).catch(() => {
        this.errors.general = "Det gick inte att uppdatera etiketten";
      });
    },
  }));

  const getFieldsBySort = async (sort, value) => {
    store.loading = true;
    try {
      const res = await get(
        `/orders${
          sort && value ? `?order=${sort === 2 ? `-${value}` : value}` : ""
        }`
      );

      store.orders = res.data.results;
    } catch (err) {
      console.log(err);
    } finally {
      store.loading = false;
    }
  };

  useEffect(() => {
    if (search) {
      store.loadingSearch = true;
      get(`/orders${search ? `?s=${search}` : ""}`).then((res) => {
        store.searchOrders = res.data.results;
        store.loadingSearch = false;
      });
    } else {
      store.loading = true;
      get("/orders").then((res) => {
        store.orders = res.data.results;
        store.loading = false;
      });
    }
  }, [search]);

  const getOrderstatusLabel = (status) => {
    switch (status) {
      case "offer":
        return "Offert";
      case "order":
        return "Order";
      case "production":
        return "Produktion";
      case "delivery":
        return "Leveransklar";
      case "done":
        return "Slutförd";
    }
  };

  return (
    <div className="flex flex-col flex-grow pb-2 md:pt-3">
      {search && search.length > 1 ? (
        <div className="box h-full overflow-hidden">
          <div className="flex items-center space-x-6 p-4 md:px-7">
            <button type="button" onClick={resetSearch}>
              <Cross />
            </button>
            <p className="font-mont">
              Sökresultat för &ldquo;
              <span className="font-mont font-medium">{search}</span>&rdquo;
            </p>
          </div>
          <div className="overflow-x-auto border-t border-grey rounded-b-none rounded-t-2xl ">
            {store.loadingSearch ? (
              <Loading />
            ) : (
              <table className="w-full">
                <tbody className="whitespace-nowrap">
                  <tr className="text-left border-b border-grey">
                    <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm font-medium">
                      Typ
                    </th>
                    <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm font-medium">
                      Status
                    </th>
                    <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm font-medium">
                      ID
                    </th>
                    <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm font-medium">
                      Kund
                    </th>
                    <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm font-medium hidden sm:table-cell">
                      Skapad
                    </th>
                    <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm font-medium hidden sm:table-cell">
                      Säljare
                    </th>
                    <th className="pl-4 md:pl-7 pt-4 pb-3 text-grey-dark text-sm font-medium hidden sm:table-cell">
                      Betalning
                    </th>
                    <th></th>
                  </tr>
                  {store.searchOrders.length > 0 ? (
                    store.searchOrders.map((order) => (
                      <tr className="border-b border-grey" key={order.id}>
                        <td className="pl-4 md:pl-7 py-4 max-w-32">
                          {getOrderstatusLabel(
                            getOrderStatus(order.order_status)
                          )}
                        </td>
                        <td className="pl-4 md:pl-7 py-4 w-40">
                          <span
                            className={`min-w-30 px-2 flex items-center justify-center py-1 capitalize rounded-full bg-${
                              getBadge(order.order_status).color
                            }`}
                          >
                            {getBadge(order.order_status).label}
                          </span>
                        </td>
                        <td className="pl-4 md:pl-7 py-4">{order.id}</td>
                        <td className="pl-4 md:pl-7 py-4">
                          {order.customer
                            ? order.customer.type === "company"
                              ? order.customer.company_name
                              : order.customer.first_name +
                                " " +
                                order.customer.last_name
                            : ""}
                        </td>
                        <td className="pl-4 md:pl-7 py-4 hidden sm:table-cell">
                          {getDateText(order.created_date)}
                        </td>
                        <td className="pl-4 md:pl-7 py-4">
                          {order.seller
                            ? order.seller.first_name +
                              " " +
                              order.seller.last_name
                            : ""}
                        </td>
                        <td className="pl-4 md:pl-7 py-4 hidden sm:table-cell w-40">
                          <span
                            className={`md:min-w-30 px-3 flex items-center justify-center py-1 capitalize rounded-full bg-${
                              getPaymentBadge(order.payment_status).color
                            }`}
                          >
                            {getPaymentBadge(order.payment_status).label}
                          </span>
                        </td>
                        <td className="pl-4 md:pl-7 py-4">
                          <Link
                            className="flex items-center justify-end"
                            to={`/offerter/${order.id}`}
                          >
                            <RightArrow className="h-10 p-3 rounded-full bg-transparent transition-colors hover:bg-blue-light hover:text-blue" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="w-full">
                      <td
                        colSpan="20"
                        className="text-grey-dark p-4 md:p-7 w-full"
                      >
                        Hittade inga objekt
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      ) : (
        <Tabs
          selectedIndex={store.tabIndex}
          onSelect={(index) => (store.tabIndex = index)}
          selectedTabClassName="selected-tab"
          selectedTabPanelClassName="w-full"
          className="flex-1"
        >
          <TabList className="inline-flex -mx-px w-full">
            <Tab className="tab text-xs md:text-base w-full z-3">Offert</Tab>
            <Tab className="tab text-xs md:text-base w-full -ml-4 z-3">
              Order
            </Tab>
            <Tab className="tab text-xs md:text-base w-full -ml-4 pl-4 z-2">
              Produktion
            </Tab>
            <Tab className="tab text-xs md:text-base w-full -ml-4 pl-4 z-1">
              Leveransklar
            </Tab>
            <Tab className="tab text-xs md:text-base w-full -ml-4 pl-4">
              Slutförda
            </Tab>
          </TabList>
          <div className="flex flex-col flex-grow border rounded-b-2xl -m-px border-grey overflow-x-auto">
            <TabPanel>
              {store.loading ? (
                <div className="p-4 md:p-7">
                  <Loading />
                </div>
              ) : (
                <Table
                  updateStatus={store.updateStatus}
                  sortValue={store.sortValue}
                  sort={store.sort}
                  toggleSort={store.toggleSort}
                  type="offer"
                  items={store.orders.filter(
                    (order) => getOrderStatus(order.order_status) === "offer"
                  )}
                />
              )}
            </TabPanel>
            <TabPanel>
              {store.loading ? (
                <div className="p-4 md:p-7">
                  <Loading />
                </div>
              ) : (
                <Table
                  type="order"
                  updateStatus={store.updateStatus}
                  sortValue={store.sortValue}
                  sort={store.sort}
                  toggleSort={store.toggleSort}
                  items={store.orders.filter(
                    (order) => getOrderStatus(order.order_status) === "order"
                  )}
                />
              )}
            </TabPanel>
            <TabPanel>
              {store.loading ? (
                <div className="p-4 md:p-7">
                  <Loading />
                </div>
              ) : (
                <Table
                  type="production"
                  updateStatus={store.updateStatus}
                  sortValue={store.sortValue}
                  sort={store.sort}
                  toggleSort={store.toggleSort}
                  items={store.orders.filter(
                    (order) =>
                      getOrderStatus(order.order_status) === "production"
                  )}
                />
              )}
            </TabPanel>
            <TabPanel>
              {store.loading ? (
                <div className="p-4 md:p-7">
                  <Loading />
                </div>
              ) : (
                <Table
                  updateStatus={store.updateStatus}
                  sortValue={store.sortValue}
                  sort={store.sort}
                  toggleSort={store.toggleSort}
                  type="delivery"
                  items={store.orders.filter(
                    (order) => getOrderStatus(order.order_status) === "delivery"
                  )}
                />
              )}
            </TabPanel>
            <TabPanel>
              {store.loading ? (
                <div className="p-4 md:p-7">
                  <Loading />
                </div>
              ) : (
                <Table
                  updateStatus={store.updateStatus}
                  sortValue={store.sortValue}
                  sort={store.sort}
                  toggleSort={store.toggleSort}
                  type="done"
                  items={store.orders.filter(
                    (order) => getOrderStatus(order.order_status) === "done"
                  )}
                />
              )}
            </TabPanel>
          </div>
        </Tabs>
      )}
    </div>
  );
});

export default Orders;
