import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect } from "react";
import { get, put, post, deleteRequest } from "../../../api";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Circle,
  ToggleBox,
  LabelSelect,
  PaymentLabelSelect,
  MeasureInput,
  Select,
} from "../../../components";
import {
  assemblyItems,
  KLARNA_PORTAL_URL,
  KLARNA_STORE,
} from "../../../constants";
import {
  Eye,
  MinusCircle,
  Checkmark,
  List,
  Cross,
  AddCircle,
  Pencil,
  Save,
  Information,
  PDF,
} from "../../../icons";
import { Closet } from "../components/closet";
import {
  AssemplyDescriptionAndMiscInfo,
  ProductSearchRow,
  Row,
  ShippingAndRequest,
} from "../components";
import gardeLogo from "../../login/assets/logo-blue-garderobsfabriken.png";
import { getOrderStatus } from "../../../utils";
import { OfferPdf, ProductionPDF, LabelPdf, PicklistPDF } from "./pdf";
import { store as globalStore } from "../../../store";
import { validateOrderData } from "../../../utils";
import { buildWardrobeImage } from "../../../wardrobeImage";
import { RITVERKTYG_URL } from "../../../constants";

const SingleOrder = observer(() => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { orderId } = params;
  const store = useLocalObservable(() => ({
    getProductType(group_id) {
      return this.productGroups.find((pg) => pg.id === group_id);
    },
    productGroups: [],
    showPickList: false,
    showProdctionPDF: false,
    showOrderLabel: false,
    showPreview: false,
    salesmen: [],
    order: null,
    validOrder: false,
    errors: {},
    figures: [],
    products: [],
    loading: true,
    saving: false,
    showModel: false,
    lightingProducts: [],
    interiorProducts: [],
    specialProducts: [],
    standAndWallmountedProducts: [],
    interiorSectionProducts: [],
    handleColor: null,
    showAddMenu: false,
    toggleShowModel() {
      this.showModel = !this.showModel;
    },
    getOrderRows() {
      const rows = [];
      if (this.order.rows && this.order.rows.length > 0) {
        this.order.rows.forEach((product) => {
          rows.push({
            product_id: product.product_id || product.id,
            quantity: product.quantity,
            product_configuration_id: product.selectedConf
              ? product.selectedConf.id
              : product.product_configuration_id
              ? product.product_configuration_id
              : null,
            type: product.category || product.type,
          });
        });
      }
      return rows;
    },
    async updateOrder() {
      this.saving = true;
      const rows = this.getOrderRows();
      try {
        await put("/orders/" + this.order.id, {
          ...this.order,
          rows,
        });
        const errors = validateOrderData(this.order);
        this.validOrder = Object.keys(errors).length === 0;
      } catch (err) {
        console.log(err.response);
      } finally {
        this.saving = false;
      }
    },
    async updatePrices() {
      const rows = this.getOrderRows();

      try {
        const { data: order } = await post("/orders/dry", {
          ...this.order,
          rows,
        });

        this.order.price = order.price;
        this.order.shipping_price = order.shipping_price;
        this.order.total_price = order.total_price;
      } catch (error) {
        console.log(error);
      }
    },
    async sendOrder() {
      const obj = {
        ...this.order,
        order_status: this.getNextStatus(this.order.order_status),
      };
      try {
        await put("/orders/" + this.order.id, obj);

        navigate("/offerter");
      } catch (err) {
        console.log(err.response);
      }
    },
    async deleteOrder() {
      try {
        await deleteRequest("/orders/" + this.order.id);

        navigate("/offerter");
      } catch (err) {
        console.log(err.response);
      }
    },
    getNextStatus(status) {
      switch (status.split(".")[0]) {
        case "offer":
          return "order.confirmed";
        case "order":
          return "production.producing";
        case "production":
          return "delivery.ready";
        case "delivery":
          return "done";
      }
    },
    async updateOrderLabels() {
      const obj = {
        ...this.order,
        order_status: this.order.order_status,
        payment_status: this.order.payment_status,
      };

      try {
        await put("/orders/" + this.order.id, obj);
      } catch (err) {
        this.errors.general = "Det gick inte att uppdatera etiketterna";
      }
    },
    async updateWardrobeImage(wardrobePath, interiorWardrobePath) {
      try {
        this.order.wardrobe.dirty = false;
        this.order.wardrobe.image = wardrobePath;
        this.order.wardrobe.interior_image = interiorWardrobePath;

        await put("/orders/" + this.order.id, this.order);
      } catch (err) {
        console.log(err.response);
      }
    },
    removeFromOrderRows(product) {
      const index = store.order.rows.findIndex(
        (row) =>
          row.product_id === product.id &&
          row.product_configuration_id === product.selectedConf.id &&
          row.quantity === product.quantity
      );

      if (index >= 0) {
        store.order.rows.splice(index, 1);
        this.updatePrices();
      }
    },
    addToOrderRows(product) {
      const row = {
        price: product.selectedConf.price * product.quantity,
        product_id: product.id,
        product: product.product || product,
        product_configuration_id: product.selectedConf.id,
        product_configuration: product.selectedConf,
        quantity: product.quantity,
      };

      store.order.rows.push(row);
      this.updatePrices();
    },
    updateOrderRow(product, quantity, config) {
      const index = store.order.rows.findIndex(
        (row) =>
          row.product_id === product.id &&
          row.product_configuration_id === product.selectedConf.id &&
          row.quantity === product.quantity
      );

      product.quantity = parseInt(quantity, 10);
      product.selectedConf = config;

      if (index >= 0) {
        const row = {
          price: product.selectedConf.price * product.quantity,
          product_id: product.id,
          product: product.product || product,
          product_configuration_id: product.selectedConf.id,
          product_configuration: product.selectedConf,
          quantity: product.quantity,
        };

        store.order.rows.splice(index, 1, row);
        this.updatePrices();
      }
    },
    changeSalesman(item) {
      this.order.seller_id = item.id;
      this.order.seller = {};
      this.order.seller.email = item.email;
      this.order.seller.phone = item.phone;
      this.order.seller.first_name = item.first_name;
      this.order.seller.last_name = item.last_name;
    },
    isInteriorProduct(product) {
      return (
        store.getProductType(product.group_id).category === "interior" ||
        store.getProductType(product.group_id).category ===
          "interior_section" ||
        store.getProductType(product.group_id).category ===
          "interior_accessories" ||
        store.getProductType(product.group_id).category === "base"
      );
    },
    isSpecialProduct(product) {
      return (
        store.getProductType(product.group_id).category === "special" ||
        store.getProductType(product.group_id).category === "other_accessories"
      );
    },
  }));

  const getProducts = async (page = 1) => {
    const limit = 100;
    const res = await get(`/products?limit=${limit}&page=${page}`);

    store.products.push(...res.data.results);

    if (res.data.count > limit) {
      getProducts(page + 1);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (
        !globalStore.productCategories ||
        globalStore.productCategories.length === 0
      ) {
        const productGroups = await get(`/product_groups?limit=100`);
        store.productGroups = productGroups.data.results;
      } else {
        store.productGroups = globalStore.productCategories;
      }
      const orders = await get(`/orders/${orderId}`);
      store.order = orders.data;
      const errors = validateOrderData(store.order);
      store.validOrder = Object.keys(errors).length === 0;
      const users = await get("/users");
      store.salesmen = users.data.results;
      await getProducts();

      if (store.order.rows) {
        let orderProds = [];

        store.order.rows.forEach((row) => {
          orderProds.push({
            quantity: row.quantity,
            selectedConf: row.product_configuration,
            product: row.product,
            ...row.product,
          });
        });

        store.lightingProducts = orderProds.filter((prod) =>
          store.getProductType(prod.group_id)
            ? store.getProductType(prod.group_id).category === "lighting"
            : false
        );
        store.interiorProducts = orderProds.filter((prod) =>
          store.isInteriorProduct(prod)
        );
        store.specialProducts = orderProds.filter((prod) =>
          store.isSpecialProduct(prod)
        );
        store.standAndWallmountedProducts = orderProds.filter((prod) =>
          store.getProductType(prod.product.group_id)
            ? store.getProductType(prod.product.group_id).category ===
              "stand_and_wallmounted"
            : false
        );

        if (store.order.wardrobe.interior_sections.length > 0) {
          store.order.wardrobe.interior_sections.forEach((interiorSection) => {
            interiorSection.products.forEach((product) => {
              const foundProduct = store.interiorSectionProducts.find(
                (p) =>
                  p.product_id === product.product_id &&
                  p.product_configuration.id ===
                    product.product_configuration.id
              );
              if (!foundProduct) {
                product.quantity = 1;
                store.interiorSectionProducts.push(product);
              } else {
                foundProduct.quantity++;
              }
            });
          });
        }

        const handleProduct = store.order.rows.find(
          (row) => row.product.name === "Handtag"
        );

        if (handleProduct) {
          store.handleColor = handleProduct.product_configuration.color;
        }
      }
      store.loading = false;
    }
    fetchData();
  }, [location.key]);

  useEffect(() => {
    if (store.order && store.order.wardrobe) {
      get("/layouts/" + store.order.wardrobe.height).then((res) => {
        store.figures = res.data;
      });
    }
  }, [store.order]);

  if (store.loading) {
    return <p>Hämtar orderdata.</p>;
  }

  const handleEditOrder = async () => {
    try {
      const { data: orderGlobalId } = await get(
        `/orders/edit/${store.order.id}`
      );

      window.location.assign(`${RITVERKTYG_URL}/?id=${orderGlobalId}`);
    } catch (error) {
      console.error(error.messsage);
    }
  };

  const openKlarnaOrderInNewTab = () => {
    const url = `${KLARNA_PORTAL_URL}/orders/all/merchants/${KLARNA_STORE}/orders/${store.order.klarna_order_id}`;

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");

    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div className="flex flex-wrap justify-between pb-6 items-center">
        <div className="flex flex-row mb-4 sm:mb-0 items-center w-full sm:w-auto">
          <h1 className="mr-4 flex-1 w-full whitespace-nowrap self-start">
            {getOrderStatus(store.order.order_status) === "offer"
              ? "Offert"
              : "Order"}
            <span className="ml-4">#{store.order ? store.order.id : ""}</span>
          </h1>
          {store.order && (
            <div className="flex flex-wrap">
              <span className="mr-2 sm:mr-4">
                <LabelSelect
                  value={store.order.order_status}
                  onChange={(value) => {
                    store.order.order_status = value;
                    store.updateOrderLabels();
                  }}
                />
              </span>
              <PaymentLabelSelect
                value={store.order.payment_status}
                onChange={(value) => {
                  store.order.payment_status = value;
                  store.updateOrderLabels();
                }}
              />
            </div>
          )}
        </div>
        <div className="flex flex-wrap items-center w-full sm:justify-start sm:w-auto mt-4 sm:mt-0 space-x-1 lg:space-x-4 space-y-1 lg:space-y-0">
          {store.order &&
            (getOrderStatus(store.order.order_status) === "production" ||
              getOrderStatus(store.order.order_status) === "done" ||
              getOrderStatus(store.order.order_status) === "delivery") && (
              <>
                <Circle
                  type="button"
                  onClick={() => (store.showProdctionPDF = true)}
                  infoText="Produktion"
                >
                  <Information />
                </Circle>
                {getOrderStatus(store.order.order_status) === "delivery" &&
                  (store.order.rows.some((row) => row.quantity > 0) ||
                    store.order.wardrobe.side_wall_count > 0 ||
                    store.order.wardrobe.sloping_cieling_count > 0 ||
                    store.order.wardrobe.top_shelf_count > 0 ||
                    store.order.wardrobe.interior_sections.length > 0 ||
                    store.order.wardrobe.bead_count) > 0 && (
                    <Circle
                      type="button"
                      onClick={() => (store.showPickList = true)}
                      infoText="Plocklista"
                    >
                      <List />
                    </Circle>
                  )}
                {store.order.wardrobe && (
                  <Circle
                    type="button"
                    onClick={() => (store.showOrderLabel = true)}
                    infoText="Etikett"
                  >
                    <PDF />
                  </Circle>
                )}
              </>
            )}

          {store.order && (
            <Circle
              type="button"
              onClick={async () => {
                await store.updateOrder();
                if (
                  store.order.wardrobe !== null &&
                  store.order.wardrobe.dirty
                ) {
                  const profileType = store.getProductType(
                    store.order.wardrobe.profile.group_id
                  );
                  const wardrobePath = await buildWardrobeImage(
                    store.order.wardrobe,
                    profileType.id,
                    false,
                    store.handleColor
                  );
                  let interiorWardrobePath = null;
                  if (store.order.wardrobe.wardrobe_type !== "only_doors") {
                    interiorWardrobePath = await buildWardrobeImage(
                      store.order.wardrobe,
                      profileType.id,
                      true,
                      store.handleColor
                    );
                  }
                  await store.updateWardrobeImage(
                    wardrobePath,
                    interiorWardrobePath
                  );
                }
                store.showPreview = true;
              }}
              infoText="Spara och PDF"
            >
              <Eye />
            </Circle>
          )}
          {store.order && getOrderStatus(store.order.order_status) === "offer" && (
            <>
              <Circle
                type="button"
                onClick={store.updateOrder}
                infoText="Spara"
              >
                <Save />
              </Circle>
              <Circle type="button" onClick={handleEditOrder} infoText="Ändra">
                <Pencil />
              </Circle>
            </>
          )}
          <Circle
            type="button"
            onClick={() => {
              if (confirm(`Ta bort order #${store.order.id}`)) {
                store.deleteOrder();
              }
            }}
            infoText="Ta bort"
          >
            <MinusCircle />
          </Circle>
          {store.order &&
            store.validOrder &&
            store.order.order_status !== "done" && (
              <Circle
                type="button"
                onClick={() => store.sendOrder()}
                infoText="Godkänn"
              >
                <Checkmark />
              </Circle>
            )}
        </div>
      </div>
      <div className="flex flex-col">
        {store.showPickList && (
          <PopupPrints onClose={() => (store.showPickList = false)}>
            <PicklistPDF
              order={store.order}
              interiorSectionProducts={store.interiorSectionProducts}
            />
          </PopupPrints>
        )}
        {store.showProdctionPDF && (
          <PopupPrints onClose={() => (store.showProdctionPDF = false)}>
            <ProductionPDF
              figures={store.figures}
              profileName={
                store.order && store.order.wardrobe
                  ? store.getProductType(store.order.wardrobe.profile.group_id)
                      .name
                  : null
              }
              order={store.order}
            />
          </PopupPrints>
        )}
        {store.showOrderLabel && store.order.wardrobe && (
          <PopupPrints onClose={() => (store.showOrderLabel = false)}>
            <LabelPdf
              profileName={
                store.order.wardrobe
                  ? store.getProductType(store.order.wardrobe.profile.group_id)
                      .name
                  : null
              }
              order={store.order}
              image={gardeLogo}
            />
          </PopupPrints>
        )}
        {store.showPreview && (
          <PopupPrints onClose={() => (store.showPreview = false)}>
            <OfferPdf
              figures={store.figures}
              profileName={
                store.order && store.order.wardrobe
                  ? store.getProductType(store.order.wardrobe.profile.group_id)
                      .name
                  : null
              }
              order={store.order}
              image={gardeLogo}
            />
          </PopupPrints>
        )}
        {!store.validOrder && (
          <div className="w-full text-orange border text-sm border-orange-light rounded-2xl flex items-center px-6 py-4 my-4">
            Ordern är inte komplett. Fyll i informationen som saknas.
          </div>
        )}
        <div className="flex w-full flex-wrap xl:space-x-8">
          <ToggleBox
            toggle={false}
            title="Kundinformation"
            className="flex w-full xl:flex-1 relative"
          >
            <table className="h-full">
              <tbody className="text-sm md:text-base">
                <tr>
                  <td className="bg-grey-lighter px-4 pt-4 pb-2 text-right align-top">
                    Namn
                  </td>
                  <td className="px-4 pt-4 pb-2 align-top">
                    {!!store.order && store.order.customer
                      ? store.order.customer.type === "company"
                        ? store.order.customer.company_name
                        : store.order.customer.first_name +
                          " " +
                          store.order.customer.last_name
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="bg-grey-lighter px-4 py-2 text-right align-top">
                    Faktureringsadress
                  </td>
                  <td className="px-4 py-2 align-top">
                    {!!store.order && store.order.customer
                      ? store.order.customer.billing_address +
                        " " +
                        store.order.customer.billing_zipcode +
                        " " +
                        store.order.customer.billing_city
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="bg-grey-lighter px-4 py-2 text-right align-top">
                    E-post
                  </td>
                  <td className="px-4 py-2 align-top">
                    {!!store.order && store.order.customer
                      ? store.order.customer.type === "company"
                        ? store.order.customer.contact_email
                        : store.order.customer.email
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="bg-grey-lighter px-4 pb-4 pt-2 text-right align-top rounded-bl-2xl">
                    Telefon
                  </td>
                  <td className="px-4 pb-4 pt-2 align-top">
                    {!!store.order && store.order.customer
                      ? store.order.customer.contact_phone
                      : ""}
                  </td>
                </tr>
                {getOrderStatus(store.order.order_status) === "offer" && (
                  <tr>
                    <td className="bg-grey-lighter px-4 pb-4 pt-2 text-right align-top rounded-bl-2xl"></td>
                    <td>
                      <Circle
                        className="mr-2 mb-2 ml-auto"
                        type="link"
                        to={`/customers/${store.order.customer.type}/${store.order.customer.id}`}
                      >
                        <Pencil />
                      </Circle>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </ToggleBox>
          {store.order.klarna_order_id ? (
            <ToggleBox
              toggle={false}
              title="Klarna"
              className="flex w-full xl:flex-1 relative mt-6 xl:mt-0"
            >
              <table className="h-full">
                <tbody className="text-sm md:text-base">
                  <tr>
                    <td className="bg-grey-lighter px-4 pb-4 pt-2 text-right rounded-bl-2xl">
                      ID
                    </td>
                    <td className="px-4 pb-4 pt-2">
                      {store.order.klarna_order_id}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-grey-lighter px-4 pb-4 pt-2 text-right rounded-bl-2xl">
                      Länk
                    </td>
                    <td className="px-4 pb-4 pt-2">
                      <p
                        className="underline cursor-pointer"
                        onClick={openKlarnaOrderInNewTab}
                      >
                        Order detaljer i klarna
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ToggleBox>
          ) : (
            <ToggleBox
              toggle={false}
              title="Säljare"
              className="w-full xl:flex-1 relative mt-4 xl:mt-0"
              error={store.errors.seller}
            >
              <table className="h-full">
                <tbody className="text-sm md:text-base">
                  <tr>
                    <td className="bg-grey-lighter px-4 pt-4 pb-2 text-right">
                      Namn
                    </td>
                    <td className="w-full px-4 pt-4 pb-2">
                      {getOrderStatus(store.order.order_status) === "offer" ? (
                        <Select
                          type="user"
                          items={store.salesmen}
                          onChange={(item) => store.changeSalesman(item)}
                          initialItem={store.order.seller_id}
                        />
                      ) : store.order.seller ? (
                        `${store.order.seller.first_name} ${store.order.seller.last_name}`
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-grey-lighter px-4 py-2 text-right align-top">
                      E-post
                    </td>
                    <td className="w-full px-4 py-2 align-top">
                      {!!store.order && store.order.seller
                        ? store.order.seller.email
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-grey-lighter px-4 pb-4 pt-2 text-right align-top rounded-bl-2xl">
                      Telefon
                    </td>
                    <td className="w-full px-4 pb-4 pt-2 align-top">
                      {!!store.order && store.order.seller
                        ? store.order.seller.phone
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ToggleBox>
          )}
        </div>
        {!!store.order && (
          <div className="mt-6">
            <ShippingAndRequest
              order={store.order}
              customer={store.order.customer}
            />
          </div>
        )}
        <div className="flex w-full mt-6">
          <ToggleBox
            title="Garderob"
            className="w-full flex flex-1 flex-col overflow-visible"
            wardrobeModelToggle={
              store.order.wardrobe === null ? false : store.toggleShowModel
            }
            showModel={store.showModel}
          >
            <Closet
              order={store.order}
              showModel={store.showModel}
              orderStatus={getOrderStatus(store.order.order_status)}
              handleColor={store.handleColor}
            />
          </ToggleBox>
        </div>
        {getOrderStatus(store.order.order_status) === "offer" && (
          <div className="mt-6">
            <ToggleBox
              title="Specialprodukter &amp; betalning"
              toggle={false}
              updateOrder={store.updateOrder}
              saving={store.saving}
            >
              <div className="p-4 md:p-7 border-b border-grey">
                <Circle
                  onClick={() => (store.showAddMenu = !store.showAddMenu)}
                  type="button"
                  actionText="Lägg till produkter"
                >
                  {!store.showAddMenu ? (
                    <AddCircle />
                  ) : (
                    <div className="relative">
                      <Cross />
                      <div className="absolute z-40 bg-white rounded-2xl border border-grey w-48 lg:w-80 flex flex-col left-0 text-left -ml-6 mt-6">
                        {store.lightingProducts.length === 0 && (
                          <span
                            onClick={() => {
                              store.lightingProducts.push({});
                            }}
                            className="font-normal text-left text-grey-dark pl-4 py-4 border-b last:border-b-0 rounded-t-2xl border-grey hover:bg-blue-light hover:text-blue"
                          >
                            Lägg till belysningsprodukter
                          </span>
                        )}
                        {store.interiorProducts.length === 0 && (
                          <span
                            onClick={() => {
                              store.interiorProducts.push({});
                            }}
                            className="font-normal text-left text-grey-dark pl-4 py-4 border-b last:border-b-0 rounded-t-2xl border-grey hover:bg-blue-light hover:text-blue"
                          >
                            Lägg till möbelinredning
                          </span>
                        )}
                        {store.standAndWallmountedProducts.length === 0 && (
                          <span
                            onClick={() => {
                              store.standAndWallmountedProducts.push({});
                            }}
                            className="font-normal text-left text-grey-dark pl-4 py-4 border-b last:border-b-0 border-grey hover:bg-blue-light hover:text-blue"
                          >
                            Lägg till stativ och vägghängd inredning
                          </span>
                        )}
                        {store.specialProducts.length === 0 && (
                          <span
                            onClick={() => {
                              store.specialProducts.push({});
                            }}
                            className="font-normal text-left text-grey-dark pl-4 py-4 border-b last:border-b-0 rounded-b-2xl border-grey hover:bg-blue-light hover:text-blue"
                          >
                            Lägg till specialprodukter
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </Circle>
              </div>
              {store.lightingProducts.length > 0 && (
                <Row openDefault title="Belysningsprodukter">
                  <EditOther
                    type="lighting"
                    products={store.products.filter((prod) =>
                      store.getProductType(prod.group_id)
                        ? store.getProductType(prod.group_id).category ===
                          "lighting"
                        : false
                    )}
                    existingProducts={store.lightingProducts}
                    onDelete={store.removeFromOrderRows}
                    onAdd={store.addToOrderRows}
                    onUpdate={store.updateOrderRow}
                  />
                </Row>
              )}
              {store.interiorProducts.length > 0 && (
                <Row openDefault title="Möbelinredning">
                  <EditOther
                    type="interior"
                    products={store.products.filter((product) =>
                      store.isInteriorProduct(product)
                    )}
                    existingProducts={store.interiorProducts}
                    onDelete={store.removeFromOrderRows}
                    onAdd={store.addToOrderRows}
                    onUpdate={store.updateOrderRow}
                  />
                </Row>
              )}
              {store.standAndWallmountedProducts.length > 0 && (
                <Row openDefault title="Stativ och vägghängd inredning">
                  <EditOther
                    type="stand_and_wallmounted"
                    products={store.products.filter((prod) =>
                      store.getProductType(prod.group_id)
                        ? store.getProductType(prod.group_id).category ===
                          "stand_and_wallmounted"
                        : false
                    )}
                    existingProducts={store.standAndWallmountedProducts}
                    onDelete={store.removeFromOrderRows}
                    onAdd={store.addToOrderRows}
                    onUpdate={store.updateOrderRow}
                  />
                </Row>
              )}
              {store.specialProducts.length > 0 && (
                <Row openDefault title="Specialprodukter">
                  <EditOther
                    type="special"
                    existingProducts={store.specialProducts}
                    products={store.products.filter((prod) =>
                      store.isSpecialProduct(prod)
                    )}
                    onDelete={store.removeFromOrderRows}
                    onAdd={store.addToOrderRows}
                    onUpdate={store.updateOrderRow}
                  />
                </Row>
              )}
              <EditSum order={store.order} />
            </ToggleBox>
          </div>
        )}
        <div className="mt-6">
          <ToggleBox
            title="Instruktioner och info"
            updateOrder={
              getOrderStatus(store.order.order_status) === "offer"
                ? store.updateOrder
                : null
            }
            saving={store.saving}
            toggle={false}
          >
            <AssemplyDescriptionAndMiscInfo order={store.order} />
          </ToggleBox>
        </div>
      </div>
    </>
  );
});

const PopupPrints = ({ children, onClose }) => {
  return (
    <div className="z-20 absolute inset-0">
      <div className="w-full absolute z-40 top-0 mt-6">
        <Circle
          className="absolute right-0 top-0 mr-6 bg-white"
          type="button"
          onClick={onClose}
        >
          <Cross />
        </Circle>
        <div className="rounded-2xl mx-auto max-w-5xl">{children}</div>
      </div>
      <div
        className="fixed w-full h-full bg-black opacity-50"
        onClick={onClose}
      >
        &nbsp;
      </div>
    </div>
  );
};

const EditSum = observer(({ order }) => {
  const store = useLocalObservable(() => ({
    showAssembly: order.assembly_option !== "no",
    showShipping: order.shipping_price > 0,
    discount: {
      showDiscount: order.discount_percentage || order.discount,
      discountType: order.discount_percentage > 0 ? "%" : "SEK",
    },
    advancePaymentType:
      order.advance_payment_percentage > 0 || order.advance_payment > 0
        ? order.advance_payment_percentage > 0
          ? "%"
          : "SEK"
        : null,
    updatePrices() {
      post("/orders/dry", order)
        .then((res) => {
          order.price = res.data.price;
          order.shipping_price = res.data.shipping_price;
          order.total_price = res.data.total_price;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }));

  return (
    <div
      className="flex flex-col p-4 md:p-7"
      style={{ gridTemplateColumns: "auto max-content" }}
    >
      <span className="flex justify-between items-center pb-4">
        <p>Summa</p>
        <p className="font-medium text-right">
          {(order.price / 100).toLocaleString("sv-SE", {
            minimumFractionDigits: 2,
          })}{" "}
          SEK
        </p>
      </span>
      <div className="flex flex-wrap pb-4 items-center">
        {store.discount.showDiscount ? (
          <>
            <Circle
              type="button"
              onClick={() => {
                order.discount_percentage = null;
                order.discount = null;
                store.discount.showDiscount = false;
                store.updatePrices();
              }}
            >
              <MinusCircle />
            </Circle>
            <p className="mx-4">Rabatt</p>
            <div className="inline-flex sm:ml-4">
              <input
                type="radio"
                id={"discount-percent"}
                name="discount-payment"
                className="hidden"
                onChange={() => {
                  order.discount = 0;
                  order.discount_percentage = 0;
                  store.discount.discountType = "%";
                }}
                checked={store.discount.discountType === "%"}
              />
              <label
                htmlFor="discount-percent"
                className={`${
                  store.discount.discountType === "%"
                    ? "bg-blue-light text-blue border-blue"
                    : "text-grey-dark border-grey bg-white"
                } flex items-center justify-center w-16 h-12 cursor-pointer border rounded-tl-full rounded-bl-full hover:border-blue hover:text-blue hover:bg-blue-light`}
              >
                %
              </label>
              <input
                type="radio"
                id="discount-sek"
                name="discount-payment"
                className="hidden"
                onChange={() => {
                  order.discount = 0;
                  order.discount_percentage = 0;
                  store.discount.discountType = "SEK";
                }}
                checked={store.discount.discountType === "SEK"}
              />
              <label
                htmlFor="discount-sek"
                className={`${
                  store.discount.discountType === "SEK"
                    ? "bg-blue-light text-blue border-blue"
                    : "text-grey-dark border-grey bg-white"
                } flex items-center justify-center w-16 h-12 cursor-pointer -ml-1 border rounded-tr-full rounded-br-full hover:border-blue hover:text-blue hover:bg-blue-light`}
              >
                SEK
              </label>
            </div>
            <div className="flex-1 flex justify-between">
              {store.discount.discountType && (
                <span className="w-48 my-4 sm:my-0 sm:ml-4">
                  <MeasureInput
                    prependLabel={store.discount.discountType}
                    value={
                      (store.discount.discountType === "%"
                        ? order.discount_percentage
                        : order.discount / 100) || ""
                    }
                    onChange={(e) => {
                      if (store.discount.discountType === "%") {
                        order.discount_percentage =
                          parseInt(e.target.value, 10) || 0;
                        order.discount =
                          order.price * (order.discount_percentage / 100);
                      } else {
                        order.discount =
                          parseInt(e.target.value, 10) * 100 || 0;
                      }
                    }}
                    onBlur={store.updatePrices}
                  />
                </span>
              )}
              {store.discount.showDiscount ? (
                <p className="font-medium flex-1 text-right flex items-center justify-end ml-7">
                  {store.discount.discountType === "%"
                    ? (
                        ((order.discount_percentage / 100) * order.price) /
                        100
                      ).toLocaleString("sv-SE", {
                        minimumFractionDigits: 2,
                      })
                    : (order.discount / 100).toLocaleString("sv-SE", {
                        minimumFractionDigits: 2,
                      })}{" "}
                  SEK
                </p>
              ) : (
                <span />
              )}
            </div>
          </>
        ) : (
          <Circle
            type="button"
            onClick={() => {
              store.discount.showDiscount = true;
            }}
            actionText="Lägg till rabatt"
          >
            <AddCircle />
          </Circle>
        )}
      </div>
      <div className="flex flex-col sm:flex-row pb-4 justify-between flex-wrap items-start sm:items-center">
        {store.showShipping ? (
          <>
            <div className="flex items-center">
              <Circle
                type="button"
                onClick={() => {
                  order.shipping_price = null;
                  store.showShipping = false;
                  store.updatePrices();
                }}
              >
                <MinusCircle />
              </Circle>
              <p className="mx-4 sm:mr-7">Frakt</p>
            </div>
            <div className="flex justify-between flex-1 mt-4 sm:mt-0 w-full sm:w-auto">
              <span className="w-full sm:w-48">
                <MeasureInput
                  small
                  prependLabel="SEK"
                  value={order.shipping_price / 100 || ""}
                  onChange={(e) => {
                    order.shipping_price =
                      parseInt(e.target.value, 10) * 100 || 0;
                  }}
                  onBlur={store.updatePrices}
                />
              </span>
              {store.showShipping ? (
                <p className="font-medium flex items-center justify-end pl-4 whitespace-nowrap">
                  +{" "}
                  {(order.shipping_price / 100).toLocaleString("sv-SE", {
                    minimumFractionDigits: 2,
                  })}{" "}
                  SEK
                </p>
              ) : (
                <span />
              )}
            </div>
          </>
        ) : (
          <Circle
            type="button"
            onClick={() => {
              store.showShipping = true;
            }}
            actionText="Lägg till frakt"
          >
            <AddCircle />
          </Circle>
        )}
      </div>
      <div className="flex flex-wrap pb-4 items-center">
        {store.showAssembly ? (
          <>
            <Circle
              type="button"
              onClick={() => {
                order.assembly_price = 0;
                order.assembly_option = "no";
                store.showAssembly = false;
                store.updatePrices();
              }}
            >
              <MinusCircle />
            </Circle>
            <p className="mx-4">Montering</p>
            <Select
              items={assemblyItems}
              onChange={(val) => {
                order.assembly_price = null;
                order.assembly_option = val.value;
                store.updatePrices();
              }}
              initialItem={order.assembly_option}
              className="mt-4 sm:mt-0"
            />
            {order.assembly_option === "fixed_price" && (
              <span className="w-full sm:w-48 mt-4 sm:mt-0 sm:ml-4">
                <MeasureInput
                  small
                  prependLabel="SEK"
                  value={order.assembly_price / 100 || ""}
                  onChange={(e) => {
                    order.assembly_price =
                      parseInt(e.target.value, 10) * 100 || 0;
                  }}
                  onBlur={store.updatePrices}
                />
              </span>
            )}
          </>
        ) : (
          <Circle
            type="button"
            onClick={() => {
              store.showAssembly = true;
            }}
            actionText="Lägg till montering"
          >
            <AddCircle />
          </Circle>
        )}
      </div>
      <span />
      <div className="flex flex-wrap items-center">
        <p>Förskottsbetalning</p>
        <div className="ml-4 sm:ml-7 flex flex-wrap">
          <div className="inline-flex">
            <input
              type="radio"
              id={"advanced-payment-percent"}
              name="advanced-payment"
              className="hidden"
              onChange={() => {
                order.advance_payment_percentage = 0;
                order.advance_payment = 0;
                store.advancePaymentType = "%";
              }}
              checked={store.advancePaymentType === "%"}
            />
            <label
              htmlFor="advanced-payment-percent"
              className={`${
                store.advancePaymentType === "%"
                  ? "bg-blue-light text-blue border-blue"
                  : "text-grey-dark border-grey bg-white"
              } flex items-center justify-center w-16 h-12 cursor-pointer border rounded-tl-full rounded-bl-full hover:border-blue hover:text-blue hover:bg-blue-light`}
            >
              %
            </label>
            <input
              type="radio"
              id="advanced-payment-sek"
              name="advanced-payment"
              className="hidden"
              onChange={() => {
                order.advance_payment_percentage = 0;
                order.advance_payment = 0;
                store.advancePaymentType = "SEK";
              }}
              checked={store.advancePaymentType === "SEK"}
            />
            <label
              htmlFor="advanced-payment-sek"
              className={`${
                store.advancePaymentType === "SEK"
                  ? "bg-blue-light text-blue border-blue"
                  : "text-grey-dark border-grey bg-white"
              } flex items-center justify-center w-16 h-12 cursor-pointer -ml-1 border rounded-tr-full rounded-br-full hover:border-blue hover:text-blue hover:bg-blue-light`}
            >
              SEK
            </label>
          </div>
        </div>
        {store.advancePaymentType && (
          <div className="flex-1 flex items-center justify-between sm:pl-4 w-full">
            {store.advancePaymentType && (
              <span className="w-48 my-4 sm:my-0">
                <MeasureInput
                  value={
                    (store.advancePaymentType === "%"
                      ? order.advance_payment_percentage
                      : order.advance_payment / 100) || ""
                  }
                  onChange={(e) => {
                    if (store.advancePaymentType === "%") {
                      order.advance_payment_percentage =
                        parseInt(e.target.value, 10) || 0;
                      order.advance_payment =
                        (order.total_price * order.advance_payment_percentage) /
                        100;
                    } else {
                      order.advance_payment =
                        parseInt(e.target.value, 10) * 100 || 0;
                    }
                  }}
                  onBlur={store.updatePrices}
                  prependLabel={store.advancePaymentType}
                  small
                />
              </span>
            )}
            {store.advancePaymentType ? (
              <p className="font-medium flex items-center justify-end pl-7">
                {store.advancePaymentType === "%"
                  ? (
                      ((order.total_price / 100) *
                        order.advance_payment_percentage) /
                      100
                    ).toLocaleString("sv-SE", {
                      minimumFractionDigits: 2,
                    })
                  : (order.advance_payment / 100).toLocaleString("sv-SE", {
                      minimumFractionDigits: 2,
                    })}
                {store.advancePaymentType && " SEK"}
              </p>
            ) : (
              <span />
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <p className="font-medium text-lg pt-6">Totalsumma</p>
        <p className="font-medium text-lg self-end text-right">
          {(order.total_price / 100).toLocaleString("sv-SE", {
            minimumFractionDigits: 2,
          })}{" "}
          SEK
        </p>
      </div>
    </div>
  );
});

const EditOther = observer(
  ({ products, existingProducts, type, onDelete, onAdd, onUpdate }) => {
    return (
      <div className="flex flex-col md:p-7 p-4">
        {existingProducts &&
          existingProducts.map((prod, i) => (
            <ProductSearchRow
              key={i}
              type={type}
              product={prod}
              onDelete={() => {
                if (prod.id) {
                  onDelete(prod);
                }
                existingProducts.splice(i, 1);
              }}
              onAdd={onAdd}
              onUpdate={onUpdate}
              products={products}
            />
          ))}
        <div className="mr-auto pt-4">
          <Circle
            type="button"
            onClick={() => {
              existingProducts.push({});
            }}
          >
            <AddCircle />
          </Circle>
        </div>
      </div>
    );
  }
);

export default SingleOrder;
