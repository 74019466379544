import React from "react";

export const Pencil = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Pencil</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zm56.56-56.56l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z"
    />
  </svg>
);
export const DownArrow = ({ className, ...props }) => (
  <svg className={className || "w-5"} viewBox="0 0 512 512" {...props}>
    <title>Chevron Down</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={48}
      d="M112 184l144 144 144-144"
    />
  </svg>
);
export const UpArrow = ({ className, ...props }) => (
  <svg className={className || "w-4"} viewBox="0 0 512 512" {...props}>
    <title>Chevron Up</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={48}
      d="M112 328l144-144 144 144"
    />
  </svg>
);
export const Save = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Save</title>
    <path
      d="M380.93 57.37A32 32 0 00358.3 48H94.22A46.21 46.21 0 0048 94.22v323.56A46.21 46.21 0 0094.22 464h323.56A46.36 46.36 0 00464 417.78V153.7a32 32 0 00-9.37-22.63zM256 416a64 64 0 1164-64 63.92 63.92 0 01-64 64zm48-224H112a16 16 0 01-16-16v-64a16 16 0 0116-16h192a16 16 0 0116 16v64a16 16 0 01-16 16z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
    />
  </svg>
);
export const ArrowForward = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Arrow Forward</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={48}
      d="M268 112l144 144-144 144m124-144H100"
    />
  </svg>
);
export const Loading = (props) => (
  <svg
    style={{
      margin: "auto",
      background: "#fff",
    }}
    width={50}
    height={50}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    display="block"
    {...props}
  >
    <rect x={47} y={24} rx={3} ry={6} width={6} height={12}>
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.9166666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.8333333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.75s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.6666666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.5833333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.5s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.4166666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.3333333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.25s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.16666666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.08333333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={47}
      y={24}
      rx={3}
      ry={6}
      width={6}
      height={12}
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
);
export const AddCircle = ({ className, ...props }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || "h-4 w-4"}
    {...props}
  >
    <path
      d="M9 1V17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 9H1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Exit = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Exit</title>
    <path
      d="M320 176v-40a40 40 0 00-40-40H88a40 40 0 00-40 40v240a40 40 0 0040 40h192a40 40 0 0040-40v-40m64-160l80 80-80 80m-193-80h273"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
    />
  </svg>
);

export const Person = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Person</title>
    <path
      d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
    />
    <path
      d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={32}
    />
  </svg>
);
export const Settings = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Settings</title>
    <path
      d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
    />
  </svg>
);
export const Checkmark = ({ className, ...props }) => (
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || "h-4 w-4"}
    {...props}
  >
    <path
      d="M22 1L7.3 18L1 11.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);
export const MinusCircle = ({ className, ...props }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || "h-4 w-4"}
    {...props}
  >
    <path
      d="M12.6 2.7V0H5.4V2.7H0V4.5H1.8V18H16.2V4.5H18V2.7H12.6ZM7.2 1.8H10.8V2.7H7.2V1.8ZM14.4 16.2H3.6V4.5H14.4V16.2Z"
      fill="currentColor"
    />
    <path
      d="M9.89961 6.2998H8.09961V14.3998H9.89961V6.2998Z"
      fill="currentColor"
    />
    <path
      d="M7.20039 6.2998H5.40039V14.3998H7.20039V6.2998Z"
      fill="currentColor"
    />
    <path
      d="M12.6003 6.2998H10.8003V14.3998H12.6003V6.2998Z"
      fill="currentColor"
    />
  </svg>
);
export const Upload = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Cloud Upload</title>
    <path
      d="M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M320 255.79l-64-64-64 64m64 192.42V207.79"
    />
  </svg>
);
export const CheckMarkNoBorder = ({ className, color, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Checkmark</title>
    <path
      fill="none"
      stroke={color || "currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={50}
      d="M416 128L192 384l-96-96"
    />
  </svg>
);
export const RightArrow = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Chevron Forward</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={48}
      d="M184 112l144 144-144 144"
    />
  </svg>
);
export const Eye = ({ className, ...props }) => (
  <svg className={className || "h-5 w-5"} viewBox="0 0 512 512" {...props}>
    <title>Eye</title>
    <path
      d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
    />
    <circle
      cx={256}
      cy={256}
      r={80}
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={32}
    />
  </svg>
);
export const Menu = ({ className, ...props }) => (
  <svg className={className || "w-5 h-5"} viewBox="0 0 512 512" {...props}>
    <title>Menu</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={32}
      d="M80 160h352M80 256h352M80 352h352"
    />
  </svg>
);
export const Cross = ({ className, ...props }) => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || "h-6 w-6"}
    {...props}
  >
    <path
      d="M6.83887 6.54883L19.6453 19.3553"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6453 6.54656L6.83887 19.353"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Calendar = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className || "w-6"}
    viewBox="0 0 512 512"
    {...props}
  >
    <title>Calendar</title>
    <rect
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={32}
      x={48}
      y={80}
      width={416}
      height={384}
      rx={48}
    />
    <circle cx={296} cy={232} r={24} />
    <circle cx={376} cy={232} r={24} />
    <circle cx={296} cy={312} r={24} />
    <circle cx={376} cy={312} r={24} />
    <circle cx={136} cy={312} r={24} />
    <circle cx={216} cy={312} r={24} />
    <circle cx={136} cy={392} r={24} />
    <circle cx={216} cy={392} r={24} />
    <circle cx={296} cy={392} r={24} />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={32}
      strokeLinecap="round"
      d="M128 48v32m256-32v32"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M464 160H48"
    />
  </svg>
);

export const List = ({ className, ...props }) => (
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || "w-4"}
    {...props}
  >
    <path
      d="M10.1984 16.0568C10.1481 15.8551 10.0476 15.6534 9.92197 15.5021L7.88662 12.7283L6.73063 13.6109L6.70557 16.7629L7.05735 17.242L8.53988 22.6634C8.76599 23.4704 9.51988 24.0755 10.3491 23.9747C11.4045 23.8739 12.0577 22.84 11.8066 21.8566L10.1984 16.0568Z"
      fill="currentColor"
    />
    <path
      d="M20.2748 9.77818H14.7968C14.4451 9.77818 14.1686 10.0304 14.1183 10.3581L15.7015 11.4928C16.8071 12.2744 17.0333 13.8377 16.2543 14.9221C15.8021 15.5525 15.0482 15.956 14.2692 15.956H14.0933V15.9812C14.0933 16.3593 14.42 16.6872 14.7969 16.6872H20.2748C20.6517 16.6872 20.9784 16.3593 20.9784 15.9812V10.4841C20.9784 10.106 20.6769 9.77808 20.2748 9.77808L20.2748 9.77818Z"
      fill="currentColor"
    />
    <path
      d="M15.4001 14.3423C15.8523 13.7119 15.7016 12.8293 15.0734 12.3755L12.9877 10.8625C13.038 8.31572 12.9124 7.86175 12.8621 7.7105C12.7867 7.43312 12.46 6.44971 11.857 5.61759L11.1785 4.70983C10.8267 4.23068 10.2739 3.90284 9.67074 3.82719C9.06762 3.75155 8.46463 3.9533 8.01223 4.35681L2.96191 8.69391C2.58506 9.02177 2.35879 9.47556 2.35879 9.9547L2.28342 16.3847L0.147432 21.68C-0.204179 22.5373 0.197738 23.5207 1.05223 23.8737C1.90654 24.2267 2.88654 23.7981 3.23834 22.9408L5.49978 17.3429C5.57516 17.1412 5.62546 16.9394 5.62546 16.7377L5.67577 13.0561L10.224 9.57628C10.224 10.1562 10.224 10.8622 10.1989 11.4926C10.1738 11.9464 10.4 12.4004 10.7768 12.6778L13.4656 14.6194C13.5661 14.6951 13.6667 14.7455 13.7671 14.7707C14.3199 15.0483 15.0235 14.8718 15.4003 14.3422L15.4001 14.3423Z"
      fill="currentColor"
    />
    <path
      d="M15.5105 0.11693C17.1325 0.495446 18.1418 2.12172 17.7646 3.74944C17.3874 5.37712 15.7666 6.38994 14.1445 6.01138C12.5224 5.63283 11.5133 4.00642 11.8906 2.37869C12.2678 0.750971 13.8884 -0.261622 15.5105 0.11693Z"
      fill="currentColor"
    />
    <path
      d="M17.938 17.8725C17.762 17.6203 17.3349 17.6203 17.1591 17.8725L15.953 19.5115C15.8525 19.6627 15.8273 19.8645 15.9027 20.0157C15.978 20.167 16.154 20.2931 16.3298 20.2931H17.0334V23.4956C17.0334 23.7729 17.2595 23.9747 17.5109 23.9747C17.7873 23.9747 17.9884 23.7478 17.9884 23.4956L17.9882 20.2679H18.7168C18.8928 20.2679 19.0686 20.1672 19.144 19.9906C19.2194 19.814 19.2194 19.6375 19.0937 19.4863L17.938 17.8725Z"
      fill="currentColor"
    />
  </svg>
);

export const Print = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className || "w-4"}
    viewBox="0 0 512 512"
    {...props}
  >
    <title>Print</title>
    <path
      d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={32}
    />
    <rect
      x={128}
      y={240}
      width={256}
      height={208}
      rx={24.32}
      ry={24.32}
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={32}
    />
    <path
      d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={32}
    />
    <circle cx={392} cy={184} r={24} />
  </svg>
);

export const PDF = ({ className, ...props }) => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || "w-4"}
    {...props}
  >
    <path
      d="M4.92217 12.6366C4.92217 12.1074 4.53011 11.78 3.85028 11.78C3.5627 11.78 3.37981 11.8052 3.27515 11.8305V13.5689C3.37964 13.594 3.53665 13.594 3.71955 13.594C4.47777 13.594 4.92219 13.2413 4.92219 12.6366L4.92217 12.6366Z"
      fill="currentColor"
    />
    <path
      d="M9.1575 11.8052C8.84384 11.8052 8.66074 11.8303 8.53003 11.8556V15.6599C8.63452 15.685 8.84368 15.685 9.0005 15.685C10.2292 15.685 11.0396 15.03 11.0396 13.6443C11.0659 12.4604 10.3339 11.8052 9.15754 11.8052L9.1575 11.8052Z"
      fill="currentColor"
    />
    <path
      d="M18.7777 6.41392L11.0657 0.190934C10.9612 0.090233 10.8042 0.0397949 10.6474 0.0397949H2.41246C1.10534 0.0397949 0.0334473 1.22393 0.0334473 2.68524V19.3135C0.0334473 20.7747 1.10534 21.9589 2.41246 21.9589H16.6078C17.915 21.9589 18.9869 20.7748 18.9869 19.3135V6.89268C19.0131 6.71641 18.9084 6.53997 18.7777 6.41395L18.7777 6.41392ZM17.706 19.3134C17.706 20.0693 17.2093 20.6991 16.6341 20.6991L2.4126 20.6989C1.83746 20.6989 1.34071 20.0691 1.34071 19.3132V17.7259H17.6798V19.3134L17.706 19.3134ZM1.94208 16.6679V10.9236C2.3604 10.8481 2.96164 10.7976 3.79829 10.7976C4.63494 10.7976 5.23618 10.9488 5.6545 11.2764C6.04657 11.5787 6.30807 12.0574 6.30807 12.6117C6.30807 13.1911 6.0989 13.6698 5.75898 13.9974C5.28851 14.4257 4.60866 14.6021 3.79829 14.6021C3.6152 14.6021 3.45838 14.6021 3.32782 14.577V16.6681L1.94208 16.6679ZM7.17058 16.6428V10.9237C7.66734 10.8481 8.3209 10.7977 9.02679 10.7977C10.1771 10.7977 10.9091 10.9993 11.5103 11.4276C12.1378 11.881 12.5298 12.5866 12.5298 13.6194C12.5298 14.728 12.1115 15.509 11.5364 15.9877C10.9089 16.4916 9.94169 16.7436 8.73904 16.7436C8.00705 16.7185 7.51034 16.6932 7.17058 16.6429V16.6428ZM16.9479 13.2415V14.3248H14.7781V16.6677H13.4186L13.4188 10.8479H17.1047V11.9311H14.778V13.2664H16.9479L16.9479 13.2415ZM17.7061 9.71439H1.36704V2.68519C1.36704 1.92931 1.86379 1.29946 2.43893 1.29946H10.1771L10.1769 7.2705H17.706L17.7061 9.71439Z"
      fill="currentColor"
    />
  </svg>
);

export const Mail = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className || "w-6"}
    viewBox="0 0 512 512"
    {...props}
  >
    <title>Mail</title>
    <rect
      x="48"
      y="96"
      width="416"
      height="320"
      rx="40"
      ry="40"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M112 160l144 112 144-112"
    />
  </svg>
);

export const Phone = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className || "w-6"}
    viewBox="0 0 512 512"
    {...props}
  >
    <title>Phone Portrait</title>
    <rect
      x="128"
      y="16"
      width="256"
      height="480"
      rx="48"
      ry="48"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <path
      d="M176 16h24a8 8 0 018 8h0a16 16 0 0016 16h64a16 16 0 0016-16h0a8 8 0 018-8h24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
  </svg>
);

export const Search = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className || "w-6"}
    viewBox="0 0 512 512"
  >
    <title>Search</title>
    <path
      d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="50"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="50"
      d="M338.29 338.29L448 448"
    />
  </svg>
);

export const Information = ({ className }) => (
  <svg
    width="4"
    height="20"
    viewBox="0 0 4 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || "h-5 w-5"}
  >
    <path
      d="M0.000843188 2.10838C-0.0107231 1.70038 0.0969285 1.29814 0.309993 0.953231C0.523057 0.608324 0.831823 0.336474 1.1967 0.172532C1.56158 0.00859037 1.96594 -0.0399689 2.35794 0.0330814C2.74994 0.106132 3.1117 0.29746 3.39685 0.582537C3.682 0.867614 3.87754 1.23345 3.95839 1.63312C4.03924 2.0328 4.00172 2.44811 3.85064 2.8258C3.69956 3.20349 3.44181 3.52635 3.11044 3.75297C2.77906 3.9796 2.38917 4.09966 1.99074 4.09777C1.73181 4.10397 1.47427 4.05712 1.23326 3.95995C0.992239 3.86279 0.772603 3.71728 0.587237 3.53196C0.401871 3.34664 0.254514 3.12525 0.153816 2.88079C0.0531187 2.63632 0.00110878 2.37371 0.000843188 2.10838ZM0.137209 5.66031H3.8468V20H0.137209V5.66031Z"
      fill="currentColor"
    />
  </svg>
);

export const MeasurementArrow = ({ className }) => (
  <svg
    width="5"
    height="8"
    viewBox="0 0 5 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.35355 3.64645C4.54882 3.84171 4.54882 4.15829 4.35355 4.35355L1.17157 7.53553C0.976311 7.7308 0.659728 7.7308 0.464466 7.53553C0.269204 7.34027 0.269204 7.02369 0.464466 6.82843L3.29289 4L0.464466 1.17157C0.269204 0.976311 0.269204 0.659728 0.464466 0.464466C0.659728 0.269204 0.976311 0.269204 1.17157 0.464466L4.35355 3.64645ZM4 4.5H3V3.5H4V4.5Z"
      fill="#1D1211"
    />
  </svg>
);
