import { store } from "./store";

export function logout() {
  localStorage.clear();
  store.token = null;
  store.refreshToken = null;
  store.productCategories = null;
  store.activeUser = null;
  store.figures = null;
}

export function setTokens(data) {
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
  store.token = data.access_token;
}
export function validateEmail(email) {
  const mailRegexp =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return mailRegexp.test(email);
}

export function validatePhone(phone) {
  const phoneRegexp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
  return phoneRegexp.test(phone);
}

export function setProductGroups(productCategories) {
  localStorage.setItem("product_groups", JSON.stringify(productCategories));
  store.productCategories = productCategories;
}

export function setActiveUser(user) {
  localStorage.setItem("active_user", JSON.stringify(user));
  store.activeUser = user;
}

export function setFigures(figures) {
  localStorage.setItem("figures", JSON.stringify(figures));
  store.figures = figures;
}
export function getOrderStatus(status) {
  return status.split(".")[0];
}

export function getBadge(label) {
  const splittedString = label.split(".");
  if (splittedString.length > 1) {
    switch (splittedString[1]) {
      case "unhandled":
        return { label: "Obehandlad", color: "orange-light" };
      case "edit":
        return { label: "Edit", color: "orange-light" };
      case "sent":
        return { label: "Skickad", color: "blue-light" };
      case "changed":
        return { label: "Ändrad", color: "orange-light" };
      case "confirmed":
        return { label: "Godkänd", color: "orange-light" };
      case "ready":
        return { label: "Redo", color: "green-light" };
      case "paused":
        return { label: "Pausad", color: "blue-light" };
      case "producing":
        return { label: "Produceras", color: "orange-light" };
      case "produced":
        return { label: "Producerad", color: "green-light" };
      default:
        return null;
    }
  }
  return { label: "Avslutad", color: "green-light" };
}

export function getPaymentBadge(label) {
  switch (label) {
    case "":
      return { label: "Obetald", color: "grey-light" };
    case "invoiced":
      return { label: "Fakturerad", color: "blue-light" };
    case "prepaid":
      return { label: "Förskottsbetald", color: "blue-light" };
    case "paid":
      return { label: "Betald", color: "green-light" };
    default:
      return null;
  }
}

export const validateOrderData = (orderData) => {
  const errors = {};
  // Validate orderData
  if (orderData.wardrobe) {
    if (!orderData.seller_id && !orderData.klarna_order_id) {
      errors.seller = "Måste välja säljare.";
    }
  }

  return errors;
};

// https://css-tricks.com/converting-color-spaces-in-javascript/#hex-to-hsl
export const hexToHSL = (H, darken = 0) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return (
    "hsl(" +
    h +
    ", " +
    s +
    "%, " +
    (l - darken >= 0 ? l - darken : l + darken) +
    "%)"
  );
};

export const getShippingText = (option) => {
  switch (option) {
    case "ship":
      return "Kund vill ha leverans till ort";
    case "assembly":
      return "Kund vill ha montering";
    case "pickup_skövde":
      return "Kund hämtar i Skövde";
    case "pickup_örebro":
      return "Kund hämtar i Örebro";
    default:
      "";
  }
};
