import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { post } from "../../api";
import { Circle, MeasureInput, ToggleBox } from "../../components";
import { Checkmark } from "../../icons";

const AddCampaign = observer(() => {
  const navigate = useNavigate();
  const state = useLocalObservable(() => ({
    description: "",
    setDescription(description) {
      state.description = description;
    },
    discount: "",
    setDiscount(discount) {
      if ((discount > 0 && discount <= 100) || discount === "") {
        state.discount = discount;
      }
    },
    isLoading: false,
    setIsLoading(isLoading) {
      state.isLoading = isLoading;
    },
    async handleSubmit() {
      if (
        (state.description === "" && state.discount === "") ||
        state.isLoading
      ) {
        return;
      }

      const data = {
        description: state.description,
        discount_percentage: parseInt(state.discount, 10),
      };

      try {
        state.setIsLoading(true);
        await post("/campaigns", data);
        navigate("/settings");
      } catch (error) {
        console.error(error.message);
      } finally {
        state.setIsLoading(false);
      }
    },
  }));
  return (
    <ToggleBox className="mt-8" title="Kampanj" toggle={false}>
      <div className="p-4 md:py-7 flex flex-wrap">
        <div className="px-3 flex-1">
          <span className="text-grey-dark font-medium">Beskrivning</span>
          <textarea
            className="mt-2 min-w-96"
            value={state.description}
            onChange={(e) => state.setDescription(e.target.value)}
          />
        </div>

        <div className="px-3 w-96">
          <MeasureInput
            label="Rabatt"
            prependLabel="%"
            value={state.discount}
            onChange={(e) => state.setDiscount(e.target.value)}
          />
        </div>
      </div>
      <div className="flex items-center px-7 pb-7">
        <Circle type="button" onClick={state.handleSubmit}>
          <Checkmark />
        </Circle>
      </div>
    </ToggleBox>
  );
});

export default AddCampaign;
