import { useLocalObservable, observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { DownArrow } from "../../icons";
import PropTypes from "prop-types";

const ToggleRow = observer(
  ({
    closed,
    title = "",
    children,
    link,
    linkTitle,
    className,
    toggle = true,
    ...props
  }) => {
    const store = useLocalObservable(() => ({
      isOpen: closed,
      toggleOpen() {
        store.isOpen = !store.isOpen;
      },
    }));
    return (
      <div
        className={`flex flex-col row overflow-hidden ${className}`}
        {...props}
      >
        <div
          onClick={() => (toggle ? store.toggleOpen() : null)}
          className={`row-header ${!store.isOpen || !children ? "closed" : ""}`}
        >
          <p>{title}</p>
          {link && linkTitle && (
            <Link className="text-grey-dark ml-6 text-xs font-light" to={link}>
              {linkTitle}
            </Link>
          )}
          <DownArrow
            className={`ml-auto w-4 transform ${
              store.isOpen ? "rotate-180" : ""
            } ${!toggle ? "invisible" : ""}`}
          />
        </div>
        {store.isOpen && children}
      </div>
    );
  }
);

ToggleRow.propTypes = {
  closed: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  className: PropTypes.string,
  toggle: PropTypes.bool,
};

export default ToggleRow;
