import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect } from "react";
import {
  AddCircle,
  Cross,
  Loading,
  MinusCircle,
  Pencil,
  Save,
} from "../../icons";
import { Circle, MeasureInput, ToggleBox, ToggleRow } from "../../components";
import { get, post, put, deleteRequest } from "../../api";

const PRODUCT_TYPE_AREA = "area_color";
const PRODUCT_TYPE_PROFILE = "profile_color";
const PRODUCT_TYPE_SPECIAL = "special";
const PRODUCT_TYPE_LIGHTING = "lighting";
const PRODUCT_TYPE_STAND_AND_WALLMOUNTED = "stand_and_wallmounted";
const PRODUCT_TYPE_INTERIOR = "interior";
import { setProductGroups } from "../../utils";
import { API_URL, ColorNames } from "../../constants";
import { useLocation } from "react-router-dom";

const Products = observer(({ productType, showPriceGroups }) => {
  const location = useLocation();
  const store = useLocalObservable(() => ({
    isEditing: false,
    categories: {
      base: [],
      configurator: [],
      areas: [],
      profiles: [],
      special: [],
      lighting: [],
      stand_and_wallmounted: [],
      interior: [],
    },
    priceGroups: [],
    toggleIsEditing(cat) {
      cat.isEditing = !cat.isEditing;
    },
    setGroups(groups) {
      setProductGroups(groups);
      const categories = this.categories;

      categories.base = groups.filter((group) => group.category === "base");
      categories.configurator = groups.filter(
        (group) =>
          group.category === "interior_section" ||
          group.category === "interior_accessories" ||
          group.category === "other_accessories"
      );

      categories.areas = groups.filter(
        (obj) => obj.category === PRODUCT_TYPE_AREA
      );
      categories.profiles = groups.filter(
        (obj) => obj.category === PRODUCT_TYPE_PROFILE
      );
      categories.special = groups.filter(
        (obj) => obj.category === PRODUCT_TYPE_SPECIAL
      );
      categories.lighting = groups.filter(
        (obj) => obj.category === PRODUCT_TYPE_LIGHTING
      );
      categories.stand_and_wallmounted = groups.filter(
        (obj) => obj.category === PRODUCT_TYPE_STAND_AND_WALLMOUNTED
      );
      categories.interior = groups.filter(
        (obj) => obj.category === PRODUCT_TYPE_INTERIOR
      );
    },
    setProducts(products) {
      Object.keys(this.categories).map((cat) => {
        this.categories[cat].map((group) => {
          group.children = products.filter(
            (prod) => prod.group_id === group.id
          );
        });
      });
    },
    setPriceGroups(data) {
      this.priceGroups = data;
    },
  }));

  useEffect(() => {
    const limit = 100;
    get("/product_groups?limit=" + limit).then((res) => {
      store.setGroups(res.data.results);
      get("/products?limit=" + limit).then(async (res) => {
        const prods = res.data.results;
        if (res.data.count > limit) {
          let nextPage = true;
          let page = 2;
          while (nextPage) {
            await get("/products?limit=" + limit + "&page=" + page).then(
              (res) => {
                prods.push(...res.data.results);
                if (res.data.results.length === limit) {
                  page += 1;
                } else {
                  nextPage = false;
                }
              }
            );
          }
        }
        store.setProducts(prods);
      });
    });
    get("/price_groups").then((res) => {
      res.data.sort((a, b) => {
        return parseInt(a.name, 10) - parseInt(b.name, 10);
      });
      store.setPriceGroups(res.data);
    });
  }, [location.key]);
  return (
    <div>
      {productType &&
        productType.value === PRODUCT_TYPE_AREA &&
        !showPriceGroups && (
          <AreaProducts categories={store.categories.areas} store={store} />
        )}
      {productType && productType.value === PRODUCT_TYPE_PROFILE && (
        <ProfileProducts profiles={store.categories.profiles} store={store} />
      )}
      {productType && productType.value === PRODUCT_TYPE_INTERIOR && (
        <FurnitureFittings
          categories={store.categories.interior}
          productType={productType.value}
        />
      )}

      {productType && productType.value === PRODUCT_TYPE_SPECIAL && (
        <SpecialProducts categories={store.categories.special} />
      )}

      {productType && productType.value === PRODUCT_TYPE_LIGHTING && (
        <Lights categories={store.categories.lighting} />
      )}
      {productType &&
        productType.value === PRODUCT_TYPE_STAND_AND_WALLMOUNTED && (
          <MountedFurnishings
            categories={store.categories.stand_and_wallmounted}
          />
        )}
      {productType && productType.value === "base" && (
        <FurnitureFittings
          categories={store.categories.base}
          productType={productType.value}
        />
      )}
      {productType && productType.value === "configurator" && (
        <ConfiguratorProducts categories={store.categories.configurator} />
      )}

      {!productType && <p>Välj en produkttyp</p>}

      {showPriceGroups && <PriceList store={store} />}
    </div>
  );
});

const getDimensionsString = (conf) => {
  const section_type = conf.section_type || "";
  const width = conf.width || 0;
  const depth = conf.depth || 0;
  const height = conf.height || 0;
  if (width < 1 && depth < 1 && height < 1) {
    return "";
  }
  return `${section_type} ${width}x${depth}x${height}`;
};

const ConfiguratorProducts = observer(({ categories }) => {
  return categories.map((category) => (
    <ToggleBox
      title={category.name}
      key={category.id}
      className="mb-4 md:mb-7 overflow-hidden"
    >
      {/* ToggleRow */}
      <div className="flex flex-col xl:hidden">
        {category.children &&
          category.children.map((item, i) => {
            return (
              <React.Fragment key={item.id}>
                <ToggleRow key={i} title={item.name}>
                  {item.configurations.length > 0 &&
                    item.configurations.map((conf, i) => (
                      <div
                        key={i}
                        className="w-full flex flex-col text-sm border-b border-grey"
                      >
                        <div className="w-full flex border-b border-grey">
                          <p className="w-24 px-4 py-2 text-opacity-50 uppercase text-right">
                            Art.nr
                          </p>
                          <p className="px-4 py-2">
                            {conf.meta.article_number}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                            Mått
                          </p>
                          <div className="px-4 py-2 flex flex-col">
                            <p>{getDimensionsString(conf)}</p>
                          </div>
                        </div>
                        {conf.color && (
                          <div className="flex">
                            <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                              Färg
                            </p>
                            <p className="px-4 py-2">
                              {ColorNames[conf.color] || conf.color}
                            </p>
                          </div>
                        )}
                        <div className="flex">
                          <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                            Pris
                          </p>
                          {conf.price ? (
                            <p className="px-4 py-2">{conf.price} SEK</p>
                          ) : (
                            <p className="px-4 py-2">{item.price} SEK</p>
                          )}
                        </div>
                      </div>
                    ))}
                  <div className="flex flex-col justify-center items-end p-4">
                    <Circle
                      type="link"
                      to={`/produkter/configurator/${item.id}/edit`}
                    >
                      <Pencil />
                    </Circle>
                  </div>
                </ToggleRow>
              </React.Fragment>
            );
          })}
      </div>
      {/* Table */}
      <div className="hidden xl:grid grid-cols-6-auto">
        <p className="bg-grey-lighter font-semibold text-sm py-2 pl-7">
          Art.nr
        </p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4">
          Produkt
        </p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4">Mått</p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4">Färg</p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4 text-right">
          Pris
        </p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4"></p>

        {category.children &&
          category.children.map((item, i) => {
            const isLast = i !== category.children.length - 1;
            const itemCLasses = `py-4 justify-start flex flex-col col-auto ${
              isLast ? "border-b border-grey" : ""
            }`;
            if (!item.configurations) return null;
            return (
              <React.Fragment key={item.id}>
                <div className={`pl-7 ${itemCLasses}`}>
                  {item.configurations.map((conf) => (
                    <p key={conf.id}>{conf.meta.article_number}</p>
                  ))}
                </div>
                <p className={`pl-4 ${itemCLasses}`}>{item.name}</p>
                <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                  {item.configurations.map((conf) => {
                    return <p key={conf.id}>{getDimensionsString(conf)}</p>;
                  })}
                </div>
                <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                  {item.configurations.map((conf) => (
                    <p key={conf.id}>{ColorNames[conf.color] || conf.color}</p>
                  ))}
                </div>
                <div className={`px-4 text-right flex flex-col ${itemCLasses}`}>
                  {item.configurations.map((conf) => (
                    <p key={conf.id}>{conf.price / 100} SEK</p>
                  ))}
                </div>
                <div
                  className={`flex flex-col justify-center items-end py-2 pr-7 ${
                    isLast ? "border-b border-grey" : ""
                  } `}
                >
                  <Circle
                    className="self-end"
                    type="link"
                    to={`/produkter/configurator/${item.id}/edit`}
                  >
                    <Pencil />
                  </Circle>
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </ToggleBox>
  ));
});

const AreaProducts = observer(({ categories }) => (
  <div>
    {categories.map((category) => (
      <ToggleBox
        category={category.name}
        key={category.id}
        title={category.name}
        className="mb-4 md:mb-7"
      >
        <div className="flex flex-wrap px-2 pt-4 md:px-4 md:pt-7 md:pb-3">
          {category.children &&
            category.children.map((child) => (
              <div
                product={child.name}
                className="px-2 w-1/2 max-w-44 mb-4"
                key={child.id}
              >
                <div className="w-full h-32 border-grey border rounded-lg overflow-hidden relative">
                  {child.image && (
                    <img
                      className="flex flex-1 w-full h-full bg-cover bg-center bg-no-repeat"
                      src={API_URL + child.image}
                    />
                  )}
                  {!child.image && (
                    <div
                      className="flex flex-1 w-full h-full"
                      style={{
                        backgroundColor: child.color ? child.color : "#fff",
                      }}
                    ></div>
                  )}
                  <div className="opacity-0 transition-opacity hover:opacity-100 absolute h-full inset-0 w-full bg-black bg-opacity-25 z-0">
                    <div className="h-full w-full pb-6 flex items-center justify-center">
                      <Circle
                        type="link"
                        to={`/produkter/${category.category}/${child.id}/edit`}
                      >
                        <Pencil />
                      </Circle>
                    </div>
                  </div>
                  <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center px-3">
                    <p>{child.name}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </ToggleBox>
    ))}
  </div>
));

const ProfileProducts = observer(({ profiles }) => (
  <div>
    {profiles.map((profile) => (
      <ToggleBox
        profil={profile.name}
        className="mb-7"
        key={profile.id}
        title={profile.name}
      >
        <div className="flex p-2 py-4 flex-wrap">
          <div className="flex flex-wrap w-full pb-3">
            <div className="w-full md:w-1/2 sm:max-w-44 px-2 pb-4">
              {profile.image && (
                <img
                  className="w-full h-32 border-grey border rounded-lg overflow-hidden relative bg-cover bg-center bg-no-repeat"
                  src={API_URL + profile.image}
                />
              )}
              {!profile.image && (
                <div className="w-full h-32 border-grey border rounded-lg overflow-hidden bg-white"></div>
              )}
            </div>
            <div className="flex flex-col justify-end px-2 pb-4">
              <p className="mb-3">Pris</p>
              <div className="rounded-2xl border-grey border bg-grey-lighter items-center min-w-48 flex">
                {profile.price === 0 ? (
                  <p className="py-4 px-6">Standard</p>
                ) : (
                  <>
                    <p className="flex-1 text-right whitespace-nowrap py-4 px-6">
                      + {profile.price / 100}
                    </p>
                    <div className="rounded-r-2xl p-4 border-l bg-grey border-grey">
                      <p>SEK / dörr</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {profile.children && <p className="px-2 font-medium mb-2">Färger</p>}
          <div className="flex flex-wrap w-full">
            {profile.children &&
              profile.children.map((color) => (
                <div className="px-2 pb-4 w-1/2 max-w-44" key={color.id}>
                  <div className="w-full h-32 border-grey border rounded-lg overflow-hidden relative">
                    <div
                      className="flex flex-1 w-full h-full"
                      style={{
                        background: `url(${
                          API_URL + color.image
                        }) center center no-repeat`,
                        backgroundColor: color.color,
                        backgroundSize: "cover",
                      }}
                    >
                      &nbsp;
                    </div>
                    <div className="opacity-0 transition-opacity hover:opacity-100 absolute h-full inset-0 w-full bg-black bg-opacity-25 z-10">
                      <div className="h-full w-full pb-6 flex items-center justify-center">
                        <Circle
                          type="link"
                          to={`/produkter/${profile.category}/${color.id}/edit`}
                        >
                          <Pencil />
                        </Circle>
                      </div>
                    </div>
                    <div className="text-xs py-2 absolute bottom-0 flex w-full bg-grey-lighter items-center px-3 z-0">
                      <p>{color.name}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </ToggleBox>
    ))}
  </div>
));

const FurnitureFittings = observer(({ categories, productType }) => {
  return categories.map((category) => (
    <ToggleBox
      title={category.name}
      key={category.id}
      className="mb-4 md:mb-7 overflow-hidden"
    >
      {/* ToggleRow */}
      <div className="flex flex-col xl:hidden">
        {category.children &&
          category.children.map((item, i) => {
            return (
              <React.Fragment key={item.id}>
                <ToggleRow key={i} title={item.name}>
                  {item.configurations.length > 1 ? (
                    item.configurations.map((conf, i) => (
                      <div
                        key={i}
                        className="w-full flex flex-col text-sm border-b border-grey"
                      >
                        <div className="w-full flex border-b border-grey">
                          <p className="w-24 px-4 py-2 text-opacity-50 uppercase text-right">
                            Art.nr
                          </p>
                          <p className="px-4 py-2">
                            {conf.meta.article_number}
                          </p>
                        </div>
                        {conf.meta.dimensions && (
                          <div className="flex">
                            <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                              Mått
                            </p>
                            <p className="px-4 py-2">{conf.meta.dimensions}</p>
                          </div>
                        )}
                        {conf.meta.color && (
                          <div className="flex">
                            <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                              Färg
                            </p>
                            <p className="px-4 py-2">{conf.meta.color}</p>
                          </div>
                        )}
                        <div className="flex">
                          <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                            Pris
                          </p>
                          {conf.price ? (
                            <p className="px-4 py-2">{conf.price} SEK</p>
                          ) : (
                            <p className="px-4 py-2">{item.price} SEK</p>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col border-b border-grey">
                      <div className="flex text-sm ">
                        <p className="bg-grey-light w-24 px-4 py-2">Art.nr</p>
                        <p className="px-4 py-2">{item.article_number}</p>
                      </div>
                      {item.dimensions && (
                        <div className="flex text-sm">
                          <p className="bg-grey-light w-24 px-4 py-2">Mått</p>
                          <p className="px-4 py-2">{item.dimensions}</p>
                        </div>
                      )}
                      {item.color && (
                        <div className="flex text-sm">
                          <p className="bg-grey-light w-24 px-4 py-2">Färg</p>
                          <p className="px-4 py-2">{item.color}</p>
                        </div>
                      )}
                      <div className="flex text-sm">
                        <p className="bg-grey-light w-24 px-4 py-2">Pris</p>
                        <p className="px-4 py-2">{item.price} SEK</p>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col justify-center items-end p-4">
                    <Circle
                      type="link"
                      to={`/produkter/${productType}/${item.id}/edit`}
                    >
                      <Pencil />
                    </Circle>
                  </div>
                </ToggleRow>
              </React.Fragment>
            );
          })}
      </div>
      {/* Table */}
      <div className="hidden xl:grid grid-cols-6-auto">
        <p className="bg-grey-lighter font-semibold text-sm py-2 pl-7">
          Art.nr
        </p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4">
          Produkt
        </p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4">Mått</p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4">Färg</p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4 text-right">
          Pris
        </p>
        <p className="bg-grey-lighter font-semibold text-sm py-2 px-4"></p>

        {category.children &&
          category.children.map((item, i) => {
            const isLast = i !== category.children.length - 1;
            const itemCLasses = `py-4 justify-start flex flex-col col-auto ${
              isLast ? "border-b border-grey" : ""
            }`;
            return (
              <React.Fragment key={item.id}>
                {item.configurations ? (
                  <div className={`pl-7 ${itemCLasses}`}>
                    {item.configurations.map((conf, i) => (
                      <p key={i}>{conf.meta.article_number}</p>
                    ))}
                  </div>
                ) : (
                  <span />
                )}
                <p className={`pl-4 ${itemCLasses}`}>{item.name}</p>
                {item.configurations ? (
                  <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                    {item.configurations.map((conf, i) => (
                      <p key={i}>{conf.meta.dimensions}</p>
                    ))}
                  </div>
                ) : (
                  <p className={`pl-4 ${itemCLasses}`}>{item.dimensions}</p>
                )}
                {item.configurations ? (
                  <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                    {item.configurations.map((conf, i) => (
                      <p key={i}>{conf.meta.color || ColorNames[conf.color]}</p>
                    ))}
                  </div>
                ) : (
                  <p className={`pl-4 ${itemCLasses}`}>{item.color}</p>
                )}
                {item.configurations ? (
                  <div
                    className={`px-4 text-right flex flex-col ${itemCLasses}`}
                  >
                    {item.configurations.map((conf, i) => (
                      <p key={i}>{conf.price / 100} SEK</p>
                    ))}
                  </div>
                ) : (
                  <p className={`px-4 text-right ${itemCLasses}`}>
                    {item.price} SEK
                  </p>
                )}
                <div
                  className={`flex flex-col justify-center items-end py-2 pr-7 ${
                    isLast ? "border-b border-grey" : ""
                  } `}
                >
                  <Circle
                    className="self-end"
                    type="link"
                    to={`/produkter/${productType}/${item.id}/edit`}
                  >
                    <Pencil />
                  </Circle>
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </ToggleBox>
  ));
});

const Lights = observer(({ categories }) => {
  return (
    <div>
      {categories.map((category) => (
        <ToggleBox
          title={category.name}
          key={category.id}
          className="mb-4 md:mb-7 overflow-hidden"
        >
          {/* ToggleRow */}
          <div className="flex flex-col xl:hidden">
            {category.children &&
              category.children.map((item, i) => {
                return (
                  <React.Fragment key={item.id}>
                    <ToggleRow key={i} title={item.name}>
                      {item.configurations.length > 1 ? (
                        item.configurations.map((conf, i) => (
                          <div
                            key={i}
                            className="w-full flex flex-col text-sm border-b border-grey"
                          >
                            <div className="w-full flex border-b border-grey">
                              <p className="px-4 py-2 text-opacity-50 uppercase w-24 text-right">
                                Art.nr
                              </p>
                              <p className="px-4 py-2">
                                {conf.meta.article_number}
                              </p>
                            </div>
                            {conf.meta.dimensions && (
                              <div className="flex">
                                <p className="bg-grey-lighter px-4 py-2 w-24 text-right">
                                  Mått
                                </p>
                                <p className="px-4 py-2">
                                  {conf.meta.dimensions}
                                </p>
                              </div>
                            )}
                            {conf.meta.v_and_w && (
                              <div className="flex">
                                <p className="bg-grey-lighter px-4 py-2 whitespace-nowrap w-24 text-right">
                                  V och W
                                </p>
                                <p className="px-4 py-2">{conf.meta.v_and_w}</p>
                              </div>
                            )}
                            <div className="flex">
                              <p className="bg-grey-lighter px-4 py-2 w-24 text-right">
                                Pris
                              </p>
                              {conf.price ? (
                                <p className="px-4 py-2">{conf.price} SEK</p>
                              ) : (
                                <p className="px-4 py-2">{item.price} SEK</p>
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex flex-col border-b border-grey">
                          <div className="flex text-sm ">
                            <p className="bg-grey-light w-24 text-right px-4 py-2">
                              Art.nr
                            </p>
                            <p className="px-4 py-2">{item.article_number}</p>
                          </div>
                          {item.dimensions && (
                            <div className="flex text-sm">
                              <p className="bg-grey-light w-24 text-right px-4 py-2">
                                Mått
                              </p>
                              <p className="px-4 py-2">{item.dimensions}</p>
                            </div>
                          )}
                          {item.v_and_w && (
                            <div className="flex text-sm">
                              <p className="bg-grey-light w-24 text-right px-4 py-2">
                                V och W
                              </p>
                              <p className="px-4 py-2">{item.v_and_w}</p>
                            </div>
                          )}
                          <div className="flex text-sm">
                            <p className="bg-grey-light w-24 text-right px-4 py-2">
                              Pris
                            </p>
                            <p className="px-4 py-2">{item.price} SEK</p>
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col justify-center items-end p-4">
                        <Circle
                          type="link"
                          to={`/produkter/interior/${item.id}/edit`}
                        >
                          <Pencil />
                        </Circle>
                      </div>
                    </ToggleRow>
                  </React.Fragment>
                );
              })}
          </div>
          {/* Table */}
          <div className="hidden xl:grid grid-cols-6-auto">
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-7">
              Art.nr
            </p>
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4">
              Produkt
            </p>
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4">
              Mått
            </p>
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4">
              V och W
            </p>
            <p className="bg-grey-lighter font-semibold text-sm text-right py-2 px-4">
              Pris
            </p>
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4"></p>

            {category.children &&
              category.children.map((item, i) => {
                const isLast = i !== category.children.length - 1;
                const itemCLasses = `py-4 justify-start flex flex-col col-auto ${
                  isLast ? "border-b border-grey" : ""
                }`;
                return (
                  <React.Fragment key={item.id}>
                    {item.configurations ? (
                      <div className={`pl-7 ${itemCLasses}`}>
                        {item.configurations.map((conf, i) => (
                          <p key={i}>{conf.meta.article_number}</p>
                        ))}
                      </div>
                    ) : (
                      <span />
                    )}
                    <p className={`pl-4 ${itemCLasses}`}>{item.name}</p>
                    {item.configurations ? (
                      <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                        {item.configurations.map((conf, i) => (
                          <p key={i}>{conf.meta.dimensions}</p>
                        ))}
                      </div>
                    ) : (
                      <p className={`pl-4 ${itemCLasses}`}>{item.dimensions}</p>
                    )}
                    {item.configurations ? (
                      <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                        {item.configurations.map((conf, i) => (
                          <p key={i}>{conf.meta.v_and_w}</p>
                        ))}
                      </div>
                    ) : (
                      <p className={`pl-4 ${itemCLasses}`}>{item.v_and_w}</p>
                    )}
                    {item.configurations ? (
                      <div
                        className={`px-4 text-right flex flex-col ${itemCLasses}`}
                      >
                        {item.configurations.map((conf, i) => (
                          <p key={i}>{conf.price / 100} SEK</p>
                        ))}
                      </div>
                    ) : (
                      <p className={`px-4 text-right ${itemCLasses}`}>
                        {item.price} SEK
                      </p>
                    )}
                    <div
                      className={`flex flex-col items-end justify-center py-2 pr-7 ${
                        isLast ? "border-b border-grey" : ""
                      } `}
                    >
                      <Circle
                        type="link"
                        to={`/produkter/lighting/${item.id}/edit`}
                      >
                        <Pencil />
                      </Circle>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </ToggleBox>
      ))}
    </div>
  );
});

const MountedFurnishings = observer(({ categories }) => {
  return (
    <div>
      {categories.map((category) => {
        return (
          <ToggleBox
            title={category.name}
            key={category.id}
            className="mb-4 md:mb-7 overflow-hidden"
          >
            {/* ToggleRow */}
            <div className="flex flex-col xl:hidden">
              {category.children &&
                category.children.map((item, i) => {
                  return (
                    <React.Fragment key={item.id}>
                      <ToggleRow key={i} title={item.name}>
                        {item.configurations.length > 1 ? (
                          item.configurations.map((conf, i) => (
                            <div
                              key={i}
                              className="w-full flex flex-col text-sm border-b border-grey"
                            >
                              <div className="w-full flex border-b border-grey">
                                <p className="px-4 py-2 text-opacity-50 uppercase w-24 text-right">
                                  Art.nr
                                </p>
                                <p className="px-4 py-2">
                                  {conf.meta.article_number}
                                </p>
                              </div>
                              {conf.meta.dimensions && (
                                <div className="flex">
                                  <p className="bg-grey-lighter px-4 py-2 w-24 text-right">
                                    Mått
                                  </p>
                                  <p className="px-4 py-2">
                                    {conf.meta.dimensions}
                                  </p>
                                </div>
                              )}
                              <div className="flex">
                                <p className="bg-grey-lighter px-4 py-2 w-24 text-right">
                                  Pris
                                </p>
                                {conf.price ? (
                                  <p className="px-4 py-2">{conf.price} SEK</p>
                                ) : (
                                  <p className="px-4 py-2">{item.price} SEK</p>
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="flex flex-col border-b border-grey">
                            <div className="flex text-sm ">
                              <p className="bg-grey-light w-24 text-right px-4 py-2">
                                Art.nr
                              </p>
                              <p className="px-4 py-2">{item.article_number}</p>
                            </div>
                            {item.dimensions && (
                              <div className="flex text-sm">
                                <p className="bg-grey-light w-24 text-right px-4 py-2">
                                  Mått
                                </p>
                                <p className="px-4 py-2">{item.dimensions}</p>
                              </div>
                            )}
                            <div className="flex text-sm">
                              <p className="bg-grey-light w-24 text-right px-4 py-2">
                                Pris
                              </p>
                              <p className="px-4 py-2">{item.price} SEK</p>
                            </div>
                          </div>
                        )}
                        <div className="flex flex-col justify-center items-end p-4">
                          <Circle
                            type="link"
                            to={`/produkter/interior/${item.id}/edit`}
                          >
                            <Pencil />
                          </Circle>
                        </div>
                      </ToggleRow>
                    </React.Fragment>
                  );
                })}
            </div>
            {/* Table */}
            <div className="hidden xl:grid grid-cols-5-auto">
              <p className="bg-grey-lighter font-semibold text-sm py-2 pl-8">
                Art.nr
              </p>
              <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4">
                Produkt
              </p>
              <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4">
                Mått
              </p>
              <p className="bg-grey-lighter font-semibold text-sm text-right py-2 px-4">
                Pris
              </p>
              <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4"></p>

              {category.children &&
                category.children.map((item, i) => {
                  const isLast = i !== category.children.length - 1;
                  const itemCLasses = `py-4 justify-start flex flex-col col-auto ${
                    isLast ? "border-b border-grey" : ""
                  }`;
                  return (
                    <React.Fragment key={item.id}>
                      {item.configurations ? (
                        <div className={`pl-7 ${itemCLasses}`}>
                          {item.configurations.map((conf, i) => (
                            <p key={i}>{conf.meta.article_number}</p>
                          ))}
                        </div>
                      ) : (
                        <span />
                      )}
                      <p className={`pl-4 ${itemCLasses}`}>{item.name}</p>
                      {item.configurations ? (
                        <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                          {item.configurations.map((conf, i) => (
                            <p key={i}>{conf.meta.dimensions}</p>
                          ))}
                        </div>
                      ) : (
                        <p className={`pl-4 ${itemCLasses}`}>
                          {item.dimensions}
                        </p>
                      )}
                      {item.configurations ? (
                        <div
                          className={`px-4 text-right flex flex-col ${itemCLasses}`}
                        >
                          {item.configurations.map((conf, i) => (
                            <p key={i}>{conf.price / 100} SEK</p>
                          ))}
                        </div>
                      ) : (
                        <p className={`px-4 text-right ${itemCLasses}`}>
                          {item.price} SEK
                        </p>
                      )}
                      <div
                        className={`flex flex-col items-end justify-center py-2 pr-7 ${
                          isLast ? "border-b border-grey" : ""
                        } `}
                      >
                        <Circle
                          type="link"
                          to={`/produkter/stand_and_wallmounted/${item.id}/edit`}
                        >
                          <Pencil />
                        </Circle>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </ToggleBox>
        );
      })}
    </div>
  );
});

const SpecialProducts = observer(({ categories }) => {
  return (
    <div>
      {categories.map((category) => (
        <ToggleBox
          title={category.name}
          key={category.id}
          className="mb-4 md:mb-7 overflow-hidden"
        >
          {/* ToggleRow */}
          <div className="flex flex-col xl:hidden">
            {category.children &&
              category.children.map((item, i) => {
                return (
                  <React.Fragment key={item.id}>
                    <ToggleRow key={i} title={item.name}>
                      {item.configurations.length > 1 ? (
                        item.configurations.map((conf, i) => (
                          <div
                            key={i}
                            className="w-full flex flex-col text-sm border-b border-grey"
                          >
                            <div className="w-full flex border-b border-grey">
                              <p className="w-24 px-4 py-2 text-opacity-50 uppercase text-right">
                                Art.nr
                              </p>
                              <p className="px-4 py-2">
                                {conf.meta.article_number}
                              </p>
                            </div>
                            {conf.meta.dimensions && (
                              <div className="flex">
                                <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                                  Mått
                                </p>
                                <p className="px-4 py-2">
                                  {conf.meta.dimensions}
                                </p>
                              </div>
                            )}
                            {conf.meta.color && (
                              <div className="flex">
                                <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                                  Färg
                                </p>
                                <p className="px-4 py-2">{conf.meta.color}</p>
                              </div>
                            )}
                            <div className="flex">
                              <p className="bg-grey-lighter w-24 px-4 py-2 text-right">
                                Pris
                              </p>
                              {conf.price ? (
                                <p className="px-4 py-2">{conf.price} SEK</p>
                              ) : (
                                <p className="px-4 py-2">{item.price} SEK</p>
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex flex-col border-b border-grey">
                          <div className="flex text-sm ">
                            <p className="bg-grey-light w-24 px-4 py-2">
                              Art.nr
                            </p>
                            <p className="px-4 py-2">{item.article_number}</p>
                          </div>
                          {item.dimensions && (
                            <div className="flex text-sm">
                              <p className="bg-grey-light w-24 px-4 py-2">
                                Mått
                              </p>
                              <p className="px-4 py-2">{item.dimensions}</p>
                            </div>
                          )}
                          {item.color && (
                            <div className="flex text-sm">
                              <p className="bg-grey-light w-24 px-4 py-2">
                                Färg
                              </p>
                              <p className="px-4 py-2">{item.color}</p>
                            </div>
                          )}
                          <div className="flex text-sm">
                            <p className="bg-grey-light w-24 px-4 py-2">Pris</p>
                            <p className="px-4 py-2">{item.price} SEK</p>
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col justify-center items-end p-4">
                        <Circle
                          type="link"
                          to={`/produkter/interior/${item.id}/edit`}
                        >
                          <Pencil />
                        </Circle>
                      </div>
                    </ToggleRow>
                  </React.Fragment>
                );
              })}
          </div>
          {/* Table */}
          <div className="hidden xl:grid grid-cols-6-auto">
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-7">
              Art.nr
            </p>
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4">
              Produkt
            </p>
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4">
              Mått
            </p>
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4">
              Färg
            </p>
            <p className="bg-grey-lighter font-semibold text-sm text-right py-2 px-4">
              Pris
            </p>
            <p className="bg-grey-lighter font-semibold text-sm py-2 pl-4"></p>

            {category.children &&
              category.children.map((item, i) => {
                const isLast = i !== category.children.length - 1;
                const itemCLasses = `py-4 justify-start flex flex-col col-auto ${
                  isLast ? "border-b border-grey" : ""
                }`;
                return (
                  <React.Fragment key={item.id}>
                    {item.configurations ? (
                      <div className={`pl-7 ${itemCLasses}`}>
                        {item.configurations.map((conf, i) => (
                          <p key={i}>{conf.meta.article_number}</p>
                        ))}
                      </div>
                    ) : (
                      <span />
                    )}
                    <p className={`pl-4 ${itemCLasses}`}>{item.name}</p>
                    {item.configurations ? (
                      <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                        {item.configurations.map((conf, i) => (
                          <p key={i}>{conf.meta.dimensions}</p>
                        ))}
                      </div>
                    ) : (
                      <p className={`pl-4 ${itemCLasses}`}>{item.dimensions}</p>
                    )}
                    {item.configurations ? (
                      <div className={`pl-4 flex flex-col ${itemCLasses}`}>
                        {item.configurations.map((conf, i) => (
                          <p key={i}>{conf.meta.color}</p>
                        ))}
                      </div>
                    ) : (
                      <p className={`pl-4 ${itemCLasses}`}>{item.color}</p>
                    )}
                    {item.configurations ? (
                      <div
                        className={`px-4 text-right flex flex-col ${itemCLasses}`}
                      >
                        {item.configurations.map((conf, i) => (
                          <p key={i}>{conf.price / 100} SEK</p>
                        ))}
                      </div>
                    ) : (
                      <p className={`px-4 text-right ${itemCLasses}`}>
                        {item.price} SEK
                      </p>
                    )}
                    <div
                      className={`flex flex-col items-end justify-center py-2 pr-7 ${
                        isLast ? "border-b border-grey" : ""
                      } `}
                    >
                      <Circle
                        type="link"
                        to={`/produkter/special/${item.id}/edit`}
                      >
                        <Pencil />
                      </Circle>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </ToggleBox>
      ))}
    </div>
  );
});

const PriceList = observer(({ store }) => {
  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const localStore = useLocalObservable(() => ({
    isEditing: false,
    isSaving: false,
    error: null,
    newPriceGroups: [],
    removeGroups: [],
  }));

  return (
    // Prislista
    <div className="flex flex-col pricelist">
      <ToggleBox toggle={false} title="Prislista">
        {!localStore.isEditing && (
          <div className="items">
            {/* ToggleRow */}
            <div className="flex flex-col xl:hidden">
              {store.priceGroups.map((priceGroup, i) => (
                <React.Fragment key={i}>
                  <ToggleRow title={`Prisgrupp ${priceGroup.name}`}>
                    <div className="flex text-sm">
                      <div className="text-right bg-grey-lighter border-r border-grey font-medium">
                        <p className="whitespace-nowrap border-b border-grey py-2 px-4">
                          Bredd per dörr
                        </p>
                        <p className="py-2 px-4">600 mm</p>
                        <p className="py-2 px-4">750 mm</p>
                        <p className="py-2 px-4">900 mm</p>
                        <p className="py-2 px-4">1050 mm</p>
                        <p className="py-2 px-4">1200 mm</p>
                      </div>
                      <div className="text-left flex-grow">
                        <p className="whitespace-nowrap mr-6 border-b border-grey py-2 px-4 w-full">
                          Pris
                        </p>
                        <p className="py-2 px-4 border-b border-grey">
                          {priceGroup.price_600 / 100} SEK
                        </p>
                        <p className="py-2 px-4 border-b border-grey">
                          {priceGroup.price_750 / 100} SEK
                        </p>
                        <p className="py-2 px-4 border-b border-grey">
                          {priceGroup.price_900 / 100} SEK
                        </p>
                        <p className="py-2 px-4 border-b border-grey">
                          {priceGroup.price_1050 / 100} SEK
                        </p>
                        <p className="py-2 px-4">
                          {priceGroup.price_1200 / 100} SEK
                        </p>
                      </div>
                    </div>
                  </ToggleRow>
                </React.Fragment>
              ))}
            </div>
            {/* Table */}
            <div className="hidden xl:grid grid-cols-7-auto">
              <p className="text-right bg-grey-lighter border-b border-r border-grey px-4 py-2 font-medium">
                Bredd per dörr
              </p>
              <p className="bg-grey-lighter border-b border-grey px-4 py-2 font-medium">
                600
              </p>
              <p className="bg-grey-lighter border-b border-grey px-4 py-2 font-medium">
                750
              </p>
              <p className="bg-grey-lighter border-b border-grey px-4 py-2 font-medium">
                900
              </p>
              <p className="bg-grey-lighter border-b border-grey px-4 py-2 font-medium">
                1050
              </p>
              <p className="bg-grey-lighter border-b border-grey px-4 py-2 font-medium">
                1200
              </p>
              <p className="px-6 text-right bg-grey-lighter border-b border-grey py-2 font-medium text-opacity-50">
                Mått i mm
              </p>

              {store.priceGroups.map((priceGroup, i) => (
                <React.Fragment key={i}>
                  <p
                    className={`text-right border-r border-grey py-3 pr-4 ${
                      i !== store.priceGroups.length - 1
                        ? "border-b border-grey"
                        : ""
                    }`}
                  >
                    {priceGroup.name}
                  </p>
                  <p
                    className={`pl-4 py-3 ${
                      i !== store.priceGroups.length - 1
                        ? "border-b border-grey"
                        : ""
                    }`}
                  >
                    {priceGroup.price_600 / 100}
                  </p>
                  <p
                    className={`pl-4 py-3 ${
                      i !== store.priceGroups.length - 1
                        ? "border-b border-grey"
                        : ""
                    }`}
                  >
                    {priceGroup.price_750 / 100}
                  </p>
                  <p
                    className={`pl-4 py-3 ${
                      i !== store.priceGroups.length - 1
                        ? "border-b border-grey"
                        : ""
                    }`}
                  >
                    {priceGroup.price_900 / 100}
                  </p>
                  <p
                    className={`pl-4 py-3 ${
                      i !== store.priceGroups.length - 1
                        ? "border-b border-grey"
                        : ""
                    }`}
                  >
                    {priceGroup.price_1050 / 100}
                  </p>
                  <p
                    className={`pl-4 py-3 ${
                      i !== store.priceGroups.length - 1
                        ? "border-b border-grey"
                        : ""
                    }`}
                  >
                    {priceGroup.price_1200 / 100}
                  </p>
                  <p
                    className={`text-right pr-6 py-3 text-opacity-50 ${
                      i !== store.priceGroups.length - 1
                        ? "border-b border-grey"
                        : ""
                    }`}
                  >
                    SEK
                  </p>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        {localStore.isEditing && (
          <div className="flex flex-col justify-start">
            {store.priceGroups.map((priceGroup, i) => (
              <div
                className="flex xxl:flex-row border-b border-grey px-2 py-4 lg:pt-6"
                key={i}
              >
                <div className="px-2 pt-8">
                  <Circle
                    onClick={() => {
                      localStore.removeGroups.push(priceGroup);
                      store.priceGroups.splice(i, 1);
                    }}
                    type="button"
                  >
                    <MinusCircle />
                  </Circle>
                </div>
                <div className="flex flex-col md:flex-row md:flex-wrap">
                  <div className="flex flex-col px-2 mb-4 md:w-full xxl:w-auto">
                    <label className="font-medium mb-2">Prisgrupp</label>
                    <input
                      type="number"
                      className="px-6 py-4 w-16 lg:w-20 lg:text-center"
                      value={priceGroup.name}
                      onChange={(e) => (priceGroup.name = e.target.value)}
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      label="600 mm"
                      className="bg-white md:w-32"
                      prependLabel="SEK"
                      value={priceGroup.price_600 / 100}
                      onChange={(e) =>
                        (priceGroup.price_600 = e.target.value * 100)
                      }
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      prependLabel="SEK"
                      className="bg-white md:w-32"
                      label="750 mm"
                      value={priceGroup.price_750 / 100}
                      onChange={(e) =>
                        (priceGroup.price_750 = e.target.value * 100)
                      }
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      label="900 mm"
                      className="bg-white md:w-32"
                      prependLabel="SEK"
                      value={priceGroup.price_900 / 100}
                      onChange={(e) =>
                        (priceGroup.price_900 = e.target.value * 100)
                      }
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      prependLabel="SEK"
                      className="bg-white md:w-32"
                      label="1050 mm"
                      value={priceGroup.price_1050 / 100}
                      onChange={(e) =>
                        (priceGroup.price_1050 = e.target.value * 100)
                      }
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      label="1200 mm"
                      className="bg-white md:w-32"
                      prependLabel="SEK"
                      value={priceGroup.price_1200 / 100}
                      onChange={(e) =>
                        (priceGroup.price_1200 = e.target.value * 100)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}

            {localStore.newPriceGroups.map((priceGroup, i) => (
              <div
                className="flex xxl:flex-row border-b border-grey px-2 py-4 lg:pt-6"
                key={i}
              >
                <div className="px-2 pt-8">
                  <Circle
                    className=""
                    onClick={() => {
                      localStore.newPriceGroups.splice(i, 1);
                    }}
                    type="button"
                  >
                    <MinusCircle />
                  </Circle>
                </div>
                <div className="flex flex-col md:flex-row md:flex-wrap">
                  <div className="flex flex-col px-2 mb-4 md:w-full xxl:w-auto">
                    <label className="font-medium">Prisgrupp</label>
                    <input
                      className="w-16 h-16 mt-3"
                      value={priceGroup.name}
                      onChange={(e) => (priceGroup.name = e.target.value)}
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      label="600 mm"
                      className="bg-white md:w-32"
                      prependLabel="SEK"
                      value={priceGroup.price_600}
                      onChange={(e) => (priceGroup.price_600 = e.target.value)}
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      prependLabel="SEK"
                      className="bg-white md:w-32"
                      label="750 mm"
                      value={priceGroup.price_750}
                      onChange={(e) => (priceGroup.price_750 = e.target.value)}
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      label="900 mm"
                      className="bg-white md:w-32"
                      prependLabel="SEK"
                      value={priceGroup.price_900}
                      onChange={(e) => (priceGroup.price_900 = e.target.value)}
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      prependLabel="SEK"
                      className="bg-white md:w-32"
                      label="1050 mm"
                      value={priceGroup.price_1050}
                      onChange={(e) => (priceGroup.price_1050 = e.target.value)}
                    />
                  </div>
                  <div className="px-2 mb-4 w-full md:w-auto">
                    <MeasureInput
                      label="1200 mm"
                      className="bg-white md:w-32"
                      prependLabel="SEK"
                      value={priceGroup.price_1200}
                      onChange={(e) => (priceGroup.price_1200 = e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="ml-4 mr-auto my-4">
              <Circle
                type="button"
                onClick={() => {
                  localStore.newPriceGroups.push({
                    name: "",
                    price_600: "",
                    price_750: "",
                    price_900: "",
                    price_1050: "",
                    price_1200: "",
                  });
                }}
              >
                <AddCircle />
              </Circle>
            </div>
          </div>
        )}
      </ToggleBox>
      {localStore.error && localStore.error.length > 0 && (
        <p className="text-red-500 text-right mt-6">{localStore.error}</p>
      )}
      {!localStore.isEditing && (
        <Circle
          type="button"
          onClick={() => {
            localStore.error = null;
            localStore.isEditing = !localStorage.isEditing;
          }}
          className="ml-auto mt-6"
        >
          <Pencil />
        </Circle>
      )}
      {localStore.isEditing && (
        <div className="flex space-x-4 mt-6">
          {!localStore.isSaving && (
            <Circle
              type="button"
              onClick={() => {
                localStore.error = null;
                localStore.isSaving = true;

                let requests = store.priceGroups.map((priceGroup) => {
                  try {
                    if (isNumber(priceGroup.price_600)) {
                      priceGroup.price_600 = parseInt(priceGroup.price_600, 10);
                    } else {
                      throw "Ogiltig data på 600. Se över och försök igen";
                    }
                    if (isNumber(priceGroup.price_750)) {
                      priceGroup.price_750 = parseInt(priceGroup.price_750, 10);
                    } else {
                      throw "Ogiltig data på 750. Se över och försök igen";
                    }
                    if (isNumber(priceGroup.price_900)) {
                      priceGroup.price_900 = parseInt(priceGroup.price_900, 10);
                    } else {
                      throw "Ogiltig data på 900. Se över och försök igen";
                    }
                    if (isNumber(priceGroup.price_1050)) {
                      priceGroup.price_1050 = parseInt(
                        priceGroup.price_1050,
                        10
                      );
                    } else {
                      throw "Ogiltig data på 1050. Se över och försök igen";
                    }
                    if (isNumber(priceGroup.price_1200)) {
                      priceGroup.price_1200 = parseInt(
                        priceGroup.price_1200,
                        10
                      );
                    } else {
                      throw "Ogiltig data på 1200. Se över och försök igen";
                    }
                  } catch (err) {
                    localStore.error = err;
                    localStore.isSaving = false;
                  }
                  put("/price_groups/" + priceGroup.id, priceGroup);
                });

                if (
                  localStore.removeGroups &&
                  localStore.removeGroups.length > 0
                ) {
                  localStore.removeGroups.map((group) => {
                    requests.push(
                      deleteRequest("/price_groups/" + group.id).catch(() => {
                        localStore.error = `Prisgrupp ${group.name} används av produkter. Du måste välja en annan prisgrupp på de produkterna först.`;
                        store.priceGroups.push(group);
                        localStore.removeGroups = [];
                      })
                    );
                  });
                }

                if (
                  localStore.newPriceGroups &&
                  localStore.newPriceGroups.length > 0
                ) {
                  requests.push(
                    localStore.newPriceGroups.map((group) => {
                      try {
                        if (isNumber(group.price_600)) {
                          group.price_600 = parseInt(group.price_600, 10) * 100;
                        } else {
                          throw "Ogiltig data på 600. Se över och försök igen";
                        }
                        if (isNumber(group.price_750)) {
                          group.price_750 = parseInt(group.price_750, 10) * 100;
                        } else {
                          throw "Ogiltig data på 750. Se över och försök igen";
                        }
                        if (isNumber(group.price_900)) {
                          group.price_900 = parseInt(group.price_900, 10) * 100;
                        } else {
                          throw "Ogiltig data på 900. Se över och försök igen";
                        }
                        if (isNumber(group.price_1050)) {
                          group.price_1050 =
                            parseInt(group.price_1050, 10) * 100;
                        } else {
                          throw "Ogiltig data på 1050. Se över och försök igen";
                        }
                        if (isNumber(group.price_1200)) {
                          group.price_1200 =
                            parseInt(group.price_1200, 10) * 100;
                        } else {
                          throw "Ogiltig data på 1200. Se över och försök igen";
                        }

                        post("/price_groups", group).then((res) => {
                          store.priceGroups.push(res.data);
                        });

                        localStore.newPriceGroups = [];
                      } catch (err) {
                        localStore.error = err;
                      }
                    })
                  );
                }

                if (!localStore.error) {
                  Promise.all(requests)
                    .then(() => {
                      localStore.isEditing = false;
                      localStore.isSaving = false;
                    })
                    .catch(() => {
                      localStore.isSaving = false;
                      localStore.error = "Något gick fel. Försök igen.";
                    });
                }
              }}
            >
              <Save />
            </Circle>
          )}
          {localStore.isSaving && (
            <Circle type="button">
              <Loading className="w-6 h-6" />
            </Circle>
          )}
          {!localStore.isSaving && (
            <Circle
              type="button"
              onClick={() => {
                localStore.newPriceGroups = [];
                localStore.isEditing = false;
              }}
            >
              <Cross />
            </Circle>
          )}
        </div>
      )}
    </div>
  );
});

export default Products;
